import { takeEvery, fork, put, all, call, select } from "redux-saga/effects";
import moment from "moment";
import {
  apiError,
  pettyCashListSuccessful,
  getPettyCashList,
  pettyCashByIDSuccessful,
  pettyCashAddSuccessful,
  pettyItemImageUploadSuccessful,
} from "./pettyCashSlice";
import { Modal } from "antd";
import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";
// Auth Related Methods
import {
  postAdd,
  getParamsList,
  postEdit,
  postDelete,
  getList,
  postFormData,
} from "../../../helpers/Helper";
import { serialize } from "object-to-formdata";

function* PettyCashList({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] != "" && k != null && k != "no_of_pages") {
          if (k == "start_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k == "end_date") {
            params[k] = moment(data[k]).add(1, "days").format("YYYY-MM-DD");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/utilitybilling/petty_cash/list/",
      params
    );
    yield put(pettyCashListSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* getAttachmentId(item) {
  if (item.attachment?.id) {
    return { attachment_id:item.attachment?.id, _id: item._id}
  } else {
    var response3 = yield call(postFormData, "/utilitybilling/petty_cash_attachment/create/", { file: item.attachment.file, } );
    return { attachment_id: response3?.data?.id, _id: item._id}
  }
}

function* AddPettyCash({ payload }) {
  try {
    if (payload.obj.id == 0) {
      const configHome = payload.obj.items.map((item) => {
        return call(getAttachmentId, item);
      });
      const response1 = yield all(configHome);

      console.log("response1", response1);
      payload.obj.items = payload.obj.items.map((item, i) => {
        delete item.attachment;
        // item.attachment_id = response1[i];
        var filerdata = response1.filter(obj=> obj._id == item._id)
        if(filerdata.length > 0 ){
          item.attachment_id = filerdata[0].attachment_id
        }
        return item;
      });
      const response = yield call(
        postAdd,
        "/utilitybilling/petty_cash/create/",
        payload.obj
      );
      if (response.status === 201) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Petty Cash Added Successfully.!
              </p>
            </div>
          ),
        });
        payload.history.push("/pages/petty-cash");
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to add Petty Cash. Please try again!
              </p>
            </div>
          ),
        });
      }
      yield put(pettyCashAddSuccessful(null));
    } else {
      const configHome = payload.obj.items.map((item) => {
        return call(getAttachmentId, item);
      });
      const response1 = yield all(configHome);

      console.log("response1", response1);
      payload.obj.items = payload.obj.items.map((item, i) => {
        delete item.attachment;
        // item.attachment_id = response1[i];
        var filerdata = response1.filter(obj=> obj._id == item._id)
        if(filerdata.length > 0 ){
          item.attachment_id = filerdata[0].attachment_id
        }
        return item;
      });
      const response = yield call(
        postEdit,
        "/utilitybilling/petty_cash/" + payload.obj.id,
        payload.obj
      );
      if (response.status === 200) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Petty Cash Edited Successfully.!
              </p>
            </div>
          ),
        });
        payload.history.push("/pages/petty-cash");
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to Petty Cash. Please try again!
              </p>
            </div>
          ),
        });
      }
      yield put(pettyCashAddSuccessful(null));
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* PettyCashByID({ payload }) {
  try {
    const response = yield call(
      getParamsList,
      "/utilitybilling/petty_cash/" + payload,
      {}
    );
    yield put(pettyCashByIDSuccessful({ response }));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* DeletePettyCash({ payload }) {
  var areaID = payload;
  try {
    const response = yield call(
      postDelete,
      "/utilitybilling/petty_cash/" + areaID
    );
    if (response.status === 204) {
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Your Petty Cash has been deleted!
            </p>
          </div>
        ),
      });
      yield put(getPettyCashList({ page: 1, search: "", page_size: 10 }));
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Your Petty Cash has been not deleted!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });

    yield put(apiError(error));
  }
}

// function* PettyItemImageUpload({ payload }) {
//   try {
//     const response = yield call(postFormData, "/utilitybilling/petty_cash_attachment/create/", {
//       file: payload.file,
//     });

//     yield put(
//       pettyItemImageUploadSuccessful({
//         response,
//         ImageType: payload.imageType,
//       })
//     );
//   } catch (error) {
//     yield put(apiError(error));
//   }
// }

function* UpdateFsyncStatus({ payload }) {
  let pettyParams = yield select((state) => state.pettyCash.pettyCashParams)
  try {
    const response = yield call(
      postAdd,
      "/thirdparty/fsyncpettycash/" + payload.id, {}
    );
    if (response.status === 200 ) {
      if( response.data.result == true){
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                {/* Selected Acknowledgement is successfully synchronised into focus */}
                {response.data.msg}
              </p>
            </div>
          ),
        });
        
      }else{
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                {/* Sorry! selected Acknowledgement is not posted on focus. please try
                again! */}
                {response.data.msg}
              </p>
            </div>
          ),
        });
      }
      yield put(getPettyCashList(pettyParams));
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! selected PettyCash is not posted on focus. please try
              again!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}


function* watchGetPettyCashList() {
  yield takeEvery("pettyCash/getPettyCashList", PettyCashList);
}
export function* watchPettyCashAdd() {
  yield takeEvery("pettyCash/pettyCashAdd", AddPettyCash);
}
export function* watchGetPettyCashByID() {
  yield takeEvery("pettyCash/getPettyCashByID", PettyCashByID);
}
export function* watchPettyCashDelete() {
  yield takeEvery("pettyCash/pettyCashDelete", DeletePettyCash);
}
export function* watchFsyncStatusUpdate() {
  yield takeEvery("pettyCash/fsyncStatusUpdate", UpdateFsyncStatus);
}
// export function* watchGetPettyItemImageUpload() {
//   yield takeEvery("pettyCash/getPettyItemImageUpload", PettyItemImageUpload);
// }

function* PettyCashSaga() {
  yield all([
    fork(watchGetPettyCashList),
    fork(watchPettyCashAdd),
    fork(watchGetPettyCashByID),
    fork(watchPettyCashDelete),
    fork(watchFsyncStatusUpdate),
    // fork(watchGetPettyItemImageUpload),
  ]);
}

export default PettyCashSaga;
