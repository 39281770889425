import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { createBrowserHistory } from "history";
export const history = createBrowserHistory();
// Login Redux States

import {
  apiError,
  backUpDataBaseListSuccessful,
  loadingStatus,
  getBackUpDataBaseList,
  isPasswordModel,
} from "./backupDataBaseSlice";

// AUTH related methods
import {
  postAdd,
  getList,
  getParamsList,
  postEdit,
  postDelete,
  getDownloadFile,
} from "../../../helpers/Helper";
import { Modal } from "antd";
import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";

function* BackUpDataBaseList({ payload }) {
  var data = payload;
  var params = {};

  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "" && k != "no_of_pages") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/system/Database/Backup",
      params
    );
    yield put(backUpDataBaseListSuccessful(response));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* DataBaseBackUP() {
  try {
    const response = yield call(getList, "/system/Database/DoBackup", {});
    Modal.success({
      icon: (
        <span className="remix-icon">
          <RiCheckboxCircleLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Database backup created successfully.!
          </p>
        </div>
      ),
    });
    yield put(loadingStatus(false));
    yield put(getBackUpDataBaseList(false));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* DataBaseVerifyPassword({ payload }) {
  try {
    const response = yield call(
      postAdd,
      "/system/Database/downloadBackup/" + payload.dataBaseId,
      payload.obj
    );

    if (response.status === 200) {
      console.log("res[pmse", response, response.status);
      // yield put(history.push(payload.dataBaseFile));
      window.location.href = response.data.file_url;
      // const response = yield call(
      //   getDownloadFile,
      //   payload.dataBaseFile
      // );
      // payload.history.push(payload.dataBaseFile);
      yield put(isPasswordModel({ model: false, Id: 0 }));
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Your Password is invalid. Please try again!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    if (error.response.status === 400) {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Your Password is invalid. Please try again!
            </p>
          </div>
        ),
      });
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">{error}</p>
          </div>
        ),
      });
    }

    yield put(apiError(error));
  }
}

export function* watchGetBackUpDataBaseList() {
  yield takeEvery("backupDatabase/getBackUpDataBaseList", BackUpDataBaseList);
}
export function* watchGetDataBaseBackUP() {
  yield takeEvery("backupDatabase/getDataBaseBackUP", DataBaseBackUP);
}
export function* watchVerifyPassword() {
  yield takeEvery("backupDatabase/verifyPassword", DataBaseVerifyPassword);
}

function* BackupDatabaseSaga() {
  yield all([
    fork(watchGetBackUpDataBaseList),
    fork(watchGetDataBaseBackUP),
    fork(watchVerifyPassword),
  ]);
}

export default BackupDatabaseSaga;
