import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States

import {
  apiError,
  MRQCountListSuccessful,
  STNCountListSuccessful,
  ACKCountListSuccessful,
  StockCountListSuccessful,
  UserWarehousesListSuccessful,
  setWarehouseId,
  getStockValues,
  StockValueSuccessful,
} from "./dashboardSlice";
import { Modal } from "antd";
import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";

// AUTH related methods
import {
  getList,
  getParamsList,
  postAdd,
  postDownloadFile,
} from "../../helpers/Helper";

function* MRQCounts({ payload }) {
  try {
    const response = yield call(
      getList,
      "/material_request_quotes/user/mrq/counts",
      {}
    );
    yield put(MRQCountListSuccessful(response));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}
function* STNCounts({ payload }) {
  try {
    const response = yield call(
      getList,
      "/stocktransfernote/user/stn/counts",
      {}
    );
    yield put(STNCountListSuccessful(response));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}
function* ACKCounts({ payload }) {
  try {
    const response = yield call(
      getList,
      "/acknowledgement/user/ack/counts",
      {}
    );
    yield put(ACKCountListSuccessful(response));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}
function* StockCounts({ payload }) {
  try {
    const response = yield call(
      getList,
      "/stockconsumption/user/stc/counts",
      {}
    );
    yield put(StockCountListSuccessful(response));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* UserWarehousesList({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] != "" && k != null && k != "no_of_pages") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/masters/user/warehouses/list/",
      params
    );
    yield put(UserWarehousesListSuccessful(response));
    if (payload.page == 1) {
      yield put(
        getStockValues({
          params: { page_size: 10, page: 1 },
          Warehouse_id: response.results[0]?.id,
        })
      );
      yield put(setWarehouseId(response.results[0]?.id));
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}
function* StockValuesGet({ payload }) {
  var data = payload.params;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] != "" && k != null && k != "no_of_pages") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/masters/user/stock/counts/" + payload.Warehouse_id,
      params
    );
    yield put(StockValueSuccessful(response));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

export function* watchGetMRQCounts() {
  yield takeEvery("dashboard/getMRQCounts", MRQCounts);
}
export function* watchGetSTNCounts() {
  yield takeEvery("dashboard/getSTNCounts", STNCounts);
}
export function* watchGetACKCounts() {
  yield takeEvery("dashboard/getACKCounts", ACKCounts);
}
export function* watchGetStockCounts() {
  yield takeEvery("dashboard/getStockCounts", StockCounts);
}

export function* watchGetUserWarehousesList() {
  yield takeEvery("dashboard/getUserWarehousesList", UserWarehousesList);
}
export function* watchGetStockValues() {
  yield takeEvery("dashboard/getStockValues", StockValuesGet);
}

function* dashboardSaga() {
  yield all([
    fork(watchGetMRQCounts),
    fork(watchGetSTNCounts),
    fork(watchGetACKCounts),
    fork(watchGetStockCounts),
    fork(watchGetUserWarehousesList),
    fork(watchGetStockValues),
  ]);
}

export default dashboardSaga;
