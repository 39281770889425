import { createSlice } from "@reduxjs/toolkit";
var initData = {
  reportsItemsList: [],
  reportDataList: [],
  modelName: "",
  // reportParamsData: { file_format: 5 },
  columnsList: [],
  listCount: 0,
  reportView: false,
  materialItemList: [],
  materialItemParams: {
    no_of_pages: 0,
    page_size: 10,
    page: 1,
    search: "",
  },
  reportParamsData: { 
    file_format: 5,
    no_of_pages: 0,
    page_size: 10,
    page: 1,
    search: "",
    model_name: "",
  },
  scheduleReports: {
    scheduleCount: 0,
    getScheduleReportsData: {},
    getScheduleReportsSuccessData: [],
  },
};
export const reportsSlice = createSlice({
  name: "reports",
  initialState: initData,
  reducers: {
    clearData: (state , action) => {
      return {
        ...state,
        loading: false,
        reportsItemsList: [],
        reportParamsData: {
          ...initData.reportParamsData,
          file_format: action.payload?.fileformat,
        },
        reportDataList: [],
        columnsList: [],
        reportView: false,
        // listCount: 0,
      };
    },

    InputChangeValue: (state, action) => {
      return {
        ...state,
        reportParamsData: {
          ...state.reportParamsData,
          [action.payload.key]: action.payload.value,
        },
      };
    },


    // ReportListSuccessful: (state, action) => {
    //   console.log("ReportListSuccessful", action.payload);
    //   var response = action.payload;
    //   var list = [];
    //   if (state.reportParamsData?.page == 1) {
    //     list = response.results;
    //   } else {
    //     list = response.results;
    //   }
    //   var noofpages = Math.ceil(
    //     action.payload.count / state.reportParamsData?.page_size
    //   );
    //   return {
    //     ...state,
    //     reportDataList: list,
    //     listCount: action.payload.count,
    //     loading: false,
    //     reportParamsData: {
    //       ...state.reportParamsData,
    //       no_of_pages: noofpages,
    //     },
    //   };
    // },
    
    getReportList: (state, action) => {
      // if (state.reportParamsData?.file_format == 5 && state.reportParamsData?.page == 1) {
      //   return {
      //     ...state,
      //     loading: true,
      //     reportDataList: [],
      //     reportParamsData: {
      //       ...state.reportParamsData,
      //       page: action.payload.ParamsData.page,
      //       page_size: action.payload.ParamsData.page_size,
      //     }
      //   };
      // } else
       if (state.reportParamsData?.file_format == 5) {
        return {
          ...state,
          loading: true,
          model_name: action.payload.objData.model_name,
          reportDataList: [],
          reportParamsData: {
            ...state.reportParamsData,
            page: action.payload.ParamsData.page,
            page_size: action.payload.ParamsData.page_size,
          }
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    ReportListSuccessful: (state, action) => {
      var list = action.payload.results
      var columns = [];
      if (list.length > 0 && state.reportParamsData?.page == 1) {
        columns = Object.keys(list[0]);
      }else{
        columns = state.columnsList
      }
      var noofpages = Math.ceil(
        action.payload.count / state.reportParamsData?.page_size
      );
      return {
        ...state,
        reportDataList: list,
        columnsList: columns,
        loading: false,
        reportView: true,
        listCount: action.payload.count,
        reportParamsData: {
          ...state.reportParamsData,
          no_of_pages: noofpages,
        },
      };
    },

    reportStatus: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    reportDownload: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    reportDownloadSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
      };
    },
    delayReport: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    getMaterialItem: (state, action) => {
      if (state.materialItemParams?.page == 1) {
        return {
          ...state,
          loading: true,
          materialItemList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    materialItemSuccessful: (state, action) => {
      console.log("materialItemSuccessful", action.payload);
      var response = action.payload;
      var list = [];
      if (state.materialItemParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.materialItemList, ...response.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.materialItemParams?.page_size
      );
      return {
        ...state,
        listCount: action.payload.count,
        materialItemList: list,
        loading: false,
        materialItemParams: {
          ...state.materialItemParams,
          no_of_pages: noofpages,
        },
      };

      // var response = action.payload;
      // var list = [];
      // if (state.materialItemParams?.page == 1) {
      //   list = response.results;
      // } else {
      //   list = [...state.materialItemList, ...response.results];
      // }
      // var noofpages = Math.ceil(
      //   action.payload.count / state.materialItemParams?.page_size
      // );
      // return {
      //   ...state,
      //   materialItemList: list,
      //   loading: false,
      //   materialItemParams: {
      //     ...state.materialItemParams,
      //     no_of_pages: noofpages,
      //   },
      // };
    },
    setMaterialItemParams: (state, action) => {
      return {
        ...state,
        materialItemParams: action.payload,
      };
    },
    
    loadingStatus: (state, action) => {
      return { ...state, loading: action.payload };
    },
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
    getScheduleReports: (state, action) => {
      return {
        ...state,
        loading: true,
        scheduleReports: {
          ...state.scheduleReports,
          getScheduleReportsData: action.payload.paramsData,
        },
      };
    },
    getScheduleReportsSuccess: (state, action) => {
      return {
        ...state,
        loading: false,
        scheduleReports: {
          ...state.scheduleReports,
          scheduleCount: action.payload.count,
          getScheduleReportsSuccessData: action.payload.results,
        },
      };
    },
    startDeleteScheduleReportById: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    DeleteScheduleReportByIdSuccess: (state, action) => {
      // console.log({ ...state.scheduleReports.getScheduleReportsSuccessData });
      return {
        ...state,
        loading: false,
        scheduleReports: {
          ...state.scheduleReports,
          getScheduleReportsSuccessData:
            state.scheduleReports.getScheduleReportsSuccessData.filter(
              (e) => !(e.id === action.payload)
            ),
        },
      };
    },
    startPostScheduleReport: (state, action) => {
      return {
        ...state,
        loading: true,
        scheduleReports: {
          ...state.scheduleReports,
          postData: action.payload,
        },
      };
    },
    // postScheduleReportSucess: (state, action) => {
    //   console.log("postScheduleReportSucess", state.scheduleReports);
    //   return {
    //     ...state,
    //     loading: false,
    //     scheduleReports: {
    //       ...state.scheduleReports,
    //       // getScheduleReportsSuccessData: [
    //       //   ...state.scheduleReports.getScheduleReportsSuccessData,
    //       //   action.payload,
    //       // ],
    //       // state.scheduleReports.getScheduleReportsSuccessData.map(
    //       //   (e) => (e, action.payload)
    //       // ),
    //     },
    //   };
    // },

    postScheduleReportSucess: (state, action) => {
      return {
        ...state,
        loading: false,
        scheduleReports: {
          ...state.scheduleReports,
          postData: {
            email: "",
            startdate: "2023-09-08",
            time: "2023-09-08-12:00:00",
            frequency: 1,
            repeatdays: null,
            reportname: "Daily Attendance Report",
          },
          getScheduleReportsSuccessData: [
            action.payload,
            ...state.scheduleReports.getScheduleReportsSuccessData,
          ],
        },
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  clearData,
  InputChangeValue,
  getReportList,
  ReportListSuccessful,
  loadingStatus,
  apiError,
  getMaterialItem,
  materialItemSuccessful,
  setMaterialItemParams,
  reportStatus,
  reportDownload,
  delayReport,
  reportDownloadSuccessful,
  getScheduleReports,
  getScheduleReportsSuccess,
  startDeleteScheduleReportById,
  DeleteScheduleReportByIdSuccess,
  startPostScheduleReport,
  postScheduleReportSucess,
} = reportsSlice.actions;

export default reportsSlice.reducer;
