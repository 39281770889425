import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States

import {
  apiError,
  ImportModelsListSuccessful,
  ExportModelsListSuccessful,
  uploadImportDataSuccessful,
  uploadImportType2DataSuccessful,
  submitImportDataSuccessful,
  loadingStatus,
  uploadImportStatus,
  downloadExportData,
  submitImportData,
  downloadRequeststatus,
  exportDownloadSuccess,
  delayImport,
  progressImportStatus,
  ImportProgessDelay,
} from "./importExportSlice";

// AUTH related methods
import {
  postAdd,
  postFormData,
  getList,
  postDownloadFileRequest,
  postDownloadFileRequestById,
  postDownloadFile,
} from "../../../helpers/Helper";
import { Modal } from "antd";
import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";
import fileDownload from "js-file-download";
function* ImportModelsList() {
  try {
    const response = yield call(getList, "/reports/generic_import_models/", {});
    yield put(ImportModelsListSuccessful(response.data));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}
function* ExportModelsList() {
  try {
    const response = yield call(getList, "/reports/generic_export_models/", {});
    yield put(ExportModelsListSuccessful(response.data));
    // yield downloadRequeststatus(response.data);
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* UploadImportType2Data({ payload }) {
  try {
    const response = yield call(
      postFormData,
      "/reports/generic_import_2/dryrun/",
      payload
    );
    yield put(uploadImportType2DataSuccessful(response.data));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* SubmitImportType2Data({ payload }) {
  try {
    const response = yield call(
      postAdd,
      "/reports/generic_import_2/process/",
      payload
    );
    yield put(submitImportDataSuccessful(response.data));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* PostDownloadRequestByid({ payload }) {
  try {
    let response = yield call(
      postDownloadFile,
      "/reports/generic_export/download/" + payload.request_id + "/",
      payload
    );
    if (response.status) {
      Modal.destroyAll();
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              File Export Successfull
            </p>
          </div>
        ),
      });
      yield put(loadingStatus(false));
    } else {
      Modal.info({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Loading..</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">Processing</p>
          </div>
        ),
      });
      yield put(loadingStatus(true));
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

function* PostDownloadRequest({ payload }) {
  try {
    let res = yield call(
      postDownloadFileRequestById,
      `/reports/generic_export/status/${payload.request_id}/`
    );

    if (res.data.status == false) {
      yield delay(5000);
      Modal.info({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Loading</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              {res.data.message}
            </p>
          </div>
        ),
      });
      yield put(downloadRequeststatus({ request_id: payload.request_id }));
    } else if (res.data.status) {
      yield put(exportDownloadSuccess({ request_id: payload.request_id }));
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        onOk: () => {},
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">Server Errror</p>
          </div>
        ),
      });
      yield put(loadingStatus(false));
      throw new Error(res.message);
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

function* DownloadExportData({ payload }) {
  try {
    let data = yield call(
      postDownloadFileRequest,
      "/reports/generic_export/",
      payload
    );
    if (data.data.request_id) {
      Modal.info({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Process Started</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              {data.data.message}
            </p>
          </div>
        ),
      });
      yield put(downloadRequeststatus({ request_id: data.data.request_id }));
      // yield PostDownloadRequest(data.data);
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">Server Errror</p>
          </div>
        ),
      });
      yield put(loadingStatus(false));
      throw new Error(res.message);
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

// Old Code
// function* UploadImportData({ payload }) {
//   try {
//     const response = yield call(
//       postFormData,
//       "/reports/generic_import/dryrun",
//       payload
//     );
//     yield put(uploadImportDataSuccessful(response.data));
//   } catch (error) {
//     Modal.error({
//       icon: (
//         <span className="remix-icon">
//           <RiAlertLine />
//         </span>
//       ),

//       title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
//       content: (
//         <div>
//           <p className="hp-p1-body hp-text-color-black-80">
//             Sorry! Getting from server side issue!
//           </p>
//         </div>
//       ),
//     });
//     yield put(apiError(error));
//   }
// }

const delay = (ms) =>
  new Promise((resolve) => {
    setTimeout(() => {
      // here is where I want to specify my timeout logic
      resolve();
    }, ms);
  });

function* UploadImportData({ payload }) {
  try {
    const response = yield call(
      postFormData,
      "/reports/generic_import/dryrun",
      payload
    );
    if (response.status == 200) {
      yield put(
        uploadImportStatus({
          request_id: response.data.request_id,
        })
      );
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Import is still running
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* ImportDelay({ payload }) {
  let timeout;
  timeout = yield delay(10000);
  yield put(uploadImportStatus({ request_id: payload.request_id }));
}

function* ImportUploadStatus({ payload }) {
  try {
    var response = yield call(
      postAdd,
      "/reports/generic_import/status/" + payload.request_id + "/",
      {}
    );
    if (response.status === 201) {
      Modal.destroyAll();
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Preview the data and click on next to continue the process
            </p>
          </div>
        ),
      });

      yield put(uploadImportDataSuccessful(response.data.message));
    } else if (response.status === 202) {
      Modal.info({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Loading..</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Import is still running , be patient
            </p>
          </div>
        ),
      });
      yield put(delayImport({ request_id: payload.request_id }));
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Import is still running
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* progressImportDelay({ payload }) {
  let timeout;
  timeout = yield delay(10000);
  yield put(progressImportStatus({ request_id: payload.request_id }));
}

function* ImportProgressStatus({ payload }) {
  try {
    var response = yield call(
      postAdd,
      "/reports/generic_import/status/" + payload.request_id + "/",
      {}
    );
    if (response.status === 201) {
      Modal.destroyAll();
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Successfully Uploaded
            </p>
          </div>
        ),
      });
      yield put(submitImportDataSuccessful(response.data.message));
    } else if (response.status === 202) {
      Modal.info({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Loading..</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Import is still running , be patient
            </p>
          </div>
        ),
      });
      yield put(ImportProgessDelay({ request_id: payload.request_id }));
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! failed to generate the process report!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* SubmitImportData({ payload }) {
  try {
    const response = yield call(postAdd, "/reports/generic_import/process", {
      ...payload,
      ...payload.initial,
    });
    if (response.status == 200) {
      yield put(
        progressImportStatus({
          request_id: response.data.request_id,
        })
      );
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),

        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Getting from server side issue!
            </p>
          </div>
        ),
      });
    }
    // yield put(submitImportDataSuccessful(response.data));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

export function* watchGetImportModelsList() {
  yield takeEvery("importExport/getImportModelsList", ImportModelsList);
}
export function* watchGetExportModelsList() {
  yield takeEvery("importExport/getExportModelsList", ExportModelsList);
}

export function* watchUploadImportType2Data() {
  yield takeEvery("importExport/uploadImportType2Data", UploadImportType2Data);
}

export function* watchSubmitImportType2Data() {
  yield takeEvery("importExport/submitImportType2Data", SubmitImportType2Data);
}

export function* watchUploadImportData() {
  yield takeEvery("importExport/uploadImportData", UploadImportData);
}
export function* watchUploadImportStatus() {
  yield takeEvery("importExport/uploadImportStatus", ImportUploadStatus);
}
export function* watchDelayImport() {
  yield takeEvery("importExport/delayImport", ImportDelay);
}
export function* watchImportProgessDelay() {
  yield takeEvery("importExport/ImportProgessDelay", progressImportDelay);
}
export function* watchProgressImportStatus() {
  yield takeEvery("importExport/progressImportStatus", ImportProgressStatus);
}
export function* watchSubmitImportData() {
  yield takeEvery("importExport/submitImportData", SubmitImportData);
}

export function* watchDownloadExportData() {
  yield takeEvery("importExport/downloadExportData", DownloadExportData);
}

export function* watchDownloadRequeststatus() {
  yield takeEvery("importExport/downloadRequeststatus", PostDownloadRequest);
}
export function* watchPostDownloadRequestByid() {
  yield takeEvery(
    "importExport/exportDownloadSuccess",
    PostDownloadRequestByid
  );
}

function* ImportExportSaga() {
  yield all([
    fork(watchGetImportModelsList),
    fork(watchGetExportModelsList),
    fork(watchUploadImportData),
    fork(watchUploadImportType2Data),
    fork(watchSubmitImportData),
    fork(watchSubmitImportType2Data),
    fork(watchUploadImportStatus),
    fork(watchDelayImport),
    fork(watchProgressImportStatus),
    fork(watchImportProgessDelay),
    fork(watchDownloadExportData),
    fork(watchDownloadRequeststatus),
    fork(watchPostDownloadRequestByid),
  ]);
}

export default ImportExportSaga;
