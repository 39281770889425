import { createSlice } from "@reduxjs/toolkit";
import themeConfig from "../../configs/themeConfig";

export const customiseSlice = createSlice({
  name: "customise",
  initialState: {
    theme: themeConfig.theme,
    contentWidth: themeConfig.contentWidth,
    sidebarCollapsed: themeConfig.sidebarCollapsed,
    sidebarCollapseButton: themeConfig.sidebarCollapseButton,
    layout: themeConfig.layout,
    navigationFull: themeConfig.navigationFull,
    navigationBg: themeConfig.navigationBg,
    direction: themeConfig.direction,
    sidebarMenuEnable: false,
  },
  reducers: {
    theme: (state, action) => {
      return {
        ...state,
        theme: action.payload,
      };
    },
    contentWidth: (state, action) => {
      return {
        ...state,
        contentWidth: action.payload,
      };
    },
    sidebarCollapsed: (state, action) => {
      return {
        ...state,
        sidebarCollapsed: action.payload,
      };
    },
    sidebarCollapseButton: (state, action) => {
      return {
        ...state,
        sidebarCollapseButton: action.payload,
      };
    },
    sidebarEnable: (state, action) => {
      return {
        ...state,
        sidebarMenuEnable: action.payload,
      };
    },
    layoutChange: (state, action) => {
      return {
        ...state,
        layout: action.payload,
      };
    },
    navigationFull: (state, action) => {
      return {
        ...state,
        navigationFull: action.payload,
      };
    },
    navigationBg: (state, action) => {
      return {
        ...state,
        navigationBg: action.payload,
      };
    },
    direction: (state, action) => {
      return {
        ...state,
        direction: action.payload,
      };
    },
  },
});

export const {
  theme,
  contentWidth,
  sidebarCollapsed,
  sidebarCollapseButton,
  layoutChange,
  navigationFull,
  navigationBg,
  sidebarEnable,
} = customiseSlice.actions;

export default customiseSlice.reducer;
