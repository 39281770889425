import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import moment from "moment";
// Login Redux States

import {
  apiError,
  userPermissionsSuccessful,
  miniBranchListSuccessful,
  miniDepartmentSuccessful,
  miniDesignationSuccessful,
  miniWarehouseSuccessful,
  miniMaterialCategorySuccessful,
  miniMaterialGroupSuccessful,
  miniMaterialItemSuccessful,
  miniStockPointSuccessful,
  userDataSuccessful,
  allDeptByBranchSuccessful,
  allWarehouseByDeptBranchSuccessful,
  userDeptByBranchSuccessful,
  miniUserBranchSuccessful,
  userWarehouseSuccessful,
  miniUserSuccessful,
  miniReportingUserSuccessful,
  miniMaterialTypeSuccessful,
  miniResourceCategorySuccessful,
  miniResourceEmployeeSuccessful,
  miniResourceVendorSuccessful,
  miniDeptWarehouseListSuccessful,
  miniUserStockpointListSuccessful,
  miniPettyCashItemSuccessful,
  miniUtilityBillingSuccessful,
  miniUtilityItemNumSuccessful,
  // wheelsmart code
  miniCountersSuccessful,
  miniZonesSuccessful,
  miniClustersSuccessful,
  miniDivisionsSuccessful,
  miniOccupationSuccessful,
  miniRejectReasonsSuccessful,
  miniManagersSuccessful,
} from "./miniSlice";

// AUTH related methods
import { getList, getParamsList } from "../../helpers/Helper";
function* getUserPermissions() {
  try {
    const response = yield call(getList, "/users/userpermissions/", {});
    yield put(userPermissionsSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* MiniDesignationsGet({ payload }) {
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/masters/mini/designations/",
      params
    );
    yield put(miniDesignationSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* MiniWarehouseGet({ payload }) {
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/masters/mini/warehouse/",
      params
    );
    yield put(miniWarehouseSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* MiniMaterialCategoryGet({ payload }) {
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/masters/mini/material/categoty/",
      params
    );
    yield put(miniMaterialCategorySuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* MiniMaterialGroupGet({ payload }) {
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/masters/mini/material/group/",
      params
    );
    yield put(miniMaterialGroupSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* MiniMaterialItemGet({ payload }) {
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/masters/mini/material/items/",
      params
    );
    yield put(miniMaterialItemSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* MiniStockPointGet({ payload }) {
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/masters/mini/stockpoints/",
      params
    );
    yield put(miniStockPointSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* UserDataGet({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/users/iamuser/", {});
    yield put(userDataSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

// Mini Branches
function* miniBranchesGet({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "" && k !== "no_of_pages") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/masters/mini/branches/",
      params
    );
    yield put(miniBranchListSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

// Mini Departments
function* MiniDepartmentGet({ payload }) {
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/masters/mini/departments/",
      params
    );
    yield put(miniDepartmentSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

// All Departments By Branch

function* AllDeptByBranchGet({ payload }) {
  var params = {};
  for (const k in payload.params) {
    if (Object.hasOwnProperty.call(payload.params, k)) {
      if (k === "type") {
        params.type = payload.params.type.join(",");
      } else {
        if (payload.params[k] !== "" && k !== "no_of_pages") {
          params[k] = payload.params[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/masters/mini/departments/by_branch/" + payload.branch_id,
      params
    );
    yield put(allDeptByBranchSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

// All Warehouse By Dept Branch

function* AllWarehouseByDeptBranch({ payload }) {
  var params = {};
  for (const k in payload.params) {
    if (Object.hasOwnProperty.call(payload.params, k)) {
      if (k === "type") {
        params.type = payload.params.type.join(",");
      } else {
        if (payload.params[k] !== "" && k !== "no_of_pages") {
          params[k] = payload.params[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/masters/user/warehouses/list/" +
        payload.department_id +
        "/" +
        payload.branch_id,
      params
    );
    yield put(allWarehouseByDeptBranchSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

// User Dept By Branche

function* UserDeptByBranchGet({ payload }) {
  var data = payload.params;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "" && k !== "no_of_pages") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/masters/mini/user/departments/by_branch/" + payload.branch_id,
      params
    );
    yield put(userDeptByBranchSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

// User Branches
function* MiniUserBranchGet({ payload }) {
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/masters/mini/user/branches/",
      params
    );
    yield put(miniUserBranchSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* UserWarehouseGet({ payload }) {
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/masters/user/warehouses/list/",
      params
    );
    yield put(userWarehouseSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* MiniUsersGet({ payload }) {
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/users/mini/users/", params);
    yield put(miniUserSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* MiniReportingUsersGet({ payload }) {
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/users/mini/reporting_users/",
      params
    );
    yield put(miniReportingUserSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* MiniMaterialTypesGet({ payload }) {
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/masters/mini/materialtype/",
      params
    );
    yield put(miniMaterialTypeSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* MiniResourceCategoryGet({ payload }) {
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/masters/mini/resource/category/",
      params
    );
    yield put(miniResourceCategorySuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* MiniResourceVendorGet({ payload }) {
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/masters/mini/resource/vendor/",
      params
    );
    yield put(miniResourceVendorSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* MiniDeptWarehouseListGet({ payload }) {
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages" && k !== "warehouse_id") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/masters/dept/warehouse_list/" + payload.warehouse_id,
      params
    );
    yield put(miniDeptWarehouseListSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* MiniUserStockpointListGet({ payload }) {
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/masters/user/stockpoint/list/",
      params
    );
    yield put(miniUserStockpointListSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}


function* MiniPettyCashItems({ payload }) {
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/masters/mini/utilitybilling/petty_cash_items/",
      params
    );
    yield put(miniPettyCashItemSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* MiniUtilityBilling({ payload }) {
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/masters/mini/utilitybilling/service_items/",
      params
    );
    yield put(miniUtilityBillingSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* MiniUtilityItemNum({ payload }) {
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/masters/mini/utilitybilling/service_item_nos/",
      params
    );
    yield put(miniUtilityItemNumSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

// wheelsmart code

function* getCounters({ payload }) {
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/masters/mini/counters/",
      params
    );
    yield put(miniCountersSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* getZones({ payload }) {
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/masters/mini/zones/", params);
    yield put(miniZonesSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* getClusters({ payload }) {
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/masters/mini/clusters/",
      params
    );
    yield put(miniClustersSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}
function* getDivisions({ payload }) {
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/masters/mini/divisions/",
      params
    );
    yield put(miniDivisionsSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* getOccupation() {
  try {
    const response = yield call(
      getParamsList,
      "/masters/mini/occupations/",
      {}
    );
    yield put(miniOccupationSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* getRejectReasons({ payload }) {
  var params = {};

  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/masters/mini/rejectreasons/",
      params
    );
    yield put(miniRejectReasonsSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* getManagers() {
  try {
    const response = yield call(
      getParamsList,
      "/permissions/mini/managers/",
      {}
    );
    yield put(miniManagersSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

export function* watchGetUserPermissions() {
  yield takeEvery("mini/getUserPermissions", getUserPermissions);
}
export function* watchGetMiniBranches() {
  yield takeEvery("mini/getMiniBranches", miniBranchesGet);
}
export function* watchGetMiniDepartment() {
  yield takeEvery("mini/getMiniDepartment", MiniDepartmentGet);
}
export function* watchGetMiniDesignations() {
  yield takeEvery("mini/getMiniDesignations", MiniDesignationsGet);
}
export function* watchGetMiniWarehouse() {
  yield takeEvery("mini/getMiniWarehouse", MiniWarehouseGet);
}
export function* watchGetMiniMaterialCategory() {
  yield takeEvery("mini/getMiniMaterialCategory", MiniMaterialCategoryGet);
}
export function* watchGetMiniMaterialGroup() {
  yield takeEvery("mini/getMiniMaterialGroup", MiniMaterialGroupGet);
}
export function* watchGetMiniMaterialItem() {
  yield takeEvery("mini/getMiniMaterialItem", MiniMaterialItemGet);
}
export function* watchGetMiniStockPoint() {
  yield takeEvery("mini/getMiniStockPoint", MiniStockPointGet);
}
export function* watchGetUserData() {
  yield takeEvery("mini/getUserData", UserDataGet);
}
export function* watchGetAllDeptByBranch() {
  yield takeEvery("mini/getAllDeptByBranch", AllDeptByBranchGet);
}
export function* watchGetAllWarehouseByDeptBranch() {
  yield takeEvery("mini/getAllWarehouseByDeptBranch", AllWarehouseByDeptBranch);
}
export function* watchGetUserDeptByBranch() {
  yield takeEvery("mini/getUserDeptByBranch", UserDeptByBranchGet);
}
export function* watchGetMiniUserBranch() {
  yield takeEvery("mini/getMiniUserBranches", MiniUserBranchGet);
}
export function* watchGetUserWarehouse() {
  yield takeEvery("mini/getUserWarehouse", UserWarehouseGet);
}
export function* watchGetMiniUsers() {
  yield takeEvery("mini/getMiniUsers", MiniUsersGet);
}
export function* watchGetMiniReportingUsers() {
  yield takeEvery("mini/getMiniReportingUsers", MiniReportingUsersGet);
}
export function* watchGetMiniMaterialTypes() {
  yield takeEvery("mini/getMiniMaterialType", MiniMaterialTypesGet);
}
export function* watchGetMiniResourceCategory() {
  yield takeEvery("mini/getMiniResourceCategory", MiniResourceCategoryGet);
}

export function* watchGetMiniResourceVendor() {
  yield takeEvery("mini/getMiniResourceVendor", MiniResourceVendorGet);
}
export function* watchGetMiniDeptWarehouseList() {
  yield takeEvery("mini/getMiniDeptWarehouseList", MiniDeptWarehouseListGet);
}
export function* watchGetMiniUserStockpointList() {
  yield takeEvery("mini/getMiniUserStockpointList", MiniUserStockpointListGet);
}
export function* watchGetMiniPettyCashItems() {
  yield takeEvery("mini/getMiniPettyCashItems", MiniPettyCashItems);
}
export function* watchGetMiniUtilityBilling() {
  yield takeEvery("mini/getMiniUtilityBilling", MiniUtilityBilling);
}
export function* watchGetMiniUtilityItemNum() {
  yield takeEvery("mini/getMiniUtilityItemNum", MiniUtilityItemNum);
}
// Wheelsmart Code

export function* watchgetMiniCounters() {
  yield takeEvery("mini/getMiniCounters", getCounters);
}

export function* watchgetMiniZones() {
  yield takeEvery("mini/getMiniZones", getZones);
}
export function* watchgetMiniClusters() {
  yield takeEvery("mini/getMiniClusters", getClusters);
}
export function* getMiniDivisions() {
  yield takeEvery("mini/getMiniDivisions", getDivisions);
}

export function* watchgetMiniOccupation() {
  yield takeEvery("mini/getMiniOccupation", getOccupation);
}

export function* watchGetMiniRejectReasons() {
  yield takeEvery("mini/getMiniRejectReasons", getRejectReasons);
}

export function* watchGetMiniManagers() {
  yield takeEvery("mini/getMiniManagers", getManagers);
}

function* miniSaga() {
  yield all([
    fork(watchGetUserPermissions),
    fork(watchGetMiniBranches),
    fork(watchGetMiniDepartment),
    fork(watchGetMiniDesignations),
    fork(watchGetMiniWarehouse),
    fork(watchGetMiniMaterialCategory),
    fork(watchGetMiniMaterialGroup),
    fork(watchGetMiniMaterialItem),
    fork(watchGetMiniStockPoint),
    fork(watchGetUserData),
    fork(watchGetAllDeptByBranch),
    fork(watchGetAllWarehouseByDeptBranch),
    fork(watchGetUserDeptByBranch),
    fork(watchGetMiniUserBranch),
    fork(watchGetUserWarehouse),
    fork(watchGetMiniUsers),
    fork(watchGetMiniReportingUsers),
    fork(watchGetMiniMaterialTypes),
    fork(watchGetMiniResourceCategory),
    fork(watchGetMiniResourceVendor),
    fork(watchGetMiniDeptWarehouseList),
    fork(watchGetMiniUserStockpointList),
    fork(watchGetMiniPettyCashItems),
    fork(watchGetMiniUtilityBilling),
    fork(watchGetMiniUtilityItemNum),
    // Wheelsmart Code

    fork(watchgetMiniCounters),
    fork(watchgetMiniZones),
    fork(watchgetMiniClusters),
    fork(getMiniDivisions),
    fork(watchgetMiniOccupation),
    fork(watchGetMiniRejectReasons),
    fork(watchGetMiniManagers),
  ]);
}

export default miniSaga;
