import axios from "axios";
import { axiosInstance, BaseURL, TOKEN_PREFIX } from "./AxiosHelper";

import fileDownload from "js-file-download";
import { serialize } from "object-to-formdata";

const simplePost = (url, data) => {
  return axios
    .post(BaseURL + url, data)
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;

      return response;
    })
    .catch((err) => {
      throw err.response;
    });
};

const postLogin = (url, data) => {
  return axios
    .post(BaseURL + url, data)
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;

      axiosInstance.defaults.headers["Authorization"] =
        TOKEN_PREFIX + response.data.tokens.access;
      return response;
    })
    .catch((err) => {
      throw err.response;
    });
};
const postLogout = (url, data) => {
  return axiosInstance
    .post(url, data)
    .then((response) => {
      if (response.status === 400 || response.status === 500) throw response;
      axiosInstance.defaults.headers["Authorization"] = "";
      return response;
    })
    .catch((err) => {
      var message;

      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 400:
            // message = err.response.data;
            "Sorry! Error 400 - Bad request, please contact our support team";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw err;
    });
};

const getList = (url) => {
  // const user = localStorage.getItem("authUser");
  // let sjson = JSON.parse(user);
  return axiosInstance
    .get(url)
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;
      return response.data;
    })
    .catch((err) => {
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 400:
            // message = err.response.data;
            message =
              "Sorry! Error 400 - Bad request, please contact our support team";
            break;
          case 403:
            message =
              "Sorry! you don't have permission to access, please contact admin";
            break;
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 502:
            message = "Sorry! Bad Gateway, please contact our support team";
            break;
          case 503:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          default:
            message = err[1];
            break;
        }
      } else if (err.request) {
        message =
          "Sorry! Error on setting up the request, please contact our support team";
      } else {
        message = err.message;
      }
      throw message;
    });
};

const getParamsList = (url, params) => {
  // const user = localStorage.getItem("authUser");
  // let sjson = JSON.parse(user);
  return axiosInstance
    .get(url, {
      params: params,
    })
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;
      return response.data;
    })
    .catch((err) => {
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 400:
            // message = err.response.data;
            message =
              "Sorry! Error 400 - Bad request, please contact our support team";
            break;
          case 403:
            message =
              "Sorry! you don't have permission to access, please contact admin";
            break;
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 502:
            message = "Sorry! Bad Gateway, please contact our support team";
            break;
          case 503:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          default:
            message = err[1];
            break;
        }
      } else if (err.request) {
        message =
          "Sorry! Error on setting up the request, please contact our support team";
      } else {
        message = err.message;
      }
      throw message;
    });
};

// postForgetPwd
// const postAdd = (url, data, params = {}) => {
//   // const user = localStorage.getItem("authUser");
//   // let sjson = JSON.parse(user);
//   return axiosInstance
//     .post(url, data, {
//       params: params,
//     })
//     .then((response) => {
//       if (response.status === 400 || response.status === 500) {
//         throw response.data;
//       }

//       return response;
//     })
//     .catch((err) => {
//       var message;
//       if (err.response && err.response.status) {
//         switch (err.response.status) {
//           case 400:
//             // message = err.response.data;
//             message =
//               "Sorry! Error 400 - Bad request, please contact our support team";
//             break;
//           case 403:
//             message =
//               "Sorry! you don't have permission to access, please contact admin";
//             break;
//           case 404:
//             message = "Sorry! the page you are looking for could not be found";
//             break;
//           case 500:
//             message =
//               "Sorry! something went wrong, please contact our support team";
//             break;
//           case 502:
//             message = "Sorry! Bad Gateway, please contact our support team";
//             break;
//           case 503:
//             message =
//               "Sorry! something went wrong, please contact our support team";
//             break;
//           default:
//             message = err[1];
//             break;
//         }
//       } else if (err.request) {
//         message =
//           "Sorry! Error on setting up the request, please contact our support team";
//       } else {
//         message = err.message;
//       }
//       throw message;
//     });
// };

const postAdd = (url, data, params = {}) => {
  // const user = localStorage.getItem("authUser");
  // let sjson = JSON.parse(user);
  return axiosInstance
    .post(url, data, {
      params: params,
    })
    .then((response) => {
      if (response.status === 400 || response.status === 500) {
        throw response;
      }

      return response;
    })
    .catch((err) => {
      var message;

      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 400:
            message = err.response.data;
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw err;
    });
};

const postFormData = (url, data) => {
  const formData = serialize(data);

  return axiosInstance
    .post(url, formData)
    .then((response) => {
      if (response.status === 400 || response.status === 500) {
        throw response;
      }

      return response;
    })
    .catch((err) => {
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 400:
            // message = err.response.data;
            message =
              "Sorry! Error 400 - Bad request, please contact our support team";
            break;
          case 403:
            message =
              "Sorry! you don't have permission to access, please contact admin";
            break;
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 502:
            message = "Sorry! Bad Gateway, please contact our support team";
            break;
          case 503:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          default:
            message = err[1];
            break;
        }
      } else if (err.request) {
        message =
          "Sorry! Error on setting up the request, please contact our support team";
      } else {
        message = err.message;
      }
      throw message;
    });
};
const putImg = (url, data) => {
  // const user = localStorage.getItem("authUser");
  // let sjson = JSON.parse(user);
  // const formData = new FormData();
  // formData.append(data.name, data.file);
  const formData = serialize(data);

  return axiosInstance
    .patch(url, formData)
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;
      return response;
    })
    .catch((err) => {
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 400:
            // message = err.response.data;
            message =
              "Sorry! Error 400 - Bad request, please contact our support team";
            break;
          case 403:
            message =
              "Sorry! you don't have permission to access, please contact admin";
            break;
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 502:
            message = "Sorry! Bad Gateway, please contact our support team";
            break;
          case 503:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          default:
            message = err[1];
            break;
        }
      } else if (err.request) {
        message =
          "Sorry! Error on setting up the request, please contact our support team";
      } else {
        message = err.message;
      }
      throw message;
    });
};
const postEdit = (url, data) => {
  // const user = localStorage.getItem("authUser");
  // let sjson = JSON.parse(user);
  return axiosInstance
    .put(url, data)
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;
      return response;
    })
    .catch((err) => {
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 400:
            // message = err.response.data;
            message =
              "Sorry! Error 400 - Bad request, please contact our support team";
            break;
          case 403:
            message =
              "Sorry! you don't have permission to access, please contact admin";
            break;
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 502:
            message = "Sorry! Bad Gateway, please contact our support team";
            break;
          case 503:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          default:
            message = err[1];
            break;
        }
      } else if (err.request) {
        message =
          "Sorry! Error on setting up the request, please contact our support team";
      } else {
        message = err.message;
      }
      throw message;
    });
};
const getDownloadFile = (url) => {
  return axiosInstance
    .get(url, {
      responseType: "blob",
    })
    .then((response) => {
      if (response.status === 400 || response.status === 500) {
        throw response.data;
      }
      var filename = "filename.pdf";
      let headerLine = response.headers["content-disposition"];
      if (headerLine) {
        let startFileNameIndex = headerLine.indexOf('"') + 1;
        let endFileNameIndex = headerLine.lastIndexOf('"');
        filename = headerLine.substring(startFileNameIndex, endFileNameIndex);
      }
      fileDownload(response.data, filename);
    })
    .catch((err) => {
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 400:
            // message = err.response.data;
            message =
              "Sorry! Error 400 - Bad request, please contact our support team";
            break;
          case 403:
            message =
              "Sorry! you don't have permission to access, please contact admin";
            break;
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 502:
            message = "Sorry! Bad Gateway, please contact our support team";
            break;
          case 503:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          default:
            message = err[1];
            break;
        }
      } else if (err.request) {
        message =
          "Sorry! Error on setting up the request, please contact our support team";
      } else {
        message = err.message;
      }
      throw message;
    });
};

const postDownloadFileRequest = (url, body) => {
  return axiosInstance
    .post(url, { ...body })
    .then((response) => {
      if (response.status === 400 || response.status === 500) {
        throw response.data;
      }

      return response;
    })
    .catch((error) => {
      return error;
    });
};

const postDownloadFileRequestById = (url, body) => {
  return axiosInstance
    .post(url, body)
    .then((response) => {
      if (response.status === 400 || response.status === 500) {
        throw response.data;
      }
      return response;
    })
    .catch((err) => {
      console.log(err.message);
    });
};
const postDownloadFile = (url, body, params = {}) => {
  return axiosInstance
    .post(url, body, {
      params: params,
      responseType: "blob",
    })
    .then((response) => {
      if (response.status === 400 || response.status === 500) {
        throw response;
      }
      var filename = "filename.csv";
      let headerLine = response.headers["content-disposition"];
      if (headerLine) {
        let startFileNameIndex = headerLine.indexOf('"') + 1;
        let endFileNameIndex = headerLine.lastIndexOf('"');
        filename = headerLine.substring(startFileNameIndex, endFileNameIndex);
      }
      fileDownload(response.data, filename);
      return response;
    })
    .catch((err) => {
      var message;

      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 400:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

const postDelete = (url) => {
  // const user = localStorage.getItem("authUser");
  // let sjson = JSON.parse(user);
  return axiosInstance
    .delete(url)
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;
      return response;
    })
    .catch((err) => {
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 400:
            // message = err.response.data;
            message =
              "Sorry! Error 400 - Bad request, please contact our support team";

            break;
          case 403:
            message =
              "Sorry! you don't have permission to access, please contact admin";
            break;
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 502:
            message = "Sorry! Bad Gateway, please contact our support team";
            break;
          case 503:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          default:
            message = err[1];
            break;
        }
      } else if (err.request) {
        message =
          "Sorry! Error on setting up the request, please contact our support team";
      } else {
        message = err.message;
      }
      throw message;
    });
};
const patchEdit = (url, data) => {
  // const user = localStorage.getItem("authUser");
  // let sjson = JSON.parse(user);
  return axiosInstance
    .patch(url, data)
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;
      return response;
    })
    .catch((err) => {
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 400:
            // message = err.response.data;
            "Sorry! Error 400 - Bad request, please contact our support team";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

export {
  simplePost,
  postLogin,
  postLogout,
  getList,
  getParamsList,
  postAdd,
  postFormData,
  putImg,
  postEdit,
  getDownloadFile,
  postDownloadFileRequest,
  postDownloadFileRequestById,
  postDownloadFile,
  patchEdit,
  postDelete,
};
