import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import moment from "moment";
import {
  apiError,
  resourceConsumptionListSuccessful,
  WorkOrderDataSuccessful,
  ResourceConsumptionItemsAdd,
  ResourceConsumptionSubItemsAdd,
  RCDocAddMake,
  RCDocViewDataSuccessful,
  getRCDocItemsById,
  RCDocItemsByIdListSuccessful,
  RCDocSubItemsByIdListSuccessful,
  RCEmployeeListSuccessful,
  addRCEmployeeDrawer,
  RCL1ApprovalsListSuccessful,
  RCSubItemL1CheckSuccessful,
  clearRCL1Data,
  RCL2ApprovalsListSuccessful,
  RCL2EmployeesListSuccessful,
  setWorkOrderDataParams,
  getWorkOrderData,
  isRCEmployeeModelVisible,
  getRCEmployeeList,
  RCEmployeeByIDListSuccessful,
  RCEmployeeByIDModelVisible,
  getRCL1ApprovalsList,
  miniResourceEmployeeSuccessful 
} from "./ResourceConsumptionSlice";
import {
  getList,
  getParamsList,
  postAdd,
  postEdit,
  postFormData,
  postDelete,
} from "../../../helpers/Helper";
import { Modal } from "antd";

import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";

//If user is login then dispatch redux action's are directly from here.

function* ResourceConsumptionListGet({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] != "" && k != null && k != "no_of_pages") {
          if (k == "start_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k == "end_date") {
            params[k] = moment(data[k]).add(1, "days").format("YYYY-MM-DD");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/resourceconsumption/list/",
      params
    );
    yield put(resourceConsumptionListSuccessful({ response }));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}
function* ResourceDocvalidateGet({ payload }) {
  try {
    const response = yield call(
      postAdd,
      "/resourceconsumption/doc/validate",
      payload
    );
    if (response.status === 200) {
      yield put(
        setWorkOrderDataParams({
          page: 1,
          page_size: 10,
        })
      );
      yield put(
        getWorkOrderData({
          Params: { page: 1, page_size: 10 },
          urlData: {
            branch: payload.branch,
            category: payload.resource_category,
          },
        })
      );
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Document already Exists. Please try again!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    console.log("error", error.response);
    if (error.response.status === 400) {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              {error.response.data.Message[0]}
            </p>
          </div>
        ),
      });
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
  
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Getting from server side issue!
            </p>
          </div>
        ),
      });
    }
   
    // yield put(addPurchaseSuccessful(null));
    yield put(apiError(error));
  }
}

function* WorkOrderDataGet({ payload }) {
  var params = {};
  var data = payload.Params;
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "type") {
        params.type = data.type.join(",");
      } else {
        if (data[k] !== "" && k !== "no_of_pages") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/resourceconsumption/branch/" +
        payload.urlData.branch +
        "/resourcecategory/" +
        payload.urlData.category,
      params
    );
    yield put(WorkOrderDataSuccessful(response));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* ResourceConsumptionAdd({ payload }) {
  try {
    const response = yield call(
      postAdd,
      "/resourceconsumption/doc/create",
      payload.rcDocObj
    );
    if (response.status === 200) {
      yield put(
        ResourceConsumptionItemsAdd({
          rcDocObj: payload.rcDocObj,
          RCItemDataList: payload.RCItemDataList,
          RCSubItemDataList: payload.RCSubItemDataList,
          unique_id: response.data.unique_id,
          history: payload.history,
        })
      );
    } else {
      yield put(
        RCDocAddMake({
          rcDocObj: payload.rcDocObj,
          unique_id: payload.unique_id,
          do_save: false,
        })
      );
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Unable to added Resource Consumption. Please try again!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });

    yield put(apiError(error));
  }
}
function* AddResourceConsumptionItems({ payload }) {
  try {
    const response = yield call(postAdd, "/resourceconsumption/items/create", {
      rcitems: payload.RCItemDataList,
      unique_id: payload.unique_id,
    });
    if (response.status === 200) {
      yield put(
        ResourceConsumptionSubItemsAdd({
          rcDocObj: payload.rcDocObj,
          RCItemDataList: payload.RCItemDataList,
          RCSubItemDataList: payload.RCSubItemDataList,
          unique_id: payload.unique_id,
          history: payload.history,
        })
      );
    } else {
      yield put(
        RCDocAddMake({
          rcDocObj: payload.rcDocObj,
          unique_id: payload.unique_id,
          do_save: false,
        })
      );
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Unable to added Resource Consumption. Please try again!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    yield put(
      RCDocAddMake({
        rcDocObj: payload.rcDocObj,
        unique_id: payload.unique_id,
        do_save: false,
      })
    );
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}
function* AddResourceConsumptionSubItems({ payload }) {
  try {
    var response = yield call(postAdd, "/resourceconsumption/subitems/create", {
      rcsubitems: payload.RCSubItemDataList,
      unique_id: payload.unique_id,
    });
    if (response.status === 200) {
      yield put(
        RCDocAddMake({
          rcDocObj: payload.rcDocObj,
          unique_id: payload.unique_id,
          do_save: true,
          history: payload.history,
        })
      );
    } else {
      yield put(
        RCDocAddMake({
          rcDocObj: payload.rcDocObj,
          unique_id: payload.unique_id,
          do_save: false,
        })
      );
    }
  } catch (error) {
    yield put(
      RCDocAddMake({
        rcDocObj: payload.rcDocObj,
        unique_id: payload.unique_id,
        do_save: false,
      })
    );

    yield put(apiError(error));
  }
}
function* AddRCDocAddMake({ payload }) {
  try {
    const response = yield call(postAdd, "/resourceconsumption/mark_saved/", {
      unique_id: payload.unique_id,
      do_save: payload.do_save,
    });
    if (response.status === 200) {
      if (payload.do_save) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Resource Consumption Added Successfully.!
              </p>
            </div>
          ),
        });
        payload.history.push("/pages/resource-consumption");
      } else {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to added Resource Consumption. Please try again!
              </p>
            </div>
          ),
        });
        // payload.history.push("/pages/Acknowledgement");
      }
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Unable to Update Resource Consumption. Please try again!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}
function* RCDocViewDataGet({ payload }) {
  try {
    const response = yield call(
      getParamsList,
      "/resourceconsumption/" + payload,
      {}
    );
    yield put(RCDocViewDataSuccessful({ response }));
    yield put(getRCDocItemsById({ id: response.id, page_size: 10, page: 1 }));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}
function* RCDocItemsByIdGet({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "" && k !== "id") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/resourceconsumption/rcitems/" + payload.id,
      params
    );
    yield put(RCDocItemsByIdListSuccessful(response));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}
function* RCDocSubItemsByIdGet({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "" && k !== "id") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/resourceconsumption/rc_sub_items/" + payload.id,
      params
    );
    yield put(RCDocSubItemsByIdListSuccessful(response));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}
function* RCEmployeeList({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "" && k !== null && k !== "no_of_pages") {
          if (k == "start_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k == "end_date") {
            params[k] = moment(data[k]).add(1, "days").format("YYYY-MM-DD");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/masters/resource/employee/create",
      params
    );
    yield put(RCEmployeeListSuccessful({ response }));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* SubmitRCEmployee({ payload }) {
  try {
    const response = yield call(
      postAdd,
      "/masters/resource/employee/create",
      payload.data
    );
    if (response.status === 201) {
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Your Resource Employee Added Successfully.!
            </p>
          </div>
        ),
      });
      if (payload.modelAdd) {
        yield put(isRCEmployeeModelVisible(false));
        yield put(getRCEmployeeList({ page: 1, search: "", page_size: 10 }));
      } else {
        yield put(addRCEmployeeDrawer(false));
      }
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Unable to Update Resource Employee. Please try again!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}
function* EmployeeChangeStatus({ payload }) {
  try {
    const response = yield call(
      postAdd,
      "/masters/employee/change_status",
      payload
    );
    if (response.status === 200) {
      if (payload.is_active) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Resource Employee Active Successfully.!
              </p>
            </div>
          ),
        });
      } else {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Resource Employee InActive Successfully.!
              </p>
            </div>
          ),
        });
      }
      yield put(getRCEmployeeList({ page: 1, search: "", page_size: 10 }));
      // if (payload.modelAdd) {
      //   yield put(isRCEmployeeModelVisible(false));
      // } else {
      //   yield put(addRCEmployeeDrawer(false));
      // }
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Unable to Update Resource Employee. Please try again!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}
function* RCL1ApprovalsList({ payload }) {
  var params = {};
  var data = payload.params;
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "type") {
        params.type = data.type.join(",");
      } else {
        if (data[k] !== "" && k !== "no_of_pages") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/resourceconsumption/l1_approvals/list/" +
        payload.urlData.category_id +
        "/" +
        payload.urlData.branch_id +
        "/" +
        payload.urlData.month_id +
        "/" +
        payload.urlData.year_id,
      params
    );
    yield put(RCL1ApprovalsListSuccessful(response));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}
function* RCEmployeeByIDList({ payload }) {
  var params = {};
  var data = payload.params;
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "type") {
        params.type = data.type.join(",");
      } else {
        if (data[k] !== "" && k !== "no_of_pages") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/resourceconsumption/l1_user_approvals/list/" +
        payload.urlData.category_id +
        "/" +
        payload.urlData.branch_id +
        "/" +
        payload.urlData.month_id +
        "/" +
        payload.urlData.year_id +
        "/" +
        payload.urlData.emp_id,
      params
    );
    yield put(RCEmployeeByIDListSuccessful(response));
    yield put(RCEmployeeByIDModelVisible(true));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}
function* CheckRCSubItemL1({ payload }) {
  try {
    const response = yield call(
      postAdd,
      "/resourceconsumption/user_monthly_check/",
      payload.obj
    );
    if (response.status === 200) {
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Your Resource Employee Updated Successfully.!
            </p>
          </div>
        ),
      });
      yield put(RCSubItemL1CheckSuccessful(payload));
      yield put(getRCL1ApprovalsList(payload.obj2))
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Unable to Update Resource Employee. Please try again!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}
function* CheckRCApproveLeave1({ payload }) {
  try {
    const response = yield call(
      postAdd,
      "/resourceconsumption/l1_approve/",
      payload
    );
    if (response.status === 200) {
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Your Resource Consumption Level 1 Approved Successfully.!
            </p>
          </div>
        ),
      });
      yield put(clearRCL1Data());
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Unable to Approve Resource Consumption Level 1. Please try
              again!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* RCL2ApprovalsList({ payload }) {
  var params = {};
  var data = payload.params;
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "type") {
        params.type = data.type.join(",");
      } else {
        if (data[k] !== "" && k !== "no_of_pages") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/resourceconsumption/l2_approvals/list/" +
        payload.urlData.category_id +
        "/" +
        payload.urlData.month_id +
        "/" +
        payload.urlData.year_id,
      params
    );
    yield put(RCL2ApprovalsListSuccessful(response));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}
function* CheckRCApproveLeave2({ payload }) {
  try {
    const response = yield call(
      postAdd,
      "/resourceconsumption/l2_approve/submit/",
      payload
    );
    if (response.status === 200) {
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Your Resource Consumption Level 2 Approved Successfully.!
            </p>
          </div>
        ),
      });
      yield put(clearRCL1Data());
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Unable to Approve Resource Consumption Level 2. Please try
              again!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}
function* RCL2EmployeesListGet({ payload }) {
  var params = {};
  var data = payload;
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "type") {
        params.type = data.type.join(",");
      } else {
        if (data[k] !== "" && k !== "no_of_pages") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/resourceconsumption/l2/employees/list/",
      params
    );
    yield put(RCL2EmployeesListSuccessful(response));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}



function* MiniResourceEmployeeGet({ payload }) {
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/masters/mini/resource/employee/",
      params
    );
    yield put(miniResourceEmployeeSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

export function* watchGetResourceConsumptionList() {
  yield takeEvery(
    "resourceConsumption/getResourceConsumptionList",
    ResourceConsumptionListGet
  );
}
export function* watchGetResourceDocvalidate() {
  yield takeEvery(
    "resourceConsumption/ResourceDocvalidate",
    ResourceDocvalidateGet
  );
}
export function* watchGetWorkOrderData() {
  yield takeEvery("resourceConsumption/getWorkOrderData", WorkOrderDataGet);
}
export function* watchAddResourceConsumption() {
  yield takeEvery(
    "resourceConsumption/AddResourceConsumption",
    ResourceConsumptionAdd
  );
}
export function* watchResourceConsumptionItemsAdd() {
  yield takeEvery(
    "resourceConsumption/ResourceConsumptionItemsAdd",
    AddResourceConsumptionItems
  );
}
export function* watchResourceConsumptionSubItemsAdd() {
  yield takeEvery(
    "resourceConsumption/ResourceConsumptionSubItemsAdd",
    AddResourceConsumptionSubItems
  );
}
export function* watchRCDocAddMake() {
  yield takeEvery("resourceConsumption/RCDocAddMake", AddRCDocAddMake);
}
export function* watchGetRCDocViewData() {
  yield takeEvery("resourceConsumption/getRCDocViewData", RCDocViewDataGet);
}
export function* watchGetRCDocItemsById() {
  yield takeEvery("resourceConsumption/getRCDocItemsById", RCDocItemsByIdGet);
}
export function* watchGetRCDocSubItemsById() {
  yield takeEvery(
    "resourceConsumption/getRCDocSubItemsById",
    RCDocSubItemsByIdGet
  );
}
export function* watchGetRCEmployeeList() {
  yield takeEvery("resourceConsumption/getRCEmployeeList", RCEmployeeList);
}
export function* watchGetRCEmployeeChangeStatus() {
  yield takeEvery(
    "resourceConsumption/RCEmployeeChangeStatus",
    EmployeeChangeStatus
  );
}
export function* watchGetRCEmployeeSubmit() {
  yield takeEvery("resourceConsumption/RCEmployeeSubmit", SubmitRCEmployee);
}

export function* watchGetRCL1ApprovalsList() {
  yield takeEvery(
    "resourceConsumption/getRCL1ApprovalsList",
    RCL1ApprovalsList
  );
}
export function* watchGetRCEmployeeByIDList() {
  yield takeEvery(
    "resourceConsumption/getRCEmployeeByIDList",
    RCEmployeeByIDList
  );
}
export function* watchGetRCSubItemL1Check() {
  yield takeEvery("resourceConsumption/RCSubItemL1Check", CheckRCSubItemL1);
}
export function* watchGetRCApproveLeave1() {
  yield takeEvery("resourceConsumption/RCApproveLeave1", CheckRCApproveLeave1);
}

export function* watchGetRCL2ApprovalsList() {
  yield takeEvery(
    "resourceConsumption/getRCL2ApprovalsList",
    RCL2ApprovalsList
  );
}
export function* watchGetRCApproveLeave2() {
  yield takeEvery("resourceConsumption/RCApproveLeave2", CheckRCApproveLeave2);
}
export function* watchGetRCL2EmployeesList() {
  yield takeEvery(
    "resourceConsumption/getRCL2EmployeesList",
    RCL2EmployeesListGet
  );
}

export function* watchGetMiniResourceEmployee() {
  yield takeEvery("resourceConsumption/getMiniResourceEmployee", MiniResourceEmployeeGet);
}

function* ResourceConsumptionSaga() {
  yield all([
    fork(watchGetResourceConsumptionList),
    fork(watchGetResourceDocvalidate),
    fork(watchGetWorkOrderData),
    fork(watchAddResourceConsumption),
    fork(watchResourceConsumptionItemsAdd),
    fork(watchResourceConsumptionSubItemsAdd),
    fork(watchRCDocAddMake),
    fork(watchGetRCDocViewData),
    fork(watchGetRCDocItemsById),
    fork(watchGetRCDocSubItemsById),
    fork(watchGetRCEmployeeList),
    fork(watchGetRCEmployeeSubmit),
    fork(watchGetRCL1ApprovalsList),
    fork(watchGetRCEmployeeByIDList),
    fork(watchGetRCSubItemL1Check),
    fork(watchGetRCApproveLeave1),
    fork(watchGetRCL2ApprovalsList),
    fork(watchGetRCApproveLeave2),
    fork(watchGetRCL2EmployeesList),
    fork(watchGetRCEmployeeChangeStatus),
    fork(watchGetMiniResourceEmployee),
  ]);
}

export default ResourceConsumptionSaga;
