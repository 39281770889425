import { takeEvery, fork, put, all, call, select } from "redux-saga/effects";
import moment from "moment";
import {
  postAdd,
  getList,
  getParamsList,
  postEdit,
  postDelete,
} from "../../../helpers/Helper";
import { Modal } from "antd";
import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";

import {
  FocusStockSucessfully,
  FocusStockCodeSucessfully,
} from "./stockSyncSlice";

function* StockStatus({ payload }) {
  try {
    const response = yield call(postAdd, "/system/focus-stock/", payload);
    Modal.success({
      icon: (
        <span className="remix-icon">
          <RiCheckboxCircleLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Ware House Stock Restored Successful
          </p>
        </div>
      ),
    });
    yield put(FocusStockSucessfully(false));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* StockErpCodes({ payload }) {
  var params = {};

  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }

  console.log(params, "payLoad");
  try {
    const response = yield call(
      getParamsList,
      `/masters/mini/warehouse/erp_code/`,
      params
    );
    yield put(FocusStockCodeSucessfully(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

export function* watchFocusStock() {
  yield takeEvery("stockSync/FocusStock", StockStatus);
}

export function* watchWarehouseErpCode() {
  yield takeEvery("stockSync/getFocusStcokCode", StockErpCodes);
}

function* stockSyncSaga() {
  yield all([fork(watchFocusStock)]);
  yield all([fork(watchWarehouseErpCode)]);
}

export default stockSyncSaga;
