import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import moment from "moment";
import {
  apiError,
  getAcknowledgmentList,
  acknowledgmentListSuccessful,
  stnListSuccessful,
  getSTNDocDataSuccessful,
  getSTNDocItems,
  STNDocItemListSuccessful,
  AckDOcViewDataSuccessful,
  getAckItemsById,
  AckItemsByIdListSuccessful,
  ACKItemsAdd,
  ACKDocAddMake,
  stnRecievedListSuccessful,
  mrqApprovedListSuccessful,
  userWarehouseAckSuccessful,
  getStnList,
  setAckItemsByIdParams,
} from "./AcknowledgementSlice";
import {
  getList,
  getParamsList,
  postAdd,
  postEdit,
  postFormData,
  postDelete,
} from "../../../helpers/Helper";
import { Modal } from "antd";

import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";

//If user is login then dispatch redux action's are directly from here.

function* AcknowledgmentListGet({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] != "" && k != null && k != "no_of_pages") {
          if (k == "start_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k == "end_date") {
            params[k] = moment(data[k]).add(1, "days").format("YYYY-MM-DD");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/acknowledgement/list/",
      params
    );
    yield put(acknowledgmentListSuccessful({ response }));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* StnListGet({ payload }) {
  var params = {};
  var data = payload.params;
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "type") {
        params.type = data.type.join(",");
      } else {
        if (data[k] !== "" && k !== "no_of_pages") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    var response;
    if (payload.stnData.AckType?.id === 1) {
      response = yield call(
        getParamsList,
        "/stocktransfernote/pending_list/warehouse/" +
          payload.stnData.warehouse?.id,
        { ...params, type: 1 }
      );
    } else if (payload.stnData.AckType?.id === 2) {
      response = yield call(
        getParamsList,
        "/stocktransfernote/pending_list/warehouse/" +
          payload.stnData.warehouse?.id,
        { ...params, type: 2 }
      );
    }

    yield put(stnListSuccessful(response));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* STNDocDataGet({ payload }) {
  try {
    const response = yield call(getList, "/stocktransfernote/" + payload);
    yield put(getSTNDocDataSuccessful({ response }));
    yield put(
      getSTNDocItems({ id: response.id, params: { page_size: 10, page: 1 } })
    );
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* STNDocItemsGet({ payload }) {
  var params = {};
  var data = payload?.params;
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "type") {
        params.type = data.type.join(",");
      } else {
        if (data[k] !== "" && k !== "no_of_pages") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/stocktransfernote/stn_items/" + payload.id,
      params
    );
    yield put(STNDocItemListSuccessful(response));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* AckDOcViewDataGet({ payload }) {
  try {
    const response = yield call(
      getParamsList,
      "/acknowledgement/" + payload,
      {}
    );
    yield put(AckDOcViewDataSuccessful({ response }));

    yield put(setAckItemsByIdParams({ page_size: 10, page: 1 }));
    yield put(getAckItemsById({ id: response.id, page_size: 10, page: 1 }));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* AckItemsByIdGet({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "" && k !== "id" && k !== "no_of_pages") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/acknowledgement/ackitems/" + payload.id,
      params
    );
    yield put(AckItemsByIdListSuccessful(response));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* AckAdd({ payload }) {
  try {
    const response = yield call(
      postAdd,
      "/acknowledgement/create/",
      payload.ackObj
    );
    if (response.status === 200) {
      yield put(
        ACKItemsAdd({
          ackItems: payload.ackItems,
          unique_id: response.data.unique_id,
          ackObj: payload.ackObj,
          history: payload.history,
        })
      );
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Unable to added Acknowledgment. Please try again!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* AddACKItems({ payload }) {
  var ackDocItems = payload.ackItems.map((item) => {
    return item;
  });

  try {
    var response = yield call(postAdd, "/acknowledgement/items/add/", {
      unique_id: payload.unique_id,
      ackitems: ackDocItems,
    });
    if (response.status === 200) {
      yield put(
        ACKDocAddMake({
          ackObj: payload.ackObj,
          unique_id: payload.unique_id,
          do_save: true,
          history: payload.history,
        })
      );
    } else {
      yield put(
        ACKDocAddMake({
          ackObj: payload.ackObj,
          unique_id: payload.unique_id,
          do_save: false,
        })
      );
    }
  } catch (error) {
    yield put(
      ACKDocAddMake({
        ackObj: payload.ackObj,
        unique_id: payload.unique_id,
        do_save: false,
      })
    );

    yield put(apiError(error));
  }
}

function* AddACKDocMake({ payload }) {
  try {
    const response = yield call(postAdd, "/acknowledgement/mark_saved/", {
      unique_id: payload.unique_id,
      do_save: payload.do_save,
    });
    if (response.status === 200) {
      if (payload.do_save) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Acknowledgment Added Successfully.!
              </p>
            </div>
          ),
        });

        payload.history.push("/pages/Acknowledgement");
      } else {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to added Acknowledgment. Please try again!
              </p>
            </div>
          ),
        });

        // payload.history.push("/pages/Acknowledgement");
      }
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Unable to Update Acknowledgment. Please try again!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* STNRecievedList({ payload }) {
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/stocktransfernote/recieved_list/",
      params
    );
    yield put(stnRecievedListSuccessful(response));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* MrqApprovedList({ payload }) {
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/material_request_quotes/mrq/approved",
      params
    );
    yield put(mrqApprovedListSuccessful(response));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}
function* UserWarehouseAckGet({ payload }) {
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/masters/user/warehouses/list/",
      params
    );
    if (response.results.length == 1) {
      yield put(
        getStnList({
          params: { page: 1, page_size: 10 },
          stnData: {
            warehouse: {
              id: response.results[0].id,
              name: response.results[0].name,
            },
            branch: response.results[0].branch,
            department: response.results[0].department,
            ackTypeList: 1,
          },
        })
      );
    }
    yield put(userWarehouseAckSuccessful(response));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}


function* UpdateFsyncStatus({ payload }) {
  try {
    const response = yield call(
      postAdd,
      "/thirdparty/fsyncacknowledgement/" + payload.id, {}
    );
    if (response.status === 200 ) {
      if( response.data.result == true){
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                {/* Selected Acknowledgement is successfully synchronised into focus */}
                {response.data.msg}
              </p>
            </div>
          ),
        });
        
      }else{
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                {/* Sorry! selected Acknowledgement is not posted on focus. please try
                again! */}
                {response.data.msg}
              </p>
            </div>
          ),
        });
      }
      yield put(getAcknowledgmentList({ page: 1, search: "", page_size: 10 }));
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! selected Acknowledgement is not posted on focus. please try
              again!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

export function* watchGetAcknowledgmentList() {
  yield takeEvery("acknowledgement/getAcknowledgmentList",AcknowledgmentListGet);
}
export function* watchGetStnList() {
  yield takeEvery("acknowledgement/getStnList", StnListGet);
}
export function* watchGetSTNDocData() {
  yield takeEvery("acknowledgement/getSTNDocData", STNDocDataGet);
}
export function* watchGetSTNDocItems() {
  yield takeEvery("acknowledgement/getSTNDocItems", STNDocItemsGet);
}
export function* watchGetAckDOcViewData() {
  yield takeEvery("acknowledgement/getAckDOcViewData", AckDOcViewDataGet);
}
export function* watchGetAckItemsById() {
  yield takeEvery("acknowledgement/getAckItemsById", AckItemsByIdGet);
}
export function* watchAddAck() {
  yield takeEvery("acknowledgement/addAck", AckAdd);
}
export function* watchACKItemsAdd() {
  yield takeEvery("acknowledgement/ACKItemsAdd", AddACKItems);
}
export function* watchACKDocAddMake() {
  yield takeEvery("acknowledgement/ACKDocAddMake", AddACKDocMake);
}
export function* watchGetStnRecievedList() {
  yield takeEvery("acknowledgement/getStnRecievedList", STNRecievedList);
}
export function* watchGetMrqApprovedList() {
  yield takeEvery("acknowledgement/getMrqApprovedList", MrqApprovedList);
}
export function* watchGetUserWarehouseAck() {
  yield takeEvery("acknowledgement/getUserWarehouseAck", UserWarehouseAckGet);
}
export function* watchFsyncStatusUpdate() {
  yield takeEvery("acknowledgement/fsyncStatusUpdate", UpdateFsyncStatus);
}
function* AcknowledgementSaga() {
  yield all([
    fork(watchGetAcknowledgmentList),
    fork(watchGetStnList),
    fork(watchGetSTNDocData),
    fork(watchGetSTNDocItems),
    fork(watchGetAckDOcViewData),
    fork(watchGetAckItemsById),
    fork(watchAddAck),
    fork(watchACKItemsAdd),
    fork(watchACKDocAddMake),
    fork(watchGetStnRecievedList),
    fork(watchGetMrqApprovedList),
    fork(watchGetUserWarehouseAck),
    fork(watchFsyncStatusUpdate),
  ]);
}

export default AcknowledgementSaga;
