import { takeEvery, fork, put, all, call, select } from "redux-saga/effects";
import moment from "moment";
// Login Redux States

import {
  apiError,
  getUsers,
  usersListSuccessful,
  isUserChangePwdModel,
  InputChangeValue,
  isPasswordModel,
  userDeptAdd,
  userByIdSuccessful,
  getBranchDeptById,
  branchDeptByIdSuccessful,
} from "./manageUserSlice";
import {
  getAllDeptByBranch,
  getAllWarehouseByDeptBranch,
} from "../../mini/miniSlice";

// AUTH related methods
import {
  postAdd,
  getList,
  getParamsList,
  postEdit,
  postDelete,
} from "../../../helpers/Helper";
import { Modal } from "antd";
import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";
const getManageUserState = (state) => state.manageUsers;

function* getUserList({ payload }) {
  var data = payload;
  var params = {};

  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "" && k !== null && k !== "no_of_pages") {
          if (k == "start_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k == "end_date") {
            params[k] = moment(data[k]).add(1, "days").format("YYYY-MM-DD");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/users/list/", params);
    yield put(usersListSuccessful(response));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* UserChangePassword({ payload }) {
  try {
    const response = yield call(postEdit, "/users/changepassword/", payload);
    if (response.status === 200) {
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Your Password Change Successfully.!
            </p>
          </div>
        ),
      });
      yield put(isUserChangePwdModel(false));
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Unable to Change Password. Please try again!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* UserUpdatePassword({ payload }) {
  try {
    const response = yield call(patchEdit, "/users/" + payload.id, payload);

    if (response.status === 200) {
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              User Password Updated Successfully.!
            </p>
          </div>
        ),
      });
      yield put(isPasswordModel(false));
      yield put(getUsers({ page: 1, search: "", page_size: 10 }));
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Unable to update User Password. Please try again!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    yield put(apiError(error));
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
  }
}
function* UserActive({ payload }) {
  try {
    const response = yield call(postAdd, "/users/useractive/" + payload, {});
    if (response.status == 200) {
      yield put(getUsers({ page: 1, search: "", page_size: 10 }));
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}
function* UserInActive({ payload }) {
  try {
    const response = yield call(postAdd, "/users/userinactive/" + payload, {});
    if (response.status == 200) {
      yield put(getUsers({ page: 1, search: "", page_size: 10 }));
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });

    yield put(apiError(error));
  }
}
function* checkUserName({ payload }) {
  try {
    yield call(postAdd, "/users/username/validation/", payload);
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}
function* checkUserEmail({ payload }) {
  try {
    yield call(postAdd, "/users/useremail/validation/", payload);
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

// User Add
function* AddUser({ payload }) {
  try {
    if (payload.userObj.id == undefined || payload.userObj.id == null) {
      const response = yield call(
        postAdd,
        "/users/list/",
        payload.userObj
        // {id:0,
        //   username:"fgfg",
        //   email:"testuser@gmail.com",
        //   phone:"7412589630",
        //   group_ids:[5],
        //   first_name:"test",
        //   last_name:"user",
        //   role:2
        // }
      );
      if (response.status === 201) {
        yield put(
          userDeptAdd({
            userObject: payload.userObj,
            objects: payload.userRoleDeptObj,
            history: payload.history,
            userId: response.data.id,
          })
        );
      }
      // console.log("payloadddddd", response);
    } else {
      const response = yield call(
        postEdit,
        "/users/" + payload.userObj.id,
        payload.userObj
        // {id:8,
        //   username:"",
        //   email:"testuser@gmail.com",
        //   phone:"7412589630",
        //   group_ids:[5],
        //   first_name:"test",
        //   last_name:"user",
        //   role:2
        // }
      );
      if (response.status === 200) {
        yield put(
          userDeptAdd({
            userObject: payload.userObj,
            objects: payload.userRoleDeptObj,
            history: payload.history,
            userId: response.data.id,
          })
        );
      }
    }
  } catch (error) {
    console.log("*****************************************", error);
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

// User Dept

function* AddUserDept({ payload }) {
  // console.log("response.id", payload);
  try {
    const response = yield call(
      postAdd,
      "/users/dept_branch/" + payload.userId,
      { objects: payload.objects }
    );
    if (response.status === 200) {
      if (payload.userObject.id == null || payload.userObject.id == undefined) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your User Added Successfully.!
              </p>
            </div>
          ),
        });
      } else {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your User Edited Successfully.!
              </p>
            </div>
          ),
        });
      }
      payload.history.push("/pages/settings/manage-users");
      // yield put(getUsers({ page: 1, search: "", page_size: 10 }));
    } else {
      if (payload.userObject.id == null || payload.userObject.id == undefined) {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to added User. Please try again!
              </p>
            </div>
          ),
        });
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to edit User. Please try again!
              </p>
            </div>
          ),
        });
      }
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* UserByIdGet({ payload }) {
  try {
    const response = yield call(getList, "/users/" + payload, {});
    yield put(userByIdSuccessful(response));
    yield put(getBranchDeptById(response.id));
    // yield put(getBranchDeptById({ id: response.id, page_size: 10, page: 1 }));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* BranchDeptByIdGet({ payload }) {
  try {
    const response = yield call(
      getList,
      "/users/dept_branch/user/" + payload,
      {}
    );

    // yield put(
    //   getAllDeptByBranch({
    //     params: {
    //       page: 1,
    //       search: "",
    //       page_size: 10,
    //     },
    //     branch_id: response[0].branch?.id,
    //   })
    // );
    // if (response[0].departments.length != 0) {
    //   yield put(
    //     getAllWarehouseByDeptBranch({
    //       params: {
    //         page: 1,
    //         search: "",
    //         page_size: 10,
    //       },
    //       branch_id: response[0].branch?.id,
    //       department_id: response[0].departments[0]?.id,
    //     })
    //   );
    // }
    yield put(branchDeptByIdSuccessful(response));
    // yield put(userByIdSuccessful(response));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* ChangeACKStatus({ payload }) {
  let userParams = yield select((state) => state.manageUser.usersParams)
  try {
    const response = yield call(postEdit, "/users/EnableAckReject/" + payload.id, payload);
    if (response.status === 200) {
      if(response.data.enable_ack_reject == true){
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                This Acknowledgement reject is enabled
              </p>
            </div>
          ),
        });
        
      }else{
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                The Acknowledgement reject is disabled
              </p>
            </div>
          ),
        });
      }
      yield put(getUsers(userParams));
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });

    yield put(apiError(error));
  }
}

export function* watchgetUsers() {
  yield takeEvery("manageUser/getUsers", getUserList);
}
export function* watchUserChangePassword() {
  yield takeEvery("manageUser/ChangePasswordSubmit", UserChangePassword);
}
export function* watchuserActive() {
  yield takeEvery("manageUser/userActive", UserActive);
}
export function* watchuserInActive() {
  yield takeEvery("manageUser/userInActive", UserInActive);
}
export function* watchuserUpdatePassword() {
  yield takeEvery("manageUser/updatePassword", UserUpdatePassword);
}
export function* watchUserNameValidation() {
  yield takeEvery("manageUser/userNameValidation", checkUserName);
}
export function* watchUserEmailValidation() {
  yield takeEvery("manageUser/userEmailValidation", checkUserEmail);
}

export function* watchUserAdd() {
  yield takeEvery("manageUser/userAdd", AddUser);
}
export function* watchUserDeptAdd() {
  yield takeEvery("manageUser/userDeptAdd", AddUserDept);
}
export function* watchGetUserById() {
  yield takeEvery("manageUser/getUserById", UserByIdGet);
}
export function* watchGetBranchDeptById() {
  yield takeEvery("manageUser/getBranchDeptById", BranchDeptByIdGet);
}
export function* watchAckStatusChange() {
  yield takeEvery("manageUser/ackStatusChange", ChangeACKStatus);
}
function* ManageUsersSaga() {
  yield all([
    fork(watchgetUsers),
    fork(watchUserChangePassword),
    fork(watchuserActive),
    fork(watchuserInActive),
    fork(watchuserUpdatePassword),
    fork(watchUserAdd),
    fork(watchUserDeptAdd),
    fork(watchUserNameValidation),
    fork(watchUserEmailValidation),
    fork(watchGetUserById),
    fork(watchGetBranchDeptById),
    fork(watchAckStatusChange),
  ]);
}

export default ManageUsersSaga;
