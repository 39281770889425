import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import moment from "moment";
import {
  apiError,
  mrqListSuccessful,
  mrqViewDataSuccessful,
  getMrqByIdItemsData,
  setMrqByIdItemsParams,
  MrqByIdItemsDataSuccessful,
  materialItemSuccessful,
  isMRQRejectModelVisible,
  getMrqList,
  MRQItemAdd,
  MRQDocAddMake,
  mrqApproval,
  userWarehouseMRQSuccessful,
  mrqClose,
} from "./MRQSlice";
import {
  getList,
  getParamsList,
  postAdd,
  postEdit,
  postFormData,
  postDelete,
} from "../../../helpers/Helper";
import { Modal } from "antd";
import { select } from "redux-saga/effects";
import { evaluate } from "mathjs";

import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";

//If user is login then dispatch redux action's are directly from here.

function* MrqListGet({ payload }) {
  var data = payload;
  var params = {};

  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] != "" && k != null && k != "no_of_pages") {
          if (k == "start_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k == "end_date") {
            params[k] = moment(data[k]).add(1, "days").format("YYYY-MM-DD");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/material_request_quotes/list/",
      params
    );
    yield put(mrqListSuccessful({ response }));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}
function* IbtInListGet({ payload }) {
  var data = payload;
  var params = {};

  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] != "" && k != null && k != "no_of_pages") {
          if (k == "start_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k == "end_date") {
            params[k] = moment(data[k]).add(1, "days").format("YYYY-MM-DD");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/material_request_quotes/ibt_requests_in/",
      params
    );
    yield put(mrqListSuccessful({ response }));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* MrqViewDataGet({ payload }) {
  try {
    const response = yield call(
      getList,
      "/material_request_quotes/" + payload,
      {}
    );

    yield put(
      setMrqByIdItemsParams({
        start_date: "",
        end_date: "",
        no_of_pages: 0,
        page_size: 10,
        page: 1,
        search: "",
      })
    );
    yield put(mrqViewDataSuccessful({ response }));
    yield put(
      getMrqByIdItemsData({
        id: response.id,
        type: response.type,
        stockpoint_id: response.stockpoint?.id,
        page_size: 10,
        page: 1,
      })
    );
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* MrqByIdItemsDataGet({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (
          data[k] !== "" &&
          k !== "id" &&
          k !== "no_of_pages" &&
          k !== "type" &&
          k !== "stockpoint_id"
        ) {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    var response;
    if (
      payload.type == null ||
      payload.type == undefined ||
      payload.type == 1
    ) {
      response = yield call(
        getParamsList,
        "material_request_quotes/mrqitems/" + payload.id,
        params
      );
    } else if (payload.type == 2) {
      response = yield call(
        getParamsList,
        "material_request_quotes/mrqitems/" +
        payload.id +
        "/" +
        payload.stockpoint_id,
        params
      );
    }
    yield put(MrqByIdItemsDataSuccessful({ response }));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

// MRQ Items List

function* MaterialItemsGet({ payload }) {
  var params = {};
  var data = payload.params;
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "type") {
        params.type = data.type.join(",");
      } else {
        if (data[k] !== "" && k !== "no_of_pages") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/masters/mrq/material/items/" + payload.URLData.warehouse_id,
      params
    );
    yield put(materialItemSuccessful(response));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* MrqAdd({ payload }) {
  try {
    if (
      payload.mrqObj.id == null ||
      payload.mrqObj.id == undefined ||
      payload.mrqObj.id == 0
    ) {
      delete payload.mrqObj.id;
      const response = yield call(
        postAdd,
        "/material_request_quotes/",
        payload.mrqObj
      );
      if (response.status === 200) {
        yield put(
          MRQItemAdd({
            type: payload.type,
            mrqApproveObj:
              payload.type == "MRQ_Approve" ? payload?.mrqApproveObj : {},
            mrqitems: payload.mrqitems,
            unique_id: response.data.unique_id,
            mrqObj: payload.mrqObj,
            history: payload.history,
          })
        );
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to added MRQ. Please try again!
              </p>
            </div>
          ),
        });
      }
    } else {
      const response = yield call(
        postAdd,
        "/material_request_quotes/",
        payload.mrqObj
      );
      if (response.status === 200) {
        yield put(
          MRQItemAdd({
            type: payload.type,
            mrqApproveObj:
              payload.type == "MRQ_Approve" ? payload?.mrqApproveObj : {},
            mrqitems: payload.mrqitems,
            unique_id: response.data.unique_id,
            mrqObj: payload.mrqObj,
            history: payload.history,
          })
        );
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to edit MRQ. Please try again!
              </p>
            </div>
          ),
        });
      }
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* AddMRQItem({ payload }) {
  var mrqDocItems = payload.mrqitems.map((item) => {
    if (item.id == null || item.id == undefined || item.id == 0) {
      return {
        material_item_id: item.material_item_id,
        uom_id: item.uom.id,
        priority: item.priority,
        qty: item.qty,
        type: item.type,
        // materialrequestquote_id: payload.mrqID,
        remarks: item.remarks,
        dodelete: item.dodelete,
      };
    } else {
      return {
        id: item.id,
        material_item_id: item.material_item_id,
        uom_id: item.uom.id,
        priority: item.priority,
        qty: item.qty,
        type: item.type,
        // materialrequestquote_id: payload.mrqID,
        remarks: item.remarks,
        dodelete: item.dodelete,
      };
    }
  });

  try {
    var response = yield call(postAdd, "/material_request_quotes/items/add/", {
      unique_id: payload.unique_id,
      mrqitems: mrqDocItems,
    });
    if (response.status === 200) {
      yield put(
        MRQDocAddMake({
          type: payload.type,
          mrqApproveObj:
            payload.type == "MRQ_Approve" ? payload?.mrqApproveObj : {},
          mrqObj: payload.mrqObj,
          unique_id: payload.unique_id,
          do_save: true,
          history: payload.history,
        })
      );
    } else {
      yield put(
        MRQDocAddMake({
          type: payload.type,
          mrqApproveObj:
            payload.type == "MRQ_Approve" ? payload?.mrqApproveObj : {},
          mrqObj: payload.mrqObj,
          unique_id: payload.unique_id,
          do_save: false,
        })
      );
    }
  } catch (error) {
    yield put(
      MRQDocAddMake({
        type: payload.type,
        mrqApproveObj:
          payload.type == "MRQ_Approve" ? payload?.mrqApproveObj : {},
        mrqObj: payload.mrqObj,
        unique_id: payload.unique_id,
        do_save: false,
      })
    );

    yield put(apiError(error));
  }
}

function* MakeMRQDocAdd({ payload }) {
  try {
    const response = yield call(
      postAdd,
      "/material_request_quotes/mark_saved/",
      {
        unique_id: payload.unique_id,
        do_save: payload.do_save,
      }
    );
    if (response.status === 200) {
      if (payload.do_save == true) {
        if (payload.type == "MRQ_Request") {
          if (payload.mrqObj.id == null || payload.mrqObj.id == undefined) {
            if (
              payload.mrqObj.type == null ||
              payload.mrqObj.type == undefined
            ) {
              Modal.success({
                icon: (
                  <span className="remix-icon">
                    <RiCheckboxCircleLine />
                  </span>
                ),
                title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
                content: (
                  <div>
                    <p className="hp-p1-body hp-text-color-black-80">
                      Your MRQ Added Successfully.!
                    </p>
                  </div>
                ),
              });
            } else if (payload.mrqObj.type == 2) {
              Modal.success({
                icon: (
                  <span className="remix-icon">
                    <RiCheckboxCircleLine />
                  </span>
                ),
                title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
                content: (
                  <div>
                    <p className="hp-p1-body hp-text-color-black-80">
                      Your IBT Added Successfully.!
                    </p>
                  </div>
                ),
              });
            }
          } else {
            if (
              payload.mrqObj.type == null ||
              payload.mrqObj.type == undefined
            ) {
              Modal.success({
                icon: (
                  <span className="remix-icon">
                    <RiCheckboxCircleLine />
                  </span>
                ),
                title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
                content: (
                  <div>
                    <p className="hp-p1-body hp-text-color-black-80">
                      Your MRQ Edited Successfully.!
                    </p>
                  </div>
                ),
              });
            } else if (payload.mrqObj.type == 2) {
              Modal.success({
                icon: (
                  <span className="remix-icon">
                    <RiCheckboxCircleLine />
                  </span>
                ),
                title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
                content: (
                  <div>
                    <p className="hp-p1-body hp-text-color-black-80">
                      Your IBT Edited Successfully.!
                    </p>
                  </div>
                ),
              });
            }
          }
          if (payload.mrqObj.type == null || payload.mrqObj.type == undefined) {
            payload.history.push("/pages/mrq");
          } else if (payload.mrqObj.type == 2) {
            payload.history.push("/pages/ibt");
          }
        } else {
          yield put(
            mrqApproval({
              mrqApproveObj: payload?.mrqApproveObj,
              history: payload.history,
            })
          );
        }
      } else if (payload.do_save == false) {
        if (payload.type == "MRQ_Request") {
          if (payload.mrqObj.id == null || payload.mrqObj.id == undefined) {
            if (
              payload.mrqObj.type == null ||
              payload.mrqObj.type == undefined
            ) {
              Modal.success({
                icon: (
                  <span className="remix-icon">
                    <RiCheckboxCircleLine />
                  </span>
                ),
                title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
                content: (
                  <div>
                    <p className="hp-p1-body hp-text-color-black-80">
                      Sorry! Unable to added MRQ. Please try again!
                    </p>
                  </div>
                ),
              });
            } else if (payload.mrqObj.type == 2) {
              Modal.success({
                icon: (
                  <span className="remix-icon">
                    <RiCheckboxCircleLine />
                  </span>
                ),
                title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
                content: (
                  <div>
                    <p className="hp-p1-body hp-text-color-black-80">
                      Sorry! Unable to added IBT. Please try again!
                    </p>
                  </div>
                ),
              });
            }
          } else {
            if (
              payload.mrqObj.type == null ||
              payload.mrqObj.type == undefined
            ) {
              Modal.success({
                icon: (
                  <span className="remix-icon">
                    <RiCheckboxCircleLine />
                  </span>
                ),
                title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
                content: (
                  <div>
                    <p className="hp-p1-body hp-text-color-black-80">
                      Sorry! Unable to edited MRQ. Please try again!
                    </p>
                  </div>
                ),
              });
            } else if (payload.mrqObj.type == 2) {
              Modal.success({
                icon: (
                  <span className="remix-icon">
                    <RiCheckboxCircleLine />
                  </span>
                ),
                title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
                content: (
                  <div>
                    <p className="hp-p1-body hp-text-color-black-80">
                      Sorry! Unable to edited IBT. Please try again!
                    </p>
                  </div>
                ),
              });
            }
          }
          if (payload.mrqObj.type == null || payload.mrqObj.type == undefined) {
            payload.history.push("/pages/mrq");
          } else if (payload.mrqObj.type == 2) {
            payload.history.push("/pages/ibt");
          }
        } else {
          Modal.success({
            icon: (
              <span className="remix-icon">
                <RiCheckboxCircleLine />
              </span>
            ),
            title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
            content: (
              <div>
                <p className="hp-p1-body hp-text-color-black-80">
                  Sorry! Unable to Approved MRQ. Please try again!
                </p>
              </div>
            ),
          });
          // payload.history.push("/pages/mrq-approve");
        }
      }
    } else {
      if (payload.mrqObj.type == null || payload.mrqObj.type == undefined) {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to Update MRQ. Please try again!
              </p>
            </div>
          ),
        });
      } else if (payload.mrqObj.type == 2) {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to Update IBT. Please try again!
              </p>
            </div>
          ),
        });
      }
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

// MRQ Delete

function* MrqDelete({ payload }) {
  try {
    const response = yield call(
      postDelete,
      "/material_request_quotes/" + payload
    );

    if (response.status === 204) {
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Your MRQ has been deleted!
            </p>
          </div>
        ),
      });

      yield put(getMrqList({ page: 1, search: "", page_size: 10 }));
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),

        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Your MRQ has been not deleted!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

// MRQ Close

function* MrqClose({ payload }) {
  try {
    const response = yield call(
      postAdd,
      "/material_request_quotes/MRQClose/" + payload.mrqId
    );

    if (response.status >= 200 && response.status < 300) {
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Your MRQ has been Closed!
            </p>
          </div>
        ),
      });

      yield put(getMrqList({ ...payload.params }));
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),

        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Facing server side issue while closing MRQ
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

// MRQ Approval

function* MRQApproval({ payload }) {
  try {
    const response = yield call(
      postAdd,
      "/material_request_quotes/mark_approve/",
      payload.mrqApproveObj
    );
    if (response.status === 200) {
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              {response.data.Message}
            </p>
          </div>
        ),
      });

      if (payload.mrqApproveObj.mrq_status === 3) {
        yield put(isMRQRejectModelVisible(false));
      }
      if (
        payload.mrqApproveObj.type == null ||
        payload.mrqApproveObj.type == undefined
      ) {
        payload.history.push("/pages/mrq-approve");
      } else if (payload.mrqApproveObj.type == 2) {
        payload.history.push("/pages/ibt-approve");
      }
    } else {
      if (payload.mrqApproveObj.mrq_status === 2) {
        if (
          payload.mrqApproveObj.type == null ||
          payload.mrqApproveObj.type == undefined
        ) {
          Modal.error({
            icon: (
              <span className="remix-icon">
                <RiAlertLine />
              </span>
            ),
            title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
            content: (
              <div>
                <p className="hp-p1-body hp-text-color-black-80">
                  Sorry! Unable to Approve MRQ. Please try again!
                </p>
              </div>
            ),
          });
        } else if (payload.mrqApproveObj.type == 2) {
          Modal.error({
            icon: (
              <span className="remix-icon">
                <RiAlertLine />
              </span>
            ),
            title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
            content: (
              <div>
                <p className="hp-p1-body hp-text-color-black-80">
                  Sorry! Unable to Approve IBT. Please try again!
                </p>
              </div>
            ),
          });
        }
      } else if (payload.mrqApproveObj.mrq_status === 3) {
        if (
          payload.mrqApproveObj.type == null ||
          payload.mrqApproveObj.type == undefined
        ) {
          Modal.error({
            icon: (
              <span className="remix-icon">
                <RiAlertLine />
              </span>
            ),
            title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
            content: (
              <div>
                <p className="hp-p1-body hp-text-color-black-80">
                  Sorry! Unable to Reject MRQ. Please try again!
                </p>
              </div>
            ),
          });
        } else if (payload.mrqApproveObj.type == 2) {
          Modal.error({
            icon: (
              <span className="remix-icon">
                <RiAlertLine />
              </span>
            ),
            title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
            content: (
              <div>
                <p className="hp-p1-body hp-text-color-black-80">
                  Sorry! Unable to Reject IBT. Please try again!
                </p>
              </div>
            ),
          });
        }
      }
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}
function* UserWarehouseMRQGet({ payload }) {
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/masters/user/warehouses/list/",
      params
    );
    yield put(userWarehouseMRQSuccessful(response));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* WarehouseStockItemGet({ payload }) {
  var params = {};
  var data = payload.params;
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "type") {
        params.type = data.type.join(",");
      } else {
        if (data[k] !== "" && k !== "no_of_pages") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/masters/warehouses/stock/list/" +
      payload.URLData.warehouse_id +
      "/" +
      payload.URLData.to_warehouse_id,
      params
    );
    yield put(materialItemSuccessful(response));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}



function* UpdateFsyncStatus({ payload }) {
  try {
    const response = yield call(
      postAdd,
      "/thirdparty/fsyncmaterialrequestquote/" + payload.id, { mrq_status: payload.mrq_status }
    );
    if (response.status === 200) {
      if (response.data.result == true) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Selected MRQ is successfully synchronised into focus
                {/* {response.data.msg} */}
              </p>
            </div>
          ),
        });

      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! selected MRQ is not posted on focus. please try
                again!
                {/* {response.data.msg} */}
              </p>
            </div>
          ),
        });
      }
      yield put(getMrqList({ page: 1, search: "", page_size: 10 }));
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! selected MRQ is not posted on focus. please try
              again!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

export function* watchGetMrqList() {
  yield takeEvery("materialRequest/getMrqList", MrqListGet);
}
export function* watchGetIBTInList() {
  yield takeEvery("materialRequest/getIBTInList", IbtInListGet);
}
export function* watchAddMrq() {
  yield takeEvery("materialRequest/addMrq", MrqAdd);
}
export function* watchMRQItemAdd() {
  yield takeEvery("materialRequest/MRQItemAdd", AddMRQItem);
}
export function* watchMRQDocAddMake() {
  yield takeEvery("materialRequest/MRQDocAddMake", MakeMRQDocAdd);
}
export function* watchMrqDelete() {
  yield takeEvery("materialRequest/mrqDelete", MrqDelete);
}
export function* watchMrqClose() {
  yield takeEvery("materialRequest/mrqClose", MrqClose);
}

export function* watchGetMrqViewData() {
  yield takeEvery("materialRequest/getMrqViewData", MrqViewDataGet);
}
export function* watchGetMrqByIdItemsData() {
  yield takeEvery("materialRequest/getMrqByIdItemsData", MrqByIdItemsDataGet);
}
export function* watchGetMaterialItem() {
  yield takeEvery("materialRequest/getMaterialItem", MaterialItemsGet);
}
export function* watchMrqApproval() {
  yield takeEvery("materialRequest/mrqApproval", MRQApproval);
}
export function* watchGetUserWarehouseMRQ() {
  yield takeEvery("materialRequest/getUserWarehouseMRQ", UserWarehouseMRQGet);
}
export function* watchGetWarehouseStockItem() {
  yield takeEvery(
    "materialRequest/getWarehouseStockItem",
    WarehouseStockItemGet
  );
}
export function* watchFsyncStatusUpdate() {
  yield takeEvery("materialRequest/fsyncStatusUpdate", UpdateFsyncStatus);
}
function* MaterialRequestSaga() {
  yield all([
    fork(watchGetMrqList),
    fork(watchGetIBTInList),
    fork(watchAddMrq),
    fork(watchMRQItemAdd),
    fork(watchMRQDocAddMake),
    fork(watchGetMrqViewData),
    fork(watchGetMrqByIdItemsData),
    fork(watchGetMaterialItem),
    fork(watchMrqApproval),
    fork(watchMrqDelete),
    fork(watchMrqClose),
    fork(watchGetUserWarehouseMRQ),
    fork(watchGetWarehouseStockItem),
    fork(watchFsyncStatusUpdate),
  ]);
}

export default MaterialRequestSaga;
