import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States

import {
  apiError,
  appSettingsAddSuccessful,
  appSettingsSuccessful,
  getAppSettings,
} from "./appsettingsSlice";

// AUTH related methods
import {
  postAdd,
  getList,
  getParamsList,
  postEdit,
  postDelete,
  postFormData,
  putImg,
} from "../../../helpers/Helper";
import { Modal } from "antd";
import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";

function* AppSettings() {
  try {
    const response = yield call(getList, "/system/dynamicsettings/");
    yield put(appSettingsSuccessful(response));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* AddAppSettings({ payload }) {
  console.log("AddAppSettings", payload);
  try {
    const response = yield call(postAdd, "/system/dynamicsettings/", payload);
    if (response.status === 200) {
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Your App Settings Updated Successfully.!
            </p>
          </div>
        ),
      });
      yield put(getAppSettings());
      // yield put(isModelVisible(false));
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Unable to Updated App Settings. Please try again!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

export function* watchGetAppSettings() {
  yield takeEvery("appSettings/getAppSettings", AppSettings);
}

export function* watchAppSettingsAdd() {
  yield takeEvery("appSettings/appSettingsAdd", AddAppSettings);
}

function* AppSettingsSaga() {
  yield all([fork(watchAppSettingsAdd), fork(watchGetAppSettings)]);
}

export default AppSettingsSaga;
