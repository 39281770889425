import { createSlice } from "@reduxjs/toolkit";

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    MRQCountData: [],
    STNCountData: [],
    ACKCountData: [],
    StockCountData: [],
    Warehouse_id: 0,
    StockDataList: [],
    StockDataListParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    UserWarehousesList: [],
    UserWarehousesListParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    //
  },
  reducers: {
    clearData: (state, action) => {
      return {
        ...state,
        loading: false,
        MRQCountData: [],
        STNCountData: [],
        ACKCountData: [],
        StockCountData: [],
        Warehouse_id: 0,
        StockDataList: [],
        StockDataListParams: {
          no_of_pages: 0,
          page_size: 10,
          page: 1,
          search: "",
        },
        UserWarehousesList: [],
        UserWarehousesListParams: {
          no_of_pages: 0,
          page_size: 10,
          page: 1,
          search: "",
        },
      };
    },
    getMRQCounts: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    MRQCountListSuccessful: (state, action) => {
      var value = [];

      var data = action.payload;
      // Object.keys(data).forEach((key) => {
      //   if (key != "total_mrq") {
      //     value.push({ title: key, total: data[key] });
      //   }
      // });
      value = [
        { title: "Closed", total: data.closed_mrq, color: "#EAAD98" },
        { title: "Rejected", total: data.rejected_mrq, color: "#F18997" },
        { title: "Pending", total: data.pending_mrq, color: "#3B9CE3" },
        { title: "Approved", total: data.approved_mrq, color: "#75C8AC" },
      ];
      return {
        ...state,
        MRQCountData: value,
        loading: false,
      };
    },
    getSTNCounts: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    STNCountListSuccessful: (state, action) => {
      var value = [];
      var data = action.payload;
      // Object.keys(data).forEach((key) => {
      //   if (key != "total_stn") {
      //     value.push({ title: key, total: data[key] });
      //   }
      // });
      value = [
        { title: "To Be ACK", total: data.total_unack, color: "#75C8AC" },
        { title: "Total ACK", total: data.total_ack, color: "#8D5CBF" },
      ];
      return {
        ...state,
        STNCountData: value,
        loading: false,
      };
    },
    getACKCounts: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    ACKCountListSuccessful: (state, action) => {
      var value = [];
      var data = action.payload;
      // Object.keys(data).forEach((key) => {
      //   if (key != "total_ack") {
      //     value.push({ title: key, total: data[key] });
      //   }
      // });
      value = [
        { title: "Fulfilled MRQ", total: data.fulfilled_mrq, color: "#75C8AC" },
        { title: "Partial MRQ", total: data.partial_mrq, color: "#CD7DD9" },
        { title: "Pending MRQ", total: data.pending_mrq, color: "#3B9CE3" },
      ];
      return {
        ...state,
        ACKCountData: value,
        loading: false,
      };
    },
    getStockCounts: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    StockCountListSuccessful: (state, action) => {
      var value = [];
      var data = action.payload;
      Object.keys(data).forEach((key) => {
        value.push({ title: key, total: data[key] });
        // value.splice(1, 0, { title: key, total: data[key] });
      });
      console.log("StockCountListSuccessful", value);
      // value = [
      //   { title: "Fulfilled MRQ", total: data.fulfilled_mrq, color: "#75C8AC" },
      //   { title: "Partial MRQ", total: data.partial_mrq, color: "#CD7DD9" },
      //   { title: "Pending MRQ", total: data.pending_mrq, color: "#3B9CE3" },
      // ];
      return {
        ...state,
        StockCountData: value,
        loading: false,
      };
    },
    getUserWarehousesList: (state, action) => {
      if (state.UserWarehousesListParams?.page == 1) {
        return {
          ...state,
          loading: true,
          UserWarehousesList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    UserWarehousesListSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.UserWarehousesListParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.UserWarehousesList, ...response.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.UserWarehousesListParams?.page_size
      );
      return {
        ...state,
        UserWarehousesList: list,
        loading: false,
        UserWarehousesListParams: {
          ...state.UserWarehousesListParams,
          no_of_pages: noofpages,
        },
      };
    },
    setUserWarehousesParams: (state, action) => {
      return {
        ...state,
        UserWarehousesListParams: action.payload,
      };
    },
    setWarehouseId: (state, action) => {
      return {
        ...state,
        Warehouse_id: action.payload,
      };
    },
    getStockValues: (state, action) => {
      if (state.StockDataListParams?.page == 1) {
        return {
          ...state,
          loading: true,
          StockDataList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    StockValueSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.StockDataListParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.StockDataList, ...response.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.StockDataListParams?.page_size
      );
      return {
        ...state,
        StockDataList: list,
        loading: false,
        StockDataListParams: {
          ...state.StockDataListParams,
          no_of_pages: noofpages,
        },
      };
    },
    setStockValueParams: (state, action) => {
      return {
        ...state,
        StockDataListParams: action.payload,
      };
    },

    loadingStatus: (state, action) => {
      return { ...state, loading: action.payload };
    },
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  clearData,
  getMRQCounts,
  MRQCountListSuccessful,
  getSTNCounts,
  STNCountListSuccessful,
  getACKCounts,
  ACKCountListSuccessful,
  getStockCounts,
  StockCountListSuccessful,
  getUserWarehousesList,
  UserWarehousesListSuccessful,
  setUserWarehousesParams,
  setWarehouseId,
  getStockValues,
  StockValueSuccessful,
  setStockValueParams,
  apiError,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
