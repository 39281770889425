import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import noImage from "../../../assets/images/noimg.png";
export const utilityBillingSlice = createSlice({
  name: "utility",
  initialState: {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    imageLooading: false,
    model: false,
    drawer: false,
    error_msg: null,
    listCount: 0,
    UtilityBillingList: [],
    utilityBillingParams: {
      start_date: "",
      end_date: "",
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
    rowdata: {},
    utilityViewData: {},
    viewModel: false,
  },
  reducers: {
    clearData: (state, action) => {
      return{
        ...state,
        loading: true,
        rowdata: {},
        utilityViewData: {},
        UtilityBillingList: [],
        utilityBillingParams: {
          start_date: "",
          end_date: "",
          no_of_pages: 0,
          page_size: 10,
          page: 1,
          search: "",
          currentSort: "code",
          sortOrder: "-",
        },
      }
    },
    getUtilityBillingList: (state, action) => {
      if (state.utilityBillingParams?.page == 1) {
        return {
          ...state,
          loading: true,
          UtilityBillingList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    utilityBillingListSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.utilityBillingParams?.page == 1) {
        list = response.results;
      } else {
        list = response.results;
      }
      var noofpages = Math.ceil(
        action.payload.count / state.utilityBillingParams?.page_size
      );
      return {
        ...state,
        UtilityBillingList: list,
        listCount: action.payload.count,
        loading: false,
        utilityBillingParams: {
          ...state.utilityBillingParams,
          no_of_pages: noofpages,
        },
      };
    },
    SetPettyCashParams: (state, action) => {
      return {
        ...state,
        utilityBillingParams: action.payload,
      };
    },

    InputChangeValue: (state, action) => {
      switch (action.payload.key) {
        case "reading_to_date":
          return {
            ...state,
            rowdata: {
              ...state.rowdata,
              [action.payload.key]: action.payload.value,
              no_of_days:
                action.payload.value == ""
                  ? null
                  : action.payload.value.diff(
                      state.rowdata.reading_from_date,
                      "days"
                    ) + 1,
            },
          };

        case "reading_from_units":
          return {
            ...state,
            rowdata: {
              ...state.rowdata,
              [action.payload.key]: action.payload.value,
              total_units:
                action.payload.value == ""
                  ? 0
                  : Math.abs(
                      state.rowdata.reading_to_units - action.payload.value
                    ),
            },
          };

        case "reading_to_units":
          return {
            ...state,
            rowdata: {
              ...state.rowdata,
              [action.payload.key]: action.payload.value,
              total_units:
                action.payload.value == ""
                  ? 0
                  : Math.abs(
                      action.payload.value - state.rowdata.reading_from_units
                    ),
            },
          };
        // case "amount":
        //   return {
        //     ...state,
        //     rowdata: {
        //       ...state.rowdata,
        //       amount: parseFloat(action.payload.value).toFixed(2), // Ensure two decimal places
        //     },
        //   };

        default:
          return {
            ...state,
            rowdata: {
              ...state.rowdata,
              [action.payload.key]: action.payload.value,
            },
          };
      }
      // if (action.payload.key == "reading_to_date") {
      //   return {
      //     ...state,
      //     rowdata: {
      //       ...state.rowdata,
      //       [action.payload.key]: action.payload.value,
      //       no_of_days:
      //         action.payload.value == ""
      //           ? null
      //           : action.payload.value.diff(
      //               state.rowdata.reading_from_date,
      //               "days"
      //             ) + 1,
      //     },
      //   };

      // } else if(action.payload.key){
      //   return {
      //     ...state,
      //     rowdata: {
      //       ...state.rowdata,
      //       [action.payload.key]: action.payload.value,
      //       total_units: action.payload.value == ""
      //       ? null
      //       : action.payload.value.Math.abs(parseInt(state.rowdata.reading_from_units) - parseInt(state.rowdata.reading_to_units))
      //     },
      //   };
      // }
      // else {
      //   return {
      //     ...state,
      //     rowdata: {
      //       ...state.rowdata,
      //       [action.payload.key]: action.payload.value,
      //     },
      //   };
      // }
    },
    isModelVisible: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
          utilityViewData: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
          utilityViewData: {},
        };
      }
    },
    bannerImgUpload: (state, action) => {
      return {
        ...state,
        imageLooading: false,
        rowdata: {
          ...state.rowdata,
          [action.payload.imageType]: action.payload.file,
        },
      };
    },
    setPreviewImg: (state, action) => {
      return {
        ...state,
        loading: false,
        rowdata: {
          ...state.rowdata,
          previewFile: action.payload,
        },
      };
    },
    utilityBillingAdd: (state) => {
      return {
        ...state,
        loading: true,
      };
    },

    getUtilityBillingByID: (state) => {
      return {
        ...state,
        loading: true,
        rowdata: {},
      };
    },
    utilityBillingByIDSuccessful: (state, action) => {
      var arr = action.payload?.response?.attachment?.split(".");
      var previewImg = "";
      if (arr?.length > 0) {
        var ext = arr[arr.length - 1].toLowerCase();
        console.log("ext", ext, arr);
        if (ext == "jpeg" || ext == "jpg" || ext == "png") {
          previewImg = action.payload.response.attachment;
        } else if (ext == "pdf") {
          previewImg =
            "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAAA3NCSVQICAjb4U/gAAAACXBIWXMAAAG7AAABuwE67OPiAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAASlQTFRF////4uLr8lVE4uXmwcbL8VZC4ubn4uXnsLe9tLvAtbzBwcfMytHYz9Xb0c7S1tvf3ODj3eDj4uXn5oh95sPA7nRl8VZC8VdD8VlF8VpG8VtH8l1J8l1K8l9M8mBN8mFO8mFP8mJP8mNQ8mVT8mZU8mdV82xa83Fg9HZm9Hdn9Hts9H5u9IBx9YJ09YR19YV39YZ39Yh69Yt99Yx+9pWI956T96CU96CV+Kec+K2j+K6l+K+l+LCn+bGo+biw+buz+r62+sK7+sO8+sW/+sa/+8/J+8/K+9HM/NbR/NfS/NnU/NrV/NvW/N7a/N/b/ODc/eTh/ebj/ebk/ejl/ejm/ero/ezq/vTy/vX0/vb1/vf2/vj4//r5//r6//v7//z8//39//7+/////YM9wgAAAAh0Uk5TABo8psDJ5upYXiAEAAABv0lEQVRYw+XX2VLCMBQGYLBaUEA0aBC3qqBIVcR9wX1XEBRBUfbz/g9h6AIotklarvC/6KSZyTfJmTSdOBydcQoiMsmggxKnC5nGTRMERAFogkgFKAKiA+YCC2AqMAFmAhtgIjACxgIrYCgwA0YCO2Ag0IARmkADPG6KQAPGhykCogoe81Ughvi97VgCOgVrQIdgEWgLVoGWYBnQBeuAJtgAVMEOoAi2gKZgDyCCTQD57QKod8DAEObI5EQXwDWeCF0A5kxfA2FZlmMzSnNakqSQ1h2cJy/SLB1YqQJJ43IJ40SZtGrZs0XSPfUCSv8xFUhAOZvNA9xhfAJfhcIHwPsqxhFlPFT2GYAseW7UIUaAc9KMpiATJECFsQYqgO9hVwNwpAE7/MArbOsAfoAkAWoHJGssQE6W4zdQWW4BSbjSa1APMwBqufdwC3iGIwI0rkkOWWZQSqcfL5pz1YAowBZ/DbAOhNZzkMIcwCZk2kC1WCT76i3KAyzkk3oz/kmKUXo6nWvu5PTtP/qcrQKch2rA5rEeGDX8sXjZ4rMJ+MZ6/2vrI0DkGy9yX31/ReC+fP+My/nH9V1gXoUoqOO/ATvkEwaxqvgyAAAAAElFTkSuQmCC";
        }
      }
      return {
        ...state,
        loading: false,
        rowdata: {
          // id: action.payload.response.id,
          amount: action.payload.response.amount,
          bill_no: action.payload.response.bill_no,
          biometric_id: action.payload.response.biometric_id,
          biometric_name: action.payload.response.biometric_name,
          branch_id: action.payload.response.branch?.id,
          item_id: action.payload.response.item?.id,
          vendor_id: action.payload.response.vendor?.id,
          remarks: action.payload.response.remarks,
          item_number_id: action.payload.response.item_number?.id,
          no_of_days: action.payload.response.no_of_days,
          reading_from_units: action.payload.response.reading_from_units,
          reading_to_units: action.payload.response.reading_to_units,
          total_units: action.payload.response.total_units,
          attachment: action.payload.response.attachment,
          reading_from_date: moment(action.payload.response.reading_from_date),
          reading_to_date: moment(action.payload.response.reading_to_date),
          previewImg: previewImg,
          date:
            action.payload.response.date == null
              ? null
              : moment(action.payload.response.date),
          bill_date:
            action.payload.response.bill_date == null
              ? null
              : moment(action.payload.response.bill_date),
        },

        // model: action.payload.type == "update" ? true : false,
        // viewModel: true,
        utilityViewData: action.payload.response,
      };
    },

    utilityBillingDelete: (state) => {
      return {
        ...state,
        loading: true,
      };
    },

    isViewModelVisible: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          viewModel: action.payload,
          utilityViewData: {},
        };
      } else {
        return {
          ...state,
          viewModel: action.payload,
        };
      }
    },
    isDrawerVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          drawer: action.payload,
          utilityViewData: {},
        };
      } else {
        return {
          ...state,
          drawer: action.payload,
        };
      }
    },
    FilterInputChangeValue: (state, action) => {
      return {
        ...state,
        utilityBillingParams: {
          ...state.utilityBillingParams,
          [action.payload.key]: action.payload.value,
        },
      };
    },

    getBranchData: (state, action) => {
      console.log("getBranchData", action.payload)
      return {
        ...state,
        loading: false,
        rowdata: {
          ...state.rowdata,
          branch_id: action.payload.branch?.id,
        }
      }
    },
    fsyncStatusUpdate: (state, action) => {
      return {
        ...state,
        loading: true,
      }
    },
    apiError: (state, action) => {
      return {
        ...state,
        loading: false,
        error_msg: action.payload,
      };
    },
  },
});
export const {
  getUtilityBillingList,
  utilityBillingListSuccessful,
  SetPettyCashParams,
  bannerImgUpload,
  setPreviewImg,
  utilityBillingAdd,
  getUtilityBillingByID,
  utilityBillingByIDSuccessful,
  utilityBillingDelete,
  InputChangeValue,
  isModelVisible,
  isDrawerVisible,
  FilterInputChangeValue,
  isViewModelVisible,
  clearData,
  getBranchData,
  fsyncStatusUpdate,
  apiError,
} = utilityBillingSlice.actions;

export default utilityBillingSlice.reducer;
