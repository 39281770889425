import React, { Suspense } from "react";

import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom";

import { Provider } from "react-redux";
import store from "./store/index";

import "./assets/icons/remixicon.css";
import "./assets/less/yoda-theme.less";

import App from "./App";
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Suspense fallback="loading">
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </Suspense>
);
