import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

export const MRQSlice = createSlice({
  name: "materialRequest",
  initialState: {
    loginError: "aaa",
    message: null,
    loading: false,
    apiError: {},
    listCount: 0,
    drawer: false,
    filterStatus: false,
    mrqList: [],
    mrqData: {
      due_date: moment().add(4, "days"),
      // warehouse_id: 16,
    },

    // mrqViewData: {},
    mrqByIdItemsData: [],
    mrqByIdItemsPrintData: [],
    mrqItemslistCount: 0,
    materialItemList: [],
    selectedMrqItems: [],
    userWarehouseMRQList: [],

    mrqRejectData: {},
    mrqDocId: 0,
    itemAddModel: false,
    mrqRejectModel: false,
    itemsListModel: false,

    MRQScreenType: null,

    mrqParams: {
      start_date: "",
      end_date: "",
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    mrqByIdItemsDataParams: {
      start_date: "",
      end_date: "",
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    materialItemParams: {
      start_date: "",
      end_date: "",
      no_of_pages: 0,
      page_size: 20,
      page: 1,
      search: "",
    },
    userWarehouseMRQParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
  },
  reducers: {
    getMrqList: (state, action) => {
      if (state.mrqParams?.page == 1) {
        return {
          ...state,
          loading: true,
          mrqList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    mrqListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count / state.mrqParams.page_size
      );
      return {
        ...state,
        listCount: action.payload.response.count,
        // mrqList: [...state.mrqList, ...action.payload.response.results],
        loading: false,
        mrqList: action.payload.response.results,
        mrqParams: {
          ...state.mrqParams,
          no_of_pages: noofpages,
        },
      };
    },
    setMrqParams: (state, action) => {
      return {
        ...state,
        mrqParams: action.payload,
      };
    },

    // MRQ Filters
    FilterInputChangeValue: (state, action) => {
      switch (action.payload.key) {
        case "branch":
          return {
            ...state,
            mrqParams: {
              ...state.mrqParams,
              [action.payload.key]: action.payload.value,
              department: null,
            },
          };
          break;

        default:
          return {
            ...state,
            mrqParams: {
              ...state.mrqParams,
              [action.payload.key]: action.payload.value,
            },
          };
          break;
      }
    },

    InputChangeValue: (state, action) => {
      switch (action.payload.key) {
        case "branch_id":
          return {
            ...state,
            mrqData: {
              ...state.mrqData,
              [action.payload.key]: action.payload.value,
              department_id: null,
            },
          };
          break;
        case "rq_type":
          return {
            ...state,
            mrqData: {
              ...state.mrqData,
              [action.payload.key]: action.payload.value,
              to_warehouse: null,
              stockpoint: null,
            },
          };
          break;
        default:
          return {
            ...state,
            mrqData: {
              ...state.mrqData,
              [action.payload.key]: action.payload.value,
            },
          };
          break;
      }
    },
    SelectChangeValue: (state, action) => {
      if (action.payload.name == "SelectChangeValue") {
        return {
          ...state,
          mrqData: {
            ...state.mrqData,
            [action.payload.key]: {
              id: action.payload.value,
              name: action.payload.name,
            },
            department_id: null,
            to_warehouse_id: {},
          },
        };
      } else {
        return {
          ...state,
          mrqData: {
            ...state.mrqData,
            [action.payload.key]: {
              id: action.payload.value,
              name: action.payload.name,
            },
            department_id: null,
          },
        };
      }
    },
    // Add Mrq

    getDeptBranchData: (state, action) => {
      return {
        ...state,
        loading: false,
        mrqData: {
          ...state.mrqData,
          branch: action.payload.branch,
          department: action.payload.department,
          stockpoint_id: action.payload.stockpoint?.id,
          stockpoint: action.payload.stockpoint,
        },
      };
    },
    addMrq: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },

    MRQItemAdd: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    MRQDocAddMake: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },

    getMrqViewData: (state, action) => {
      return {
        ...state,
        mrqByIdItemsDataParams: {
          start_date: "",
          end_date: "",
          no_of_pages: 0,
          page_size: 10,
          page: 1,
          search: "",
        },
        loading: true,
        mrqData: {},
        mrqByIdItemsData: [],
        mrqByIdItemsPrintData: [],
        selectedMrqItems: [],
      };
    },
    mrqViewDataSuccessful: (state, action) => {
      return {
        ...state,
        // mrqViewData: action.payload.response,
        // mrqData: action.payload.response,
        mrqData: {
          ...action.payload.response,
          id: state.MRQScreenType == "copy" ? 0 : action.payload.response.id,
          date:
            state.MRQScreenType == "copy"
              ? moment(new Date())
              : action.payload.response.date == null
                ? ""
                : moment(action.payload.response.date),
          due_date:
            state.MRQScreenType == "copy"
              ? moment(new Date()).add(4, "days")
              : action.payload.response.due_date == null
                ? ""
                : moment(action.payload.response.due_date),
          biometric_id:
            state.MRQScreenType == "copy"
              ? ""
              : action.payload.response.biometric_id,
          biometric_name:
            state.MRQScreenType == "copy"
              ? ""
              : action.payload.response.biometric_name,
          branch_id: action.payload.response.branch?.id,
          department_id: action.payload.response.department?.id,
          stockpoint_id: action.payload.response.stockpoint?.id,
          to_warehouse: action.payload.response.stockpoint,
          warehouse_id: action.payload.response.warehouse?.id,
          material_type_id: action.payload.response.material_type?.id,
        },
        loading: false,
      };
    },
    getMrqByIdItemsData: (state, action) => {
      if (state.mrqByIdItemsDataParams?.page == 1) {
        return {
          ...state,
          loading: true,
          mrqDocId: action.payload.id,
          mrqByIdItemsData: [],
          mrqByIdItemsPrintData: [],
          selectedMrqItems: [],
        };
      } else {
        return {
          ...state,
          loading: true,
          mrqDocId: action.payload.id,
        };
      }
    },
    MrqByIdItemsDataSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count / state.mrqByIdItemsDataParams.page_size
      );
      var selectItems = action.payload.response?.results?.map((item) => {
        item.id = state.MRQScreenType == "copy" ? 0 : item.id;
        item.material_item_id = item.material_item.id;
        item.unack_qty = item.unack_qty;
        item.open_mrq_qty =
          state.MRQScreenType == "copy"
            ? item.open_mrq_qty
            : parseFloat(item.open_mrq_qty) - parseFloat(item.qty);
        item.fromwarehouse_stock_qty =
          item.fromwarehouse_stock_qty == null
            ? 0
            : item.fromwarehouse_stock_qty;
        item.ibtActual_qty =
          parseFloat(item.max_stock_qty) -
          (parseFloat(item.open_mrq_qty) +
            parseFloat(item.warehouse_stock_qty) +
            parseFloat(item.unack_qty));
        item.actual_qty =
          parseFloat(item.max_stock_qty) == 0
            ? 0
            : parseFloat(item.max_stock_qty) -
            (parseFloat(item.open_mrq_qty) +
              parseFloat(item.warehouse_stock_qty) +
              parseFloat(item.unack_qty));
        item.qty = state.MRQScreenType == "copy" ? "" : item.qty;
        item.dodelete = false;
        return item;
      });

      return {
        ...state,
        mrqItemslistCount: action.payload.response.count,
        mrqByIdItemsData:
          state.MRQScreenType == "view"
            ? action.payload.response.results
            : [...state.mrqByIdItemsData, ...action.payload.response.results],
        mrqByIdItemsPrintData:
          state.MRQScreenType == "print"
            ? [...state.mrqByIdItemsData, ...action.payload.response.results]
            : [],
        selectedMrqItems: [...state.selectedMrqItems, ...selectItems],
        //  itemsListModel: true,
        loading: false,
        mrqByIdItemsDataParams: {
          ...state.mrqByIdItemsDataParams,
          no_of_pages: noofpages,
        },
      };
    },
    setMrqByIdItemsParams: (state, action) => {
      return {
        ...state,
        mrqByIdItemsDataParams: action.payload,
      };
    },

    // Mrq Items List
    getMaterialItem: (state, action) => {
      if (state.materialItemParams?.page == 1) {
        return {
          ...state,
          loading: true,
          materialItemList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    materialItemSuccessful: (state, action) => {
      var response = action.payload;
      var mrqItemsList = [];
      var selectedMRQItemsList = state.selectedMrqItems.filter(
        (item) => !item.dodelete
      );
      var selectedMrqItems_ids = selectedMRQItemsList.map((i) => {
        return i.material_item_id;
      });
      mrqItemsList = response.results.filter((item) => {
        return selectedMrqItems_ids.indexOf(item.id) == -1;
      });
      var list = [];
      if (state.materialItemParams?.page == 1) {
        list = mrqItemsList;
      } else {
        list = [...state.materialItemList, ...mrqItemsList];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.materialItemParams?.page_size
      );
      return {
        ...state,
        materialItemList: list,
        loading: false,
        itemAddModel: true,
        materialItemParams: {
          ...state.materialItemParams,
          no_of_pages: noofpages,
        },
      };
    },
    setMaterialItemParams: (state, action) => {
      return {
        ...state,
        materialItemParams: action.payload,
      };
    },

    // Add Material Items

    addMaterialItem: (state, action) => {
      return {
        ...state,
        loading: false,
        selectedMrqItems: [
          ...state.selectedMrqItems,
          {
            max_stock_qty: action.payload.max_stock_qty,
            open_mrq_qty: action.payload.open_mrq_qty,
            warehouse_stock_qty: action.payload.warehouse_stock_qty,
            unack_qty: action.payload.unack_qty,
            fromwarehouse_stock_qty:
              action.payload.fromwarehouse_stock_qty == null
                ? 0
                : action.payload?.fromwarehouse_stock_qty,
            ibtActual_qty:
              parseFloat(action.payload.max_stock_qty) -
              parseFloat(action.payload.open_mrq_qty) -
              parseFloat(action.payload.warehouse_stock_qty) -
              parseFloat(action.payload.unack_qty),
            actual_qty:
              parseFloat(action.payload.max_stock_qty) == 0
                ? "-"
                : parseFloat(action.payload.max_stock_qty) -
                parseFloat(action.payload.open_mrq_qty) -
                parseFloat(action.payload.warehouse_stock_qty) -
                parseFloat(action.payload.unack_qty),
            uom: action.payload.uom,
            material_item_id: action.payload.id,
            material_item: { ...action.payload },
            uom_id: action.payload.uom?.id,
            priority: 1,
            qty: "",
            remarks: "",
            dodelete: false,
          },
        ],
        itemAddModel: false,
        materialItemParams: {
          start_date: "",
          end_date: "",
          no_of_pages: 0,
          page_size: 20,
          page: 1,
          search: "",
        },
      };
    },

    isItemAddModelVisible: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          itemAddModel: action.payload,
        };
      } else {
        return {
          ...state,
          itemAddModel: action.payload,
          materialItemList: [],
          materialItemParams: {
            start_date: "",
            end_date: "",
            no_of_pages: 0,
            page_size: 20,
            page: 1,
            search: "",
          },
        };
      }
    },
    removeCardItem: (state, action) => {
      var selectedMrqItems2 = [];
      if (
        action.payload.id == 0 ||
        action.payload.id == null ||
        action.payload.id == undefined
      ) {
        selectedMrqItems2 = state.selectedMrqItems.filter(
          (i) => i.material_item_id != action.payload.material_item_id
        );
      } else {
        selectedMrqItems2 = state.selectedMrqItems.map((item) => {
          if (item.id == action.payload.id) {
            return { ...item, dodelete: true };
          }
          return item;
        });
      }

      return {
        ...state,
        selectedMrqItems: selectedMrqItems2,
      };
    },
    SItemInputChangeValue: (state, action) => {
      var selectedItem = state.selectedMrqItems.map((item) => {
        if (item.material_item_id == action.payload.item.material_item_id) {
          // item[action.payload.key]= action.payload.value;
          return {
            ...item,
            [action.payload.key]: action.payload.value,
          };
        }
        return item;
      });
      return {
        ...state,
        selectedMrqItems: selectedItem,
      };
    },

    mrqApproval: (state) => {
      return {
        ...state,
        loading: true,
      };
    },

    isMRQRejectModelVisible: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          mrqRejectModel: action.payload,
        };
      } else {
        return {
          ...state,
          mrqRejectModel: action.payload,
        };
      }
    },

    isItemsListModelVisible: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          itemsListModel: action.payload,
        };
      } else {
        return {
          ...state,
          itemsListModel: action.payload,
          mrqByIdItemsData: [],
        };
      }
    },

    mrqClearData: (state, action) => {
      return {
        ...state,
        loading: false,
        mrqData: { due_date: moment().add(4, "days") },
        selectedMrqItems: [],
        mrqByIdItemsData: [],
        mrqByIdItemsPrintData: [],
        filterStatus: false,
        mrqParams: {
          start_date: "",
          end_date: "",
          no_of_pages: 0,
          page_size: 10,
          page: 1,
          search: "",
        },
      };
    },

    // MRQ Delete
    mrqDelete: (state) => {
      return {
        ...state,
        loading: true,
      };
    },

    rejectModelInputChangeValue: (state, action) => {
      return {
        ...state,
        mrqData: {
          ...state.mrqData,
          [action.payload.key]: action.payload.value,
        },
      };
    },

    getUserWarehouseMRQ: (state) => {
      if (state.userWarehouseMRQParams?.page == 1) {
        return {
          ...state,
          loading: true,
          userWarehouseMRQList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    userWarehouseMRQSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.userWarehouseMRQParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.userWarehouseMRQList, ...response.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.userWarehouseMRQParams?.page_size
      );
      if (list.length == 1) {
        return {
          ...state,
          userWarehouseMRQList: list,
          mrqData: {
            ...state.mrqData,
            branch: list[0].branch,
            department: list[0].department,
            stockpoint: list[0].stockpoint,
            warehouse: { id: list[0].id, name: list[0].name },
          },
          loading: false,
        };
      }
      return {
        ...state,
        userWarehouseMRQList: list,
        loading: false,
        userWarehouseMRQParams: {
          ...state.userWarehouseMRQParams,
          no_of_pages: noofpages,
        },
      };
    },
    setUserWarehouseMRQParams: (state, action) => {
      return {
        ...state,
        userWarehouseMRQParams: action.payload,
      };
    },

    isDrawerVisible: (state, action) => {
      return {
        ...state,
        drawer: action.payload,
      };
    },
    checkFilterStatus: (state, action) => {
      return {
        ...state,
        filterStatus: action.payload,
      };
    },
    setMRQScreenType: (state, action) => {
      return {
        ...state,
        MRQScreenType: action.payload,
      };
    },
    clearMRQ: (state, action) => {
      return {
        ...state,
        loading: false,
        mrqByIdItemsData: [],
        mrqByIdItemsDataParams: {
          start_date: "",
          end_date: "",
          no_of_pages: 0,
          page_size: 10,
          page: 1,
          search: "",
        },
      };
    },
    //
    // Mrq Items List
    getWarehouseStockItem: (state, action) => {
      if (state.materialItemParams?.page == 1) {
        return {
          ...state,
          loading: true,
          materialItemList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    warehouseStockItemSuccessful: (state, action) => {
      var response = action.payload;
      var mrqItemsList = [];
      var selectedMRQItemsList = state.selectedMrqItems.filter(
        (item) => !item.dodelete
      );
      var selectedMrqItems_ids = selectedMRQItemsList.map((i) => {
        return i.material_item_id;
      });
      mrqItemsList = response.results.filter((item) => {
        return selectedMrqItems_ids.indexOf(item.id) == -1;
      });
      var list = [];
      if (state.materialItemParams?.page == 1) {
        list = mrqItemsList;
      } else {
        list = [...state.materialItemList, ...mrqItemsList];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.materialItemParams?.page_size
      );
      return {
        ...state,
        materialItemList: list,
        loading: false,
        itemAddModel: true,
        materialItemParams: {
          ...state.materialItemParams,
          no_of_pages: noofpages,
        },
      };
    },
    setWarehouseStockItemParams: (state, action) => {
      return {
        ...state,
        materialItemParams: action.payload,
      };
    },

    // IBT request IN
    getIBTInList: (state, action) => {
      if (state.mrqParams?.page == 1) {
        return {
          ...state,
          loading: true,
          mrqList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    fsyncStatusUpdate: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    mrqClose: (state, action) => {
      return {
        ...state,
        loading: true,
      }
    },
    // mrqCloseSuccessfull: (state, action) => {
    //   return {
    //     ...state,
    //     loading: false,
    //   }
    // },
    apiError: (state, action) => {
      return {
        ...state,
        loading: false,
        formSubmitLoading: false,
        error_msg: action.payload,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getMrqList,
  mrqListSuccessful,
  setMrqParams,
  getIBTInList,
  FilterInputChangeValue,
  InputChangeValue,
  SelectChangeValue,
  getDeptBranchData,
  addMrq,
  getMrqViewData,
  mrqViewDataSuccessful,
  getMrqByIdItemsData,
  MrqByIdItemsDataSuccessful,
  setMrqByIdItemsParams,
  getMaterialItem,
  materialItemSuccessful,
  setMaterialItemParams,
  addMaterialItem,
  isItemAddModelVisible,
  removeCardItem,
  SItemInputChangeValue,
  mrqApproval,
  rejectModelInputChangeValue,
  mrqDelete,
  mrqClearData,
  MRQItemAdd,
  MRQDocAddMake,
  isDrawerVisible,
  checkFilterStatus,
  isMRQRejectModelVisible,
  isItemsListModelVisible,
  getUserWarehouseMRQ,
  userWarehouseMRQSuccessful,
  setUserWarehouseMRQParams,
  setMRQScreenType,
  clearMRQ,
  getWarehouseStockItem,
  warehouseStockItemSuccessful,
  setWarehouseStockItemParams,
  fsyncStatusUpdate,
  mrqClose,
  apiError,
} = MRQSlice.actions;

export default MRQSlice.reducer;
