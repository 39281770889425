import { all } from "redux-saga/effects";

// login
import loginSaga from "./auth/login/saga";

// notification
import notificationSaga from "./notifications/saga";

// Settings
import ManageUsersSaga from "./settings/ManageUsers/saga";
import ManageProfileSaga from "./settings/ManageProfile/saga";
import ManagersSaga from "./settings/Managers/saga";
import LocationManagerSaga from "./settings/LocationManager/saga";
import ApprovalSaga from "./settings/Approval/saga";
import ImportExportSaga from "./settings/ImportExport/saga";
import BackupDatabaseSaga from "./settings/BackupDatabse/saga";
import ManageCompanySaga from "./settings/ManageCompany/saga";
import AppSettingsSaga from "./settings/AppSettings/saga";
import stockSyncSaga from "./settings/StockSync/saga";

//mini
import miniSaga from "./mini/saga";

// Masters
import CountrySaga from "./Masters/Country/saga";

// Reports
import reportsSaga from "./reports/saga";
// dashboard
import dashboardSaga from "./dashboard/saga";
// System APIs
import menuItemSaga from "./system/saga";
// Side Menu
import MaterialRequestSaga from "./sideMenu/materialRequestQuote/saga";
import AcknowledgementSaga from "./sideMenu/Acknowledgement/saga";
import StockConsumptionSaga from "./sideMenu/StockConsumption/saga";
import ResourceConsumptionSaga from "./sideMenu/ResourceConsumption/saga";
import IBTSTNSaga from "./sideMenu/IBTSTN/saga";
import PettyCashSaga from "./sideMenu/pettyCash/saga";
import UtilityBillingSaga from "./sideMenu/utilityBilling/saga";

export default function* rootSaga() {
  yield all([
    // login
    loginSaga(),

    //notification
    notificationSaga(),

    // Settings
    ManageUsersSaga(),
    ManageProfileSaga(),
    ManagersSaga(),
    LocationManagerSaga(),
    ApprovalSaga(),
    ImportExportSaga(),
    BackupDatabaseSaga(),
    ManageCompanySaga(),
    AppSettingsSaga(),
    stockSyncSaga(),

    // mini
    miniSaga(),

    // Masters
    CountrySaga(),

    //Reports
    reportsSaga(),

    //dashboard
    dashboardSaga(),

    // system APIs
    menuItemSaga(),

    // Side Menu
    MaterialRequestSaga(),
    AcknowledgementSaga(),
    StockConsumptionSaga(),
    ResourceConsumptionSaga(),
    IBTSTNSaga(),
    PettyCashSaga(),
    UtilityBillingSaga(),
  ]);
}
