import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States

import {
  apiError,
  MasterMenuItemsSuccessful,
  sideMenuItemsSuccessful,
  ReportsMenuItemsSuccessful,
  userPermissionsSuccessful,
  QuickMenuItemsSuccessful,
  ImportMenuItemsSuccessful,
  profileMenuItemsSuccessful,
  ExportMenuItemsSuccessful,
  systemFormulasSuccessful,
} from "./systemSlice";
import { Modal } from "antd";
import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";

// AUTH related methods
import { getList, getParamsList, getDownloadFile } from "../../helpers/Helper";
function* MasterMenuItems() {
  try {
    const response = yield call(getList, "/system/usermenu/MENU0003", {});
    yield put(MasterMenuItemsSuccessful(response));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}
function* SideMenuItems() {
  try {
    const response = yield call(getList, "/system/usermenu/MENU0004", {});
    // const response = SideMenu;
    yield put(sideMenuItemsSuccessful(response));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}
function* ReportsMenuItems() {
  try {
    const response = yield call(getList, "/system/usermenu/MENU0005", {});
    yield put(ReportsMenuItemsSuccessful(response));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}
function* UserPermissions() {
  try {
    const response = yield call(getList, "/users/userpermissions/", {});
    yield put(userPermissionsSuccessful(response));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}
function* QuickMenuItems() {
  try {
    // const response = QuickMenu;
    const response = yield call(getList, "/system/usermenu/MENU0006", {});
    yield put(QuickMenuItemsSuccessful(response));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}
function* ImportMenuItems() {
  try {
    const response = yield call(getList, "/system/usermenu/MENU0007", {});
    yield put(ImportMenuItemsSuccessful(response));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}
function* ProfileMenuItems() {
  try {
    // const response = ProfileMenu;
    const response = yield call(getList, "/system/usermenu/MENU0008", {});
    yield put(profileMenuItemsSuccessful(response));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}
function* ExportMenuItems() {
  try {
    const response = yield call(getList, "/system/usermenu/MENU0009", {});
    yield put(ExportMenuItemsSuccessful(response));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}
function* SystemFormulas() {
  try {
    const response = yield call(getList, "/system/allformuls/", {});
    yield put(systemFormulasSuccessful(response));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}
function* DownloadFile({ payload }) {
  try {
    const response = yield call(getDownloadFile, payload);
    console.log("DownloadFile", response);
    // yield put(systemFormulasSuccessful(response.data));
  } catch (error) {
    yield put(apiError(error));
  }
}
export function* watchgetMasterMenuItems() {
  yield takeEvery("system/getMasterMenuItems", MasterMenuItems);
}
export function* watchGetSideMenuItems() {
  yield takeEvery("system/getSideMenuItems", SideMenuItems);
}
export function* watchGetReportsMenuItems() {
  yield takeEvery("system/getReportsMenuItems", ReportsMenuItems);
}
export function* watchGetUserPermissions() {
  yield takeEvery("system/getUserPermissions", UserPermissions);
}
export function* watchGetQuickMenuItems() {
  yield takeEvery("system/getQuickMenuItems", QuickMenuItems);
}
export function* watchGetImportMenuItems() {
  yield takeEvery("system/getImportMenuItems", ImportMenuItems);
}
export function* watchGetProfileMenuItems() {
  yield takeEvery("system/getProfileMenuItems", ProfileMenuItems);
}
export function* watchGetExportMenuItems() {
  yield takeEvery("system/getExportMenuItems", ExportMenuItems);
}
export function* watchGetSystemFormulas() {
  yield takeEvery("system/getSystemFormulas", SystemFormulas);
}
export function* watchGetDownloadFile() {
  yield takeEvery("system/getDownloadFile", DownloadFile);
}
function* menuItemSaga() {
  yield all([
    fork(watchgetMasterMenuItems),
    fork(watchGetSideMenuItems),
    fork(watchGetReportsMenuItems),
    fork(watchGetUserPermissions),
    fork(watchGetQuickMenuItems),
    fork(watchGetImportMenuItems),
    fork(watchGetProfileMenuItems),
    fork(watchGetExportMenuItems),
    fork(watchGetSystemFormulas),
    fork(watchGetDownloadFile),
  ]);
}

export default menuItemSaga;
