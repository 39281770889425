import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { Card, Row, Col } from "antd";
import PageTitle from "../../layout/components/content/page-title";

import StockHistoryCard from "./stockHistoryCard";
import TransactionCard from "./transactionCard";
import StockConsumptionColumnCard from "./stockConsumptionColumnCard";
import {
  clearData,
  getMRQCounts,
  getSTNCounts,
  getACKCounts,
  getStockCounts,
  getStockValues,
  getUserWarehousesList,
} from "../../store/dashboard/dashboardSlice";

function DashboardHome(props) {
  useEffect(() => {
    props.clearData();
    props.getMRQCounts();
    props.getSTNCounts();
    props.getACKCounts();
    props.getStockCounts();
    props.getUserWarehousesList({ page_size: 10, page: 1 });
    inactivityTime();
  }, []);

  var inactivityTime = function () {
    var time;
    // window.onload = resetTimer;
    // **************** DOM Events ********************* //
    // document.onmousemove = resetTimer;
    // document.onmousedown = resetTimer; // touchscreen presses
    // document.ontouchstart = resetTimer;
    // document.onclick = resetTimer; // touchpad clicks
    // document.onkeydown = resetTimer; // onkeypress is deprectaed
    // document.addEventListener("scroll", resetTimer, true);

    // **************** OR DOM Events Using array  ********************* //
    window.addEventListener("load", resetTimer, true);
    var events = ["mousedown", "mousemove", "keypress", "scroll", "touchstart"];
    events.forEach(function (name) {
      document.addEventListener(name, resetTimer, true);
    });
    function reload() {
      props.clearData();
      props.getMRQCounts();
      props.getSTNCounts();
      props.getACKCounts();
      props.getStockCounts();
      props.getUserWarehousesList({ page_size: 10, page: 1 });
      resetTimer();
    }

    function resetTimer() {
      clearTimeout(time);
      time = setTimeout(reload, 30000);
      // 1000 milliseconds = 1 second
    }
  };
  return (
    <Row gutter={[32, 32]} className="hp-mb-32">
      <Col flex="1" className="hp-overflow-hidden">
        <PageTitle pageTitle="Dashboard" goBack={false} />
        <Row gutter={[12, 12]}>
          <Col span={8} xs={24} sm={24} md={12} xl={8}>
            <TransactionCard
              title="Material Requests"
              data={props.MRQCountData}
            />
          </Col>
          <Col span={8} xs={24} sm={24} md={12} xl={8}>
            <TransactionCard
              title="Stock Transfers"
              data={props.STNCountData}
            />
          </Col>
          <Col span={8} xs={24} sm={24} md={12} xl={8}>
            <TransactionCard
              title="Acknowledgement"
              data={props.ACKCountData}
            />
          </Col>
          <Col span={14} xs={24} sm={24} md={24} xl={14}>
            {props.StockCountData.length != 0 && (
              <StockConsumptionColumnCard data={props.StockCountData} />
            )}
          </Col>
          <Col span={10} xs={24} sm={24} md={24} xl={10}>
            <StockHistoryCard />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

function mapStateToProps({ dashboard, reports, system }) {
  return {
    MRQCountData: dashboard.MRQCountData,
    STNCountData: dashboard.STNCountData,
    ACKCountData: dashboard.ACKCountData,
    StockCountData: dashboard.StockCountData,
  };
}

export default connect(mapStateToProps, {
  clearData,
  getMRQCounts,
  getSTNCounts,
  getACKCounts,
  getStockCounts,
  getStockValues,
  getUserWarehousesList,
})(DashboardHome);
