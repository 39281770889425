import { takeEvery, fork, put, all, call, select } from "redux-saga/effects";
import moment from "moment";
import {
  apiError,
  isModelVisible,
  utilityBillingListSuccessful,
  getUtilityBillingList,
  utilityBillingByIDSuccessful,
} from "./utilityBillingSlice";
import { Modal } from "antd";
import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";
// Auth Related Methods
import {
  postAdd,
  getParamsList,
  postEdit,
  postDelete,
  getList,
  postFormData, 
} from "../../../helpers/Helper";
import { serialize } from "object-to-formdata";



function* UtilityBillingList({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] != "" && k != null && k != "no_of_pages") {
          if (k == "start_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k == "end_date") {
            params[k] = moment(data[k]).add(1, "days").format("YYYY-MM-DD");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(getParamsList, "/utilitybilling/utility/list/", params);
    yield put(utilityBillingListSuccessful(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* AddUtilityBilling({ payload }) {
  try {
    if (payload.obj.id == 0) {
      const response = yield call(postFormData, "/utilitybilling/utility/list/", payload.obj);
      if (response.status === 201) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Utility Billing Added Successfully.!
              </p>
            </div>
          ),
        });
        payload.history.push("/pages/utility-billing");
        // yield put(getUtilityBillingList({ page: 1, search: "", page_size: 10 }));
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to add Utility Billing. Please try again!
              </p>
            </div>
          ),
        });
      }
    } else {
      const formData = serialize(payload.obj);
      const response = yield call(
        postEdit,
        "/utilitybilling/utility/" + payload.obj.id,
        formData
      );
      if (response.status === 200) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Utility Billing Edited Successfully.!
              </p>
            </div>
          ),
        });
        // yield put(getUtilityBillingList({ page: 1, search: "", page_size: 10 }));
        payload.history.push("/pages/utility-billing");
      } else {
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to Utility Billing. Please try again!
              </p>
            </div>
          ),
        });
      }
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}


function* UtilityBillingByID({ payload }) {
  try {
    const response = yield call(
      getParamsList,
      "/utilitybilling/utility/" +  payload, {});
    yield put(utilityBillingByIDSuccessful({ response}));
  } catch (error) {
    yield put(apiError(error));
  }
}



function* DeleteUtilityBilling({ payload }) {
  var areaID = payload;
  try {
    const response = yield call(postDelete, "//utilitybilling/utility/" + areaID);
    if (response.status === 204) {
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Your  Utility Billing has been deleted!
            </p>
          </div>
        ),
      });
      yield put(getUtilityBillingList({ page: 1, search: "", page_size: 10 }));
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Your  Utility Billing has been not deleted!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });

    yield put(apiError(error));
  }
}

function* UpdateFsyncStatus({ payload }) {
  let utilityParams = yield select((state) => state.utility.utilityBillingParams)
  try {
    const response = yield call(
      postAdd,
      "/thirdparty/fsyncutility/" + payload.id, {}
    );
    if (response.status === 200 ) {
      if( response.data.result == true){
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                {/* Selected Acknowledgement is successfully synchronised into focus */}
                {response.data.msg}
              </p>
            </div>
          ),
        });
        
      }else{
        Modal.error({
          icon: (
            <span className="remix-icon">
              <RiAlertLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                {/* Sorry! selected Acknowledgement is not posted on focus. please try
                again! */}
                {response.data.msg}
              </p>
            </div>
          ),
        });
      }
      yield put(getUtilityBillingList(utilityParams));
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! selected Utility Billing is not posted on focus. please try
              again!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),

      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">
            Sorry! Getting from server side issue!
          </p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* watchGetUtilityBillingList() {
  yield takeEvery("utility/getUtilityBillingList", UtilityBillingList);
}
export function* watchUtilityBillingAdd() {
  yield takeEvery("utility/utilityBillingAdd", AddUtilityBilling);
}
export function* watchGetUtilityBillingByID() {
  yield takeEvery("utility/getUtilityBillingByID", UtilityBillingByID);
}
export function* watchUtilityBillingDelete() {
  yield takeEvery("utility/utilityBillingDelete", DeleteUtilityBilling);
}
export function* watchFsyncStatusUpdate() {
  yield takeEvery("utility/fsyncStatusUpdate", UpdateFsyncStatus);
}

function* UtilityBillingSaga() {
  yield all([
    fork(watchGetUtilityBillingList),
    fork(watchUtilityBillingAdd),
    fork(watchGetUtilityBillingByID),
    fork(watchUtilityBillingDelete),
    fork(watchFsyncStatusUpdate),
  ]);
}

export default UtilityBillingSaga;
