// layout
import customiseReducer from "./customise/customiseSlice";

// Authentication Module
import Login from "./auth/login/loginSlice";

//notification
import Notification from "./notifications/notificationSlice";

// mini
import Mini from "./mini/miniSlice";
// Reports
import Reports from "./reports/reportsSlice";
// system APIs
import System from "./system/systemSlice";
//Masters
import Country from "./Masters/Country/countrySlice";

//Dashboard
import Dashboard from "./dashboard/dashboardSlice";

//Setting
import ManageUsers from "./settings/ManageUsers/manageUserSlice";
import ManageProfile from "./settings/ManageProfile/profileSlice";
import LocationManager from "./settings/LocationManager/locationManagerSlice";
import Managers from "./settings/Managers/ManagersSlice";
import Approval from "./settings/Approval/approvalSlice";
import ImportExport from "./settings/ImportExport/importExportSlice";
import BackupDatabase from "./settings/BackupDatabse/backupDataBaseSlice";
import ManageCompany from "./settings/ManageCompany/manageCompanySlice";
import AppSettings from "./settings/AppSettings/appsettingsSlice";
import StockSyncData from "./settings/StockSync/stockSyncSlice";

//Side Menu
import MaterialRequest from "./sideMenu/materialRequestQuote/MRQSlice";
import Acknowledgement from "./sideMenu/Acknowledgement/AcknowledgementSlice";
import StockConsumption from "./sideMenu/StockConsumption/StockConsumptionSlice";
import ResourceConsumption from "./sideMenu/ResourceConsumption/ResourceConsumptionSlice";
import IBTSTN from "./sideMenu/IBTSTN/IBTSTNSlice";
import PettyCash from "./sideMenu/pettyCash/pettyCashSlice";
import Utility from "./sideMenu/utilityBilling/utilityBillingSlice";

export default {
  // Layout,
  customise: customiseReducer,

  // Authentication
  login: Login,

  //Notification
  notification: Notification,

  // mini
  mini: Mini,
  // Reports
  reports: Reports,
  // system APIs
  system: System,
  //Masters
  country: Country,
  // Dashboard
  dashboard: Dashboard,
  //Settings
  manageUser: ManageUsers,
  manageProfile: ManageProfile,
  locationManager: LocationManager,
  managers: Managers,
  approval: Approval,
  importExport: ImportExport,
  backupDatabase: BackupDatabase,
  manageCompany: ManageCompany,
  appSettings: AppSettings,
  stockSync: StockSyncData,

  //SideMenu
  materialRequest: MaterialRequest,
  acknowledgement: Acknowledgement,
  stockConsumption: StockConsumption,
  resourceConsumption: ResourceConsumption,
  ibtStn: IBTSTN,
  pettyCash: PettyCash,
  utility: Utility,
};
