import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import moment from "moment";
import {
  apiError,
  IBTSTNListSuccessful,
  getPendingIBTList,
  userWarehouseIBTSuccessful,
  pendingIBTListSuccessful,
  mrqViewDataSuccessful,
  getMrqByIdItemsData,
  setMrqByIdItemsParams,
  MrqByIdItemsDataSuccessful,
  stockListByItemIdSuccessful,
  IBTSTNItemsAdd,
  IBTSTNDocAddMake,
  STNDocViewDataSuccessful,
  getSTNItemsById,
  STNItemsByIdListSuccessful,
  clearIBTSTNData,
  //
  // getAcknowledgmentList,
  // getSTNDocDataSuccessful,
  // getSTNDocItems,
  // STNDocItemListSuccessful,
  // stnRecievedListSuccessful,
  // mrqApprovedListSuccessful,
} from "./IBTSTNSlice";
import {
  getList,
  getParamsList,
  postAdd,
  postEdit,
  postFormData,
  postDelete,
} from "../../../helpers/Helper";
import { Modal } from "antd";

import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";

//If user is login then dispatch redux action's are directly from here.

function* IBTSTNListGet({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] != "" && k != null && k != "no_of_pages") {
          if (k == "start_date") {
            params[k] = moment(data[k]).format("YYYY-MM-DD");
          } else if (k == "end_date") {
            params[k] = moment(data[k]).add(1, "days").format("YYYY-MM-DD");
          } else {
            params[k] = data[k];
          }
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/stocktransfernote/list/",
      params
    );
    yield put(IBTSTNListSuccessful({ response }));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* UserWarehouseIBTGet({ payload }) {
  var params = {};
  for (const k in payload) {
    if (Object.hasOwnProperty.call(payload, k)) {
      if (k === "type") {
        params.type = payload.type.join(",");
      } else {
        if (payload[k] !== "" && k !== "no_of_pages") {
          params[k] = payload[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/masters/user/warehouses/list/",
      params
    );
    if (response.results.length == 1) {
      yield put(
        getPendingIBTList({
          params: { page: 1, page_size: 10 },
          ibtStnData: {
            warehouse: {
              id: response.results[0].id,
              name: response.results[0].name,
            },
            branch: response.results[0].branch,
            department: response.results[0].department,
          },
        })
      );
    }
    yield put(userWarehouseIBTSuccessful(response));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}
function* PendingIBTListGet({ payload }) {
  var params = {};
  var data = payload.params;
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "type") {
        params.type = data.type.join(",");
      } else {
        if (data[k] !== "" && k !== "no_of_pages") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/material_request_quotes/ibt/approved/list/" +
        payload.ibtStnData.warehouse?.id,
      params
    );
    yield put(pendingIBTListSuccessful(response));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* MrqViewDataGet({ payload }) {
  try {
    const response = yield call(
      getList,
      "/material_request_quotes/" + payload,
      {}
    );

    yield put(
      setMrqByIdItemsParams({
        start_date: "",
        end_date: "",
        no_of_pages: 0,
        page_size: 10,
        page: 1,
        search: "",
      })
    );
    yield put(mrqViewDataSuccessful({ response }));
    yield put(getMrqByIdItemsData({ id: response.id, page_size: 10, page: 1 }));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* MrqByIdItemsDataGet({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "" && k !== "id" && k !== "no_of_pages") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "material_request_quotes/mrqitems/" + payload.id,
      params
    );
    yield put(MrqByIdItemsDataSuccessful({ response }));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}
function* StockListByItemId({ payload }) {
  console.log("warehouse_id", payload);
  var params = {};
  var data = payload.params;
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "type") {
        params.type = data.type.join(",");
      } else {
        if (data[k] !== "" && k !== "no_of_pages" && k !== "warehouse_id") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/stockconsumption/warehouse/" + payload.url_data.warehouse_id,
      params
    );
    yield put(
      stockListByItemIdSuccessful({
        response,
        mrq_item: payload.url_data.mrq_item,
      })
    );
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* IBTSTNAdd({ payload }) {
  try {
    const response = yield call(
      postAdd,
      "/stocktransfernote/",
      payload.ibtStnObj
    );
    if (response.status === 200) {
      yield put(
        IBTSTNItemsAdd({
          stnitems: payload.stnitems,
          unique_id: response.data.unique_id,
          ibtStnObj: payload.ibtStnObj,
          history: payload.history,
        })
      );
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Unable to added Stock Transfer. Please try again!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* AddIBTSTNItems({ payload }) {
  var stnitems = payload.stnitems.map((item) => {
    return item;
  });

  try {
    var response = yield call(postAdd, "/stocktransfernote/items/add/", {
      unique_id: payload.unique_id,
      stnitems: stnitems,
    });
    if (response.status === 200) {
      yield put(
        IBTSTNDocAddMake({
          ibtStnObj: payload.ibtStnObj,
          unique_id: payload.unique_id,
          do_save: true,
          history: payload.history,
        })
      );
    } else {
      yield put(
        IBTSTNDocAddMake({
          ibtStnObj: payload.ibtStnObj,
          unique_id: payload.unique_id,
          do_save: false,
        })
      );
    }
  } catch (error) {
    yield put(
      IBTSTNDocAddMake({
        ibtStnObj: payload.ibtStnObj,
        unique_id: payload.unique_id,
        do_save: false,
      })
    );

    yield put(apiError(error));
  }
}

function* AddIBTSTNDocMake({ payload }) {
  try {
    const response = yield call(postAdd, "/stocktransfernote/mark_saved/", {
      unique_id: payload.unique_id,
      do_save: payload.do_save,
    });
    if (response.status === 200) {
      if (payload.do_save) {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Your Stock Transfer Added Successfully.!
              </p>
            </div>
          ),
        });
        yield put(clearIBTSTNData());
        payload.history.push("/pages/ibt-stn");
      } else {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to added Stock Transfer. Please try again!
              </p>
            </div>
          ),
        });

        // payload.history.push("/pages/Acknowledgement");
      }
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Unable to Update Stock Transfer. Please try again!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* STNDocViewDataGet({ payload }) {
  try {
    const response = yield call(
      getParamsList,
      "/stocktransfernote/" + payload,
      {}
    );
    yield put(STNDocViewDataSuccessful({ response }));
    yield put(getSTNItemsById({ id: response.id, page_size: 10, page: 1 }));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* STNItemsByIdGet({ payload }) {
  var data = payload;
  var params = {};
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "currentSort" || k === "sortOrder") {
        params.ordering = data.sortOrder + data.currentSort;
      } else {
        if (data[k] !== "" && k !== "id") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/stocktransfernote/stnitems/" + payload.id,
      params
    );
    yield put(STNItemsByIdListSuccessful(response));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}
//

// function* STNDocDataGet({ payload }) {
//   try {
//     const response = yield call(getList, "/stocktransfernote/" + payload);
//     yield put(getSTNDocDataSuccessful({ response }));
//     yield put(
//       getSTNDocItems({ id: response.id, params: { page_size: 10, page: 1 } })
//     );
//   } catch (error) {
//     yield put(apiError(error));
//   }
// }

// function* STNDocItemsGet({ payload }) {
//   var params = {};
//   var data = payload?.params;
//   for (const k in data) {
//     if (Object.hasOwnProperty.call(data, k)) {
//       if (k === "type") {
//         params.type = data.type.join(",");
//       } else {
//         if (data[k] !== "" && k !== "no_of_pages") {
//           params[k] = data[k];
//         }
//       }
//     }
//   }
//   try {
//     const response = yield call(
//       getParamsList,
//       "/stocktransfernote/stn_items/" + payload.id,
//       params
//     );
//     yield put(STNDocItemListSuccessful(response));
//   } catch (error) {
//     yield put(apiError(error));
//   }
// }

// function* STNRecievedList({ payload }) {
//   var params = {};
//   for (const k in payload) {
//     if (Object.hasOwnProperty.call(payload, k)) {
//       if (k === "type") {
//         params.type = payload.type.join(",");
//       } else {
//         if (payload[k] !== "" && k !== "no_of_pages") {
//           params[k] = payload[k];
//         }
//       }
//     }
//   }
//   try {
//     const response = yield call(
//       getParamsList,
//       "/stocktransfernote/recieved_list/",
//       params
//     );
//     yield put(stnRecievedListSuccessful(response));
//   } catch (error) {
//     yield put(apiError(error));
//   }
// }

// function* MrqApprovedList({ payload }) {
//   var params = {};
//   for (const k in payload) {
//     if (Object.hasOwnProperty.call(payload, k)) {
//       if (k === "type") {
//         params.type = payload.type.join(",");
//       } else {
//         if (payload[k] !== "" && k !== "no_of_pages") {
//           params[k] = payload[k];
//         }
//       }
//     }
//   }
//   try {
//     const response = yield call(
//       getParamsList,
//       "/material_request_quotes/mrq/approved",
//       params
//     );
//     yield put(mrqApprovedListSuccessful(response));
//   } catch (error) {
//     yield put(apiError(error));
//   }
// }

export function* watchGetAcknowledgmentList() {
  yield takeEvery("ibtStn/getIBTSTNList", IBTSTNListGet);
}
export function* watchGetUserWarehouseIBT() {
  yield takeEvery("ibtStn/getUserWarehouseIBT", UserWarehouseIBTGet);
}
export function* watchGetPendingIBTList() {
  yield takeEvery("ibtStn/getPendingIBTList", PendingIBTListGet);
}
export function* watchGetMrqViewData() {
  yield takeEvery("ibtStn/getMrqViewData", MrqViewDataGet);
}
export function* watchGetMrqByIdItemsData() {
  yield takeEvery("ibtStn/getMrqByIdItemsData", MrqByIdItemsDataGet);
}
export function* watchGetStockListByItemId() {
  yield takeEvery("ibtStn/getStockListByItemId", StockListByItemId);
}
export function* watchAddIBTSTN() {
  yield takeEvery("ibtStn/addIBTSTN", IBTSTNAdd);
}
export function* watchIBTSTNItemsAdd() {
  yield takeEvery("ibtStn/IBTSTNItemsAdd", AddIBTSTNItems);
}
export function* watchIBTSTNDocAddMake() {
  yield takeEvery("ibtStn/IBTSTNDocAddMake", AddIBTSTNDocMake);
}
export function* watchGetSTNDocViewData() {
  yield takeEvery("ibtStn/getSTNDocViewData", STNDocViewDataGet);
}
export function* watchGetSTNItemsById() {
  yield takeEvery("ibtStn/getSTNItemsById", STNItemsByIdGet);
}
// export function* watchGetSTNDocData() {
//   yield takeEvery("acknowledgement/getSTNDocData", STNDocDataGet);
// }
// export function* watchGetSTNDocItems() {
//   yield takeEvery("acknowledgement/getSTNDocItems", STNDocItemsGet);
// }

// export function* watchGetStnRecievedList() {
//   yield takeEvery("acknowledgement/getStnRecievedList", STNRecievedList);
// }
// export function* watchGetMrqApprovedList() {
//   yield takeEvery("acknowledgement/getMrqApprovedList", MrqApprovedList);
// }

function* IBTSTNSaga() {
  yield all([
    fork(watchGetAcknowledgmentList),
    fork(watchGetUserWarehouseIBT),
    fork(watchGetPendingIBTList),
    fork(watchGetMrqViewData),
    fork(watchGetMrqByIdItemsData),
    fork(watchGetStockListByItemId),
    fork(watchAddIBTSTN),
    fork(watchIBTSTNItemsAdd),
    fork(watchIBTSTNDocAddMake),
    fork(watchGetSTNDocViewData),
    fork(watchGetSTNItemsById),
    //
    // fork(watchGetSTNDocData),
    // fork(watchGetSTNDocItems),
    // fork(watchGetStnRecievedList),
    // fork(watchGetMrqApprovedList),
  ]);
}

export default IBTSTNSaga;
