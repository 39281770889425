import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

export const StockConsumptionSlice = createSlice({
  name: "stockConsumption",
  initialState: {
    loginError: "aaa",
    message: null,
    loading: false,
    apiError: {},
    listCount: 0,
    drawer: false,
    mrqDrawer: false,
    stockData: {},
    StockConsumptionList: [],
    selectedStockConsumptionList: [],
    purchaseData: {},
    StockItemsData: {},
    stockConsumptionParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
    itemsListModel: false,
    stockSubmitModel: false,
    ItemHistoryList: [],
    ItemHistoryListCount: 0,
    ItemHistoryParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
  },
  reducers: {
    checkStockConsumptionDraft: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    checkDraftStockConsumptionSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        stockData: {
          ...state.stockData,
          id: action.payload.pk == null ? null : action.payload?.pk,
          no_of_items: action.payload.no_of_items,
        },
      };
    },
    getStockConsumptionList: (state) => {
      if (state.stockConsumptionParams?.page == 1) {
        return {
          ...state,
          loading: true,
          StockConsumptionList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    stockConsumptionListSuccessful: (state, action) => {
      var response = action.payload;
      // var SelectedList = [];
      var list = response.results.map((item) => {
        if (item.stc_item != null) {
          item.consumption_qty = item.stc_item?.qty;
          item.remarks = item.stc_item?.remarks;
        }

        var filterlist = state.selectedStockConsumptionList.filter(
          (scItem) =>
            scItem.material_item?.id == item.material_item?.id &&
            scItem.batch.id == item.batch.id
        );
        console.log("filterlist", filterlist);
        if (filterlist.length > 0) {
          item.consumption_qty = filterlist[0].consumption_qty;
          item.remarks = filterlist[0].remarks;
        }

        return {
          ...item,
        };
      });

      var noofpages = Math.ceil(
        action.payload.count / state.stockConsumptionParams?.page_size
      );
      return {
        ...state,
        StockConsumptionList: list,
        listCount: action.payload.count,
        loading: false,
        stockConsumptionParams: {
          ...state.stockConsumptionParams,
          no_of_pages: noofpages,
        },
      };
    },
    setStockConsumptionParams: (state, action) => {
      return {
        ...state,
        stockConsumptionParams: action.payload,
      };
    },

    StockInputChangeValue: (state, action) => {
      // switch (action.payload.key) {
      //   case "branch_id":
      //     return {
      //       ...state,
      //       stockData: {
      //         ...state.stockData,
      //         [action.payload.key]: action.payload.value,
      //         department_id: null,
      //       },
      //     };
      //   default:
      //     return {
      //       ...state,
      //       stockData: {
      //         ...state.stockData,
      //         [action.payload.key]: action.payload.value,
      //       },
      //     };
      //     break;
      // }
      return {
        ...state,
        stockData: {
          ...state.stockData,
          [action.payload.key]: action.payload.value,
        },
      };
    },

    addStockConsumption: (state) => {
      return {
        ...state,
        loading: true,
      };
    },

    addStockConsumptionItem: (state) => {
      return {
        ...state,
        loading: true,
      };
    },

    addStockDocSave: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    StockDocSaveSuccessful: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    addStockSubmit: (state) => {
      return {
        ...state,
        loading: true,
      };
    },

    clearStockList: (state, action) => {
      return {
        ...state,
        loading: false,
        stockData: {},
        StockItemsData: {},
        StockConsumptionList: [],
        selectedStockConsumptionList: [],
        stockConsumptionParams: {
          no_of_pages: 0,
          page_size: 10,
          page: 1,
          search: "",
        },
        listCount: 0,
      };
    },

    InputChangeValue: (state, action) => {
      return {
        ...state,
        StockItemsData: {
          ...state.StockItemsData,
          [action.payload.key]: action.payload.value,
        },
      };
    },

    stockItemInputChangeValue: (state, action) => {
      var selectedStockItem = state.StockConsumptionList.map((item) => {
        if (
          item.material_item?.id == action.payload.item.material_item?.id &&
          item.batch.id == action.payload.item.batch.id
        ) {
          return {
            ...item,
            [action.payload.key]: action.payload.value,
          };
        }
        return item;
      });
      return {
        ...state,
        StockConsumptionList: selectedStockItem,
      };
    },
    updateStockConsumptionList: (state, action) => {
      console.log("updateStockConsumptionList", action.payload);
      var filterlist = state.selectedStockConsumptionList.filter(
        (item) =>
          item.material_item?.id == action.payload.item.material_item?.id &&
          item.batch.id == action.payload.item.batch.id
      );
      if (filterlist.length == 0) {
        return {
          ...state,
          selectedStockConsumptionList: [
            ...state.selectedStockConsumptionList,
            {
              ...action.payload.item,
              [action.payload.key]: action.payload.value,
            },
          ],
        };
      } else {
        var SelectedList = state.selectedStockConsumptionList.map((item) => {
          if (item.material_item?.id == action.payload.item.material_item?.id) {
            return {
              ...item,
              [action.payload.key]: action.payload.value,
            };
          } else {
            return item;
          }
        });
        return {
          ...state,
          selectedStockConsumptionList: SelectedList,
        };
      }
    },

    FilterInputChangeValue: (state, action) => {
      switch (action.payload.key) {
        case "counter__zone":
          return {
            ...state,
            purchaseParams: {
              ...state.purchaseParams,
              [action.payload.key]: action.payload.value,
              counter__cluster: null,
              counter__division: null,
              counter: null,
            },
          };

        case "counter__division":
          return {
            ...state,
            purchaseParams: {
              ...state.purchaseParams,
              [action.payload.key]: action.payload.value,
              counter__cluster: null,
              counter: null,
            },
          };

        case "counter__cluster":
          return {
            ...state,
            purchaseParams: {
              ...state.purchaseParams,
              [action.payload.key]: action.payload.value,
              counter: null,
            },
          };
        default:
          return {
            ...state,
            purchaseParams: {
              ...state.purchaseParams,
              [action.payload.key]: action.payload.value,
            },
          };
      }
      // return {
      //   ...state,
      //   purchaseParams: {
      //     ...state.purchaseParams,
      //     [action.payload.key]: action.payload.value,
      //   },
      // };
    },
    GetItemHistory: (state, action) => {
      if (state.ItemHistoryParams?.page == 1) {
        return {
          ...state,
          loading: true,
          ItemHistoryList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    ItemHistorySuccessful: (state, action) => {
      var response = action.payload.results;
      var noofpages = Math.ceil(
        action.payload.count / state.ItemHistoryParams?.page_size
      );
      return {
        ...state,
        ItemHistoryList: response,
        ItemHistoryListCount: action.payload.count,
        loading: false,
        ItemHistoryParams: {
          ...state.ItemHistoryParams,
          no_of_pages: noofpages,
        },
      };
    },
    setItemHistoryParams: (state, action) => {
      return {
        ...state,
        ItemHistoryParams: action.payload,
      };
    },

    isItemsListModelVisible: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          itemsListModel: action.payload,
          rowdata: {},
        };
      } else {
        return {
          ...state,
          itemsListModel: action.payload,
        };
      }
    },
    isStockSubmitModelVisible: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          stockSubmitModel: action.payload,
          StockItemsData: {},
        };
      } else {
        return {
          ...state,
          stockSubmitModel: action.payload,
        };
      }
    },
    getDeptBranchData: (state, action) => {
      return {
        ...state,
        loading: false,
        stockData: {
          ...state.stockData,
          branch: action.payload.branch,
          department: action.payload.department,
        },
      };
    },
    apiError: (state, action) => {
      return {
        ...state,
        loading: false,
        formSubmitLoading: false,
        error_msg: action.payload,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  checkStockConsumptionDraft,
  checkDraftStockConsumptionSuccessful,
  StockInputChangeValue,
  getStockConsumptionList,
  stockConsumptionListSuccessful,
  setStockConsumptionParams,
  addStockConsumption,
  addStockConsumptionItem,
  addStockDocSave,
  StockDocSaveSuccessful,
  stockItemInputChangeValue,
  updateStockConsumptionList,
  clearStockList,
  InputChangeValue,
  apiError,
  isDrawerVisible,
  FilterInputChangeValue,
  GetItemHistory,
  ItemHistorySuccessful,
  isItemsListModelVisible,
  setItemHistoryParams,
  isStockSubmitModelVisible,
  getDeptBranchData,
  addStockSubmit,
} = StockConsumptionSlice.actions;

export default StockConsumptionSlice.reducer;
