import React, { useState } from "react";

import { Card, Row, Col } from "antd";
import Chart from "react-apexcharts";

export default function TransactionCard(props) {
  const [data] = useState({});
  console.log("dataValue", props.data);

  return (
    <Card className="hp-border-color-black-40 hp-card-6 hp-p-0">
      <Row gutter={(12, 12)}>
        <Col span={12}>
          <span>{props.title}</span>
        </Col>

        <Col span={24} className="hp-p-0">
          <div id="earnings-donut-card" className="hp-donut-chart">
            <Chart
              options={{
                chart: {
                  id: "earnings-donut-card",
                  fontFamily: "Manrope, sans-serif",
                  type: "donut",
                  toolbar: {
                    show: false,
                  },
                  zoom: {
                    enabled: false,
                  },
                },
                colors: props.data.map((d) => d.color),
                labels: props.data.map((d) => d.title + ":" + d.total),
                // labels: {
                //   formatter: function (w) {
                //     return props.data.map((d) => d.title + ":" + d.total);
                //   },
                // },

                dataLabels: {
                  enabled: false,
                },

                plotOptions: {
                  pie: {
                    donut: {
                      size: "80%",
                      labels: {
                        show: true,
                        name: {
                          fontSize: "2rem",
                        },
                        value: {
                          fontSize: "20px",
                          fontWeight: "regular",
                          color: "B2BEC3",
                          formatter(val) {
                            return val;
                          },
                        },
                        total: {
                          show: true,
                          fontSize: "20px",
                          fontWeight: "regular",
                          // label: "Kitchen",
                          color: "#636E72",

                          formatter: function (w) {
                            return `${w.globals.seriesTotals.reduce((a, b) => {
                              return a + b;
                            }, 0)}`;
                          },
                        },
                      },
                    },
                  },
                },
                responsive: [
                  {
                    breakpoint: 426,
                    options: {
                      legend: {
                        itemMargin: {
                          horizontal: 8,
                          vertical: 4,
                        },
                      },
                    },
                  },
                ],

                legend: {
                  itemMargin: {
                    horizontal: 2,
                    vertical: 2,
                  },
                  horizontalAlign: "center",
                  position: "left",
                  fontSize: "14px",
                  inverseOrder: true,
                  markers: {
                    radius: 12,
                  },
                },
              }}
              series={props.data.map((d) => d.total)}
              type="donut"
              height={"100%"}
              legend="legend"
            />
          </div>
        </Col>
      </Row>
    </Card>
  );
}
