import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

export const IBTSTNSlice = createSlice({
  name: "ibtStn",
  initialState: {
    loginError: "aaa",
    message: null,
    loading: false,
    formSubmitLoading: false,
    apiError: {},
    listCount: 0,
    ibtStnParams: {
      start_date: "",
      end_date: "",
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    ibtStnList: [],
    drawer: false,
    PendingIBTList: [],
    PendingIBTListParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    ibtStnData: {},
    userWarehouseIBTList: [],
    userWarehouseIBTParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },

    mrqData: {},
    mrqByIdItemsData: [],
    mrqByIdItemsPrintData: [],
    mrqItemslistCount: 0,
    mrqByIdItemsDataParams: {
      start_date: "",
      end_date: "",
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    StockListByItemId: [],
    StockListByItemIdCount: 0,
    stockListByItemIdParams: {},
    STNItem: {},
    StockItemsModel: false,
    selectedStnItemsList: [],
    Total_IBT_qty: 0,
    STNSubmitModel: false,
    STNData: {},
    stnItemsListModel: false,
    STNDocData: {},

    STNItemslistCount: 0,
    STNDocId: 0,
    STNDocItemsParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    //
    STNDoclistCount: 0,
    STNDocItemsList: [],
    STNItemsByIdList: [],
    StnRecievedList: [],
    MrqApprovedList: [],
    screeType: "",

    STNItemsParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    StnRecievedParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    MrqApprovedParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },

    mrqDrawer: false,
  },
  reducers: {
    getIBTSTNList: (state, action) => {
      if (state.ibtStnParams?.page == 1) {
        return {
          ...state,
          loading: true,
          ibtStnList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    IBTSTNListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count / state.ibtStnParams.page_size
      );
      return {
        ...state,
        listCount: action.payload.response.count,

        loading: false,
        ibtStnList: action.payload.response.results,
        ibtStnParams: {
          ...state.ibtStnParams,
          no_of_pages: noofpages,
        },
      };
    },
    setIBTSTNParams: (state, action) => {
      return {
        ...state,
        ibtStnParams: action.payload,
      };
    },
    getUserWarehouseIBT: (state) => {
      if (state.userWarehouseIBTParams?.page == 1) {
        return {
          ...state,
          loading: true,
          userWarehouseIBTList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    userWarehouseIBTSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.userWarehouseIBTParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.userWarehouseIBTList, ...response.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.userWarehouseIBTParams?.page_size
      );
      if (list.length == 1) {
        return {
          ...state,
          userWarehouseIBTList: list,
          ibtStnData: {
            ...state.ibtStnData,
            warehouse: { id: list[0].id, name: list[0].name },
            branch: list[0].branch,
            department: list[0].department,
          },
          loading: false,
        };
      }
      return {
        ...state,
        userWarehouseIBTList: list,
        loading: false,
        userWarehouseIBTParams: {
          ...state.userWarehouseIBTParams,
          no_of_pages: noofpages,
        },
      };
    },
    setUserWarehouseIBTParams: (state, action) => {
      return {
        ...state,
        userWarehouseIBTParams: action.payload,
      };
    },
    getPendingIBTList: (state) => {
      if (state.PendingIBTListParams?.page == 1) {
        return {
          ...state,
          loading: true,
          PendingIBTList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    pendingIBTListSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.PendingIBTListParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.PendingIBTList, ...response.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.PendingIBTListParams?.page_size
      );
      return {
        ...state,
        PendingIBTList: list,
        loading: false,
        PendingIBTListParams: {
          ...state.PendingIBTListParams,
          no_of_pages: noofpages,
        },
      };
    },
    setPendingIBTParams: (state, action) => {
      return {
        ...state,
        PendingIBTListParams: action.payload,
      };
    },

    getMrqViewData: (state, action) => {
      return {
        ...state,
        mrqByIdItemsDataParams: {
          start_date: "",
          end_date: "",
          no_of_pages: 0,
          page_size: 10,
          page: 1,
          search: "",
        },
        loading: true,
        mrqData: {},
        mrqByIdItemsData: [],
        mrqByIdItemsPrintData: [],
      };
    },
    mrqViewDataSuccessful: (state, action) => {
      return {
        ...state,
        // mrqViewData: action.payload.response,
        // mrqData: action.payload.response,
        mrqData: {
          ...action.payload.response,
          id: action.payload.response.id,
          date:
            action.payload.response.date == null
              ? ""
              : moment(action.payload.response.date),
          due_date:
            action.payload.response.due_date == null
              ? ""
              : moment(action.payload.response.due_date),
          biometric_id: action.payload.response.biometric_id,
          biometric_name: action.payload.response.biometric_name,
          branch_id: action.payload.response.branch?.id,
          department_id: action.payload.response.department?.id,
          stockpoint_id: action.payload.response.stockpoint?.id,
          warehouse_id: action.payload.response.warehouse?.id,
          material_type_id: action.payload.response.material_type?.id,
        },
        loading: false,
      };
    },
    getMrqByIdItemsData: (state, action) => {
      if (state.mrqByIdItemsDataParams?.page == 1) {
        return {
          ...state,
          loading: true,
          mrqDocId: action.payload.id,
          mrqByIdItemsData: [],
          mrqByIdItemsPrintData: [],
        };
      } else {
        return {
          ...state,
          loading: true,
          mrqDocId: action.payload.id,
        };
      }
    },
    MrqByIdItemsDataSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count / state.mrqByIdItemsDataParams.page_size
      );
      var selectItems = action.payload.response.results.map((item) => {
        return { ...item, sendQty: 0 };
      });

      return {
        ...state,
        mrqItemslistCount: action.payload.response.count,
        mrqByIdItemsData: [...state.mrqByIdItemsData, ...selectItems],
        //  itemsListModel: true,
        loading: false,
        mrqByIdItemsDataParams: {
          ...state.mrqByIdItemsDataParams,
          no_of_pages: noofpages,
        },
      };
    },
    setMrqByIdItemsParams: (state, action) => {
      return {
        ...state,
        mrqByIdItemsDataParams: action.payload,
      };
    },

    getStockListByItemId: (state) => {
      if (state.stockListByItemIdParams?.page == 1) {
        return {
          ...state,
          loading: true,
          StockListByItemId: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    stockListByItemIdSuccessful: (state, action) => {
      var response = action.payload.response;
      // var SelectedList = [];
      var list = response.results.map((item) => {
        state.selectedStnItemsList.map((scItem) => {
          if (
            scItem.material_item_id == item.material_item?.id &&
            scItem.batch_id == item.batch.id
          ) {
            item.Transfer_Qty = scItem.qty;
          }
        });
        // if (item.stc_item != null) {
        //   item.consumption_qty = item.stc_item?.qty;
        //   item.remarks = item.stc_item?.remarks;
        // }

        // var filterlist = state.selectedStnItemsList.filter(
        //   (scItem) =>
        //     scItem.material_item_id == item.material_item?.id &&
        //     scItem.batch_id == item.batch.id
        // );
        // console.log("filterlist", filterlist);
        // if (filterlist.length > 0) {
        //   item.consumption_qty = filterlist[0].consumption_qty;
        //   item.remarks = filterlist[0].remarks;
        // }

        return {
          ...item,
        };
      });

      var noofpages = Math.ceil(
        action.payload.response.count / state.stockListByItemIdParams?.page_size
      );
      return {
        ...state,
        StockListByItemId: list,
        StockListByItemIdCount: action.payload.response.count,
        STNItem: action.payload.mrq_item,
        loading: false,
        stockListByItemIdParams: {
          ...state.stockListByItemIdParams,
          no_of_pages: noofpages,
        },
        StockItemsModel: true,
      };
    },
    setStockListByItemIdParams: (state, action) => {
      return {
        ...state,
        stockListByItemIdParams: action.payload,
      };
    },
    StockItemInputChangeValue: (state, action) => {
      var selectedItem = state.StockListByItemId.map((item) => {
        if (
          item.material_item_id == action.payload.item.material_item_id &&
          item.batch.id == action.payload.item.batch.id
        ) {
          return {
            ...item,
            [action.payload.key]: action.payload.value,
          };
        }
        return item;
      });
      return {
        ...state,
        StockListByItemId: selectedItem,
      };
    },
    selectedSTNItems: (state, action) => {
      // Old Code
      // console.log("action.payload.stnitems", action.payload.stnitems);
      // var selectedBatcheIds = action.payload.stnitems.map((item) => {
      //   return item.batch_id;
      // });
      // var selectedMaterialItemIds = action.payload.stnitems.map((item) => {
      //   return item.material_item_id;
      // });
      // console.log("selectedBatcheIds", selectedBatcheIds);

      // var SelectedSTNItem = state.selectedStnItemsList.filter((item) => {
      //   console.log(
      //     item.batch_id,
      //     selectedBatcheIds.indexOf(item.batch_id) != -1,
      //     item.material_item_id,
      //     selectedMaterialItemIds.indexOf(item.material_item_id) != -1
      //   );
      //   return !(
      //     selectedBatcheIds.indexOf(item.batch_id) != -1 &&
      //     selectedMaterialItemIds.indexOf(item.material_item_id) != -1
      //   );
      // });
      // console.log("SelectedSTNItem", SelectedSTNItem);
      // var mrqItemsList = state.mrqByIdItemsData.map((item) => {
      //   if (
      //     item.material_item.id == action.payload.stnitems[0]?.material_item_id
      //   ) {
      //     return { ...item, sendQty: action.payload.totalIBTQty };
      //   }
      //   return item;
      // });
      // return {
      //   ...state,
      //   selectedStnItemsList: [...SelectedSTNItem, ...action.payload.stnitems],
      //   mrqByIdItemsData: mrqItemsList,
      //   StockItemsModel: false,
      // };

      console.log("action.payload.stnitems", action.payload.stnitems);
      var stnitems = action.payload.stnitems
      var selectedBatcheIds = stnitems.map((item) => {
        return item.batch_id;
      });
      var selectedMaterialItemIds = stnitems.map((item) => {
        return item.material_item_id;
      });
      console.log("selectedBatcheIds{}{}{}", selectedBatcheIds);

      var SelectedSTNItem = state.selectedStnItemsList.filter((item) => {
        // console.log(
        //   item.batch_id,
        //   selectedBatcheIds.indexOf(item.batch_id) != -1,
        //   item.material_item_id,
        //   selectedMaterialItemIds.indexOf(item.material_item_id) != -1
        // );
        return !(
          selectedBatcheIds.indexOf(item.batch_id) != -1 &&
          selectedMaterialItemIds.indexOf(item.material_item_id) != -1
        );
      });
      console.log("SelectedSTNItem------------", SelectedSTNItem);
      console.log("aaction.payload.totalIBTQtyc", action.payload.totalIBTQty);
      var mrqItemsList = state.mrqByIdItemsData.map((item) => {
        if (
          item.material_item.id == stnitems[0]?.material_item_id
        ) {
          return { ...item, sendQty: action.payload.totalIBTQty };
        }
        return item;
      });
      stnitems = stnitems.filter((item) => {
        return item.qty > 0;
      })
      return {
        ...state,
        selectedStnItemsList: [...SelectedSTNItem, ...stnitems],
        mrqByIdItemsData: mrqItemsList,
        StockItemsModel: false,
      };
    },
    checkTotalIBTQty: (state, action) => {
      const Total_qty = state.StockListByItemId.reduce((partialSum, a) => {
        console.log("a.Transfer_Qty", a.Transfer_Qty);
        return partialSum + parseFloat(a.Transfer_Qty ? a.Transfer_Qty : 0);
      }, 0);
      return {
        ...state,
        loading: false,
        Total_IBT_qty: Total_qty,
      };
    },
    isStockItemsListModelVisible: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          StockItemsModel: action.payload,
        };
      } else {
        return {
          ...state,
          StockItemsModel: action.payload,
          // mrqByIdItemsData: [],
        };
      }
    },

    isSTNSubmitModelVisible: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          STNSubmitModel: action.payload,
          StockItemsData: {},
        };
      } else {
        return {
          ...state,
          STNSubmitModel: action.payload,
        };
      }
    },

    InputChangeValue: (state, action) => {
      return {
        ...state,
        STNData: {
          ...state.STNData,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    clearIBTSTNData: (state) => {
      return {
        ...state,
        loading: false,
        ibtStnData: {},
        STNData: {},
        STNSubmitModel: false,
        StockItemsData: {},
        Total_IBT_qty: 0,
        selectedStnItemsList: [],
        StockListByItemId: [],
        mrqByIdItemsDataParams: {
          start_date: "",
          end_date: "",
          no_of_pages: 0,
          page_size: 10,
          page: 1,
          search: "",
        },
        mrqData: {},
        mrqByIdItemsData: [],
        StockListByItemId: [],
        StockListByItemIdCount: 0,
        STNItem: {},
        STNDocItemsList: [],
        STNDocData: {},
      };
    },
    // Add IBTSTN

    addIBTSTN: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },

    IBTSTNItemsAdd: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    IBTSTNDocAddMake: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },

    isSTNItemsListModelVisible: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          stnItemsListModel: action.payload,
        };
      } else {
        return {
          ...state,
          stnItemsListModel: action.payload,
          // mrqByIdItemsData: [],
        };
      }
    },
    getSTNDocViewData: (state, action) => {
      console.log("ibtStnData", action.payload);
      return {
        ...state,
        loading: true,
        STNDocData: {},
      };
    },
    STNDocViewDataSuccessful: (state, action) => {
      return {
        ...state,
        STNDocData: {
          ...action.payload.response,
          // date:
          //   action.payload.response.date == null
          //     ? ""
          //     : moment(action.payload.response.date),
        },
        loading: false,
      };
    },
    getSTNItemsById: (state, action) => {
      if (state.STNItemsParams?.page == 1) {
        return {
          ...state,
          loading: true,
          STNDocId: action.payload.id,
          STNItemsByIdList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
          STNDocId: action.payload.id,
        };
      }
    },
    STNItemsByIdListSuccessful: (state, action) => {
      var response = action.payload;
      var noofpages = Math.ceil(
        action.payload.count / state.STNItemsParams?.page_size
      );
      return {
        ...state,
        STNItemsByIdList: response.results,
        STNItemslistCount: action.payload.count,
        loading: false,
        STNItemsParams: {
          ...state.STNItemsParams,
          no_of_pages: noofpages,
        },
      };
    },
    setSTNItemsByIdParams: (state, action) => {
      return {
        ...state,
        STNItemsParams: action.payload,
      };
    },

    getDeptBranchData: (state, action) => {
      return {
        ...state,
        loading: false,
        ibtStnData: {
          ...state.ibtStnData,
          branch: action.payload.branch,
          department: action.payload.department,
        },
      };
    },
    SelectChangeValue: (state, action) => {
      return {
        ...state,
        ibtStnData: {
          ...state.ibtStnData,
          [action.payload.key]: {
            id: action.payload.value,
            name: action.payload.name,
          },
        },
      };
    },
    // ************************************************

    // AckItemInputChangeValue: (state, action) => {
    //   var selectedAckItem = state.STNDocItemsList.map((item) => {
    //     if (
    //       item.id == action.payload.item.id &&
    //       item.batch.id == action.payload.item.batch.id
    //     ) {
    //       if (action.payload.key == "accepted_qty") {
    //         if (action.payload.value == "") {
    //           return {
    //             ...item,
    //             [action.payload.key]: action.payload.value,
    //             return_qty: "0",
    //           };
    //         } else {
    //           return {
    //             ...item,
    //             [action.payload.key]: action.payload.value,
    //             return_qty: (
    //               parseFloat(item.qty) - parseFloat(action.payload.value)
    //             ).toFixed(2),
    //           };
    //         }
    //       } else {
    //         return {
    //           ...item,
    //           [action.payload.key]: action.payload.value,
    //         };
    //       }
    //     }
    //     return item;
    //   });
    //   return {
    //     ...state,
    //     STNDocItemsList: selectedAckItem,
    //   };
    // },
    // acceptAllQty: (state, action) => {
    //   var acceptAllAckItem = state.STNDocItemsList.map((item) => {
    //     return {
    //       ...item,
    //       accepted_qty: item.qty,
    //       return_qty: "0",
    //     };
    //   });
    //   return {
    //     ...state,
    //     STNDocItemsList: acceptAllAckItem,
    //   };
    // },
    // stnInputChangeValue: (state, action) => {
    //   switch (action.payload.key) {
    //     case "branch_id":
    //       return {
    //         ...state,
    //         ibtStnData: {
    //           ...state.ibtStnData,
    //           [action.payload.key]: action.payload.value,
    //           department_id: null,
    //           stn_id: null,
    //         },
    //       };
    //     case "department_id":
    //       return {
    //         ...state,
    //         ibtStnData: {
    //           ...state.ibtStnData,
    //           [action.payload.key]: action.payload.value,
    //           stn_id: null,
    //         },
    //       };
    //       break;
    //     default:
    //       return {
    //         ...state,
    //         ibtStnData: {
    //           ...state.ibtStnData,
    //           [action.payload.key]: action.payload.value,
    //         },
    //       };
    //       break;
    //   }
    // },

    // getSTNDocData: (state, action) => {
    //   return {
    //     ...state,
    //     loading: true,
    //     STNDocData: {},
    //     STNDocItemsList: [],
    //     STNDocItemsParams: {
    //       no_of_pages: 0,
    //       page_size: 10,
    //       page: 1,
    //       search: "",
    //     },
    //   };
    // },

    // getSTNDocDataSuccessful: (state, action) => {
    //   return {
    //     ...state,
    //     STNDocData: action.payload.response,
    //     loading: false,
    //     STNDocItemsList: [],
    //     STNDocItemsParams: {
    //       no_of_pages: 0,
    //       page_size: 10,
    //       page: 1,
    //       search: "",
    //     },
    //   };
    // },

    // getSTNDocItems: (state, action) => {
    //   if (state.STNDocItemsParams?.page == 1) {
    //     return {
    //       ...state,
    //       loading: true,
    //       STNDocItemsList: [],
    //     };
    //   } else {
    //     return {
    //       ...state,
    //       loading: true,
    //     };
    //   }
    // },
    // STNDocItemListSuccessful: (state, action) => {
    //   var response = action.payload;
    //   var list = [];
    //   if (state.STNDocItemsParams?.page == 1) {
    //     list = response.results;
    //   } else {
    //     list = [...state.STNDocItemsList, ...response.results];
    //   }
    //   var noofpages = Math.ceil(
    //     action.payload.count / state.STNDocItemsParams?.page_size
    //   );
    //   return {
    //     ...state,
    //     STNDoclistCount: action.payload.count,
    //     STNDocItemsList: list,
    //     loading: false,
    //     STNDocItemsParams: {
    //       ...state.STNDocItemsParams,
    //       no_of_pages: noofpages,
    //     },
    //   };
    // },
    // setSTNDocItemsParams: (state, action) => {
    //   return {
    //     ...state,
    //     STNDocItemsParams: action.payload,
    //   };
    // },

    // setScreenType: (state, action) => {
    //   return {
    //     ...state,
    //     screeType: action.payload,
    //   };
    // },

    FilterInputChangeValue: (state, action) => {
      return {
        ...state,
        ibtStnParams: {
          ...state.ibtStnParams,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    isDrawerVisible: (state, action) => {
      return {
        ...state,
        drawer: action.payload,
      };
    },

    // getStnRecievedList: (state) => {
    //   if (state.StnRecievedParams?.page == 1) {
    //     return {
    //       ...state,
    //       loading: true,
    //       StnRecievedList: [],
    //     };
    //   } else {
    //     return {
    //       ...state,
    //       loading: true,
    //     };
    //   }
    // },
    // stnRecievedListSuccessful: (state, action) => {
    //   var response = action.payload;
    //   var list = [];
    //   if (state.StnRecievedParams?.page == 1) {
    //     list = response.results;
    //   } else {
    //     list = [...state.StnRecievedList, ...response.results];
    //   }
    //   var noofpages = Math.ceil(
    //     action.payload.count / state.StnRecievedParams?.page_size
    //   );
    //   return {
    //     ...state,
    //     StnRecievedList: list,
    //     loading: false,
    //     StnRecievedParams: {
    //       ...state.StnRecievedParams,
    //       no_of_pages: noofpages,
    //     },
    //   };
    // },
    // setStnRecievedParams: (state, action) => {
    //   return {
    //     ...state,
    //     StnRecievedParams: action.payload,
    //   };
    // },

    // getMrqApprovedList: (state) => {
    //   if (state.MrqApprovedParams?.page == 1) {
    //     return {
    //       ...state,
    //       loading: true,
    //       MrqApprovedList: [],
    //     };
    //   } else {
    //     return {
    //       ...state,
    //       loading: true,
    //     };
    //   }
    // },
    // mrqApprovedListSuccessful: (state, action) => {
    //   var response = action.payload;
    //   var list = [];
    //   if (state.MrqApprovedParams?.page == 1) {
    //     list = response.results;
    //   } else {
    //     list = [...state.MrqApprovedList, ...response.results];
    //   }
    //   var noofpages = Math.ceil(
    //     action.payload.count / state.MrqApprovedParams?.page_size
    //   );
    //   return {
    //     ...state,
    //     MrqApprovedList: list,
    //     loading: false,
    //     MrqApprovedParams: {
    //       ...state.MrqApprovedParams,
    //       no_of_pages: noofpages,
    //     },
    //   };
    // },
    // setMrqApprovedParams: (state, action) => {
    //   return {
    //     ...state,
    //     MrqApprovedParams: action.payload,
    //   };
    // },

    apiError: (state, action) => {
      return {
        ...state,
        loading: false,
        formSubmitLoading: false,
        error_msg: action.payload,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getIBTSTNList,
  IBTSTNListSuccessful,
  setIBTSTNParams,
  getPendingIBTList,
  getUserWarehouseIBT,
  userWarehouseIBTSuccessful,
  setUserWarehouseIBTParams,
  pendingIBTListSuccessful,
  setPendingIBTParams,
  getMrqViewData,
  mrqViewDataSuccessful,
  getMrqByIdItemsData,
  MrqByIdItemsDataSuccessful,
  setMrqByIdItemsParams,
  getStockListByItemId,
  stockListByItemIdSuccessful,
  setStockListByItemIdParams,
  StockItemInputChangeValue,
  checkTotalIBTQty,
  isStockItemsListModelVisible,
  selectedSTNItems,
  isSTNSubmitModelVisible,
  addIBTSTN,
  IBTSTNItemsAdd,
  IBTSTNDocAddMake,
  isSTNItemsListModelVisible,
  getSTNDocViewData,
  STNDocViewDataSuccessful,
  getSTNItemsById,
  STNItemsByIdListSuccessful,
  setSTNItemsByIdParams,
  clearIBTSTNData,
  getDeptBranchData,
  SelectChangeValue,
  //
  InputChangeValue,
  // stnInputChangeValue,
  // getSTNDocData,
  // getSTNDocDataSuccessful,
  // getSTNDocItems,
  // STNDocItemListSuccessful,
  // setSTNDocItemsParams,
  FilterInputChangeValue,
  isDrawerVisible,

  // AckItemInputChangeValue,
  // acceptAllQty,
  // getStnRecievedList,
  // stnRecievedListSuccessful,
  // setStnRecievedParams,
  // getMrqApprovedList,
  // mrqApprovedListSuccessful,
  // setMrqApprovedParams,
  // setScreenType,
  apiError,
} = IBTSTNSlice.actions;

export default IBTSTNSlice.reducer;
