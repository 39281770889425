import { createSlice, current } from "@reduxjs/toolkit";

export const stockSyncSlice = createSlice({
  name: "stockSync",
  initialState: {
    loading: false,
    ModelOpen: false,
    WhareHouseErpData: [],
    count: 0,
    stockSyncData: {},

    stockSyncParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
  },

  reducers: {
    setOpenModal: (state, action) => {
      return {
        ...state,
        ModelOpen: action.payload,
      };
    },

    FocusStock: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },

    FocusStockSucessfully: (state, action) => {
      return {
        ...state,
        loading: action.payload,
        ModelOpen: false,
      };
    },

    getFocusStcokCode: (state, action) => {
      if (state.stockSyncParams?.page == 1) {
        return {
          ...state,
          loading: true,
          WhareHouseErpData: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },

    FocusStockCodeSucessfully: (state, action) => {
      console.log();
      var list = [];
      if (state.stockSyncParams?.page == 1) {
        list = action.payload.results;
      } else {
        list = [...state.WhareHouseErpData, ...action.payload.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.stockSyncParams?.page_size
      );

      return {
        ...state,
        count: action.payload.count,
        WhareHouseErpData: list,
        loading: false,
        stockSyncParams: {
          ...state.stockSyncParams,
          no_of_pages: noofpages,
        },
      };
    },

    InputChangeValue: (state, action) => {
      return {
        ...state,
        stockSyncData: {
          ...state.stockSyncData,
          [action.payload.key]: action.payload.value,
        },
      };
    },

    setPageParms: (state, action) => {
      return {
        ...state,
        stockSyncParams: action.payload,
      };
    },

    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

export const {
  FocusStock,
  FocusStockSucessfully,
  apiError,
  setOpenModal,
  getFocusStcokCode,
  FocusStockCodeSucessfully,
  setPageParms,
  InputChangeValue,
} = stockSyncSlice.actions;

export default stockSyncSlice.reducer;
