import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import moment from "moment";
import {
  apiError,
  checkDraftStockConsumptionSuccessful,
  stockConsumptionListSuccessful,
  addStockConsumptionItem,
  addStockDocSave,
  isStockSubmitModelVisible,
  addStockSubmit,
  getStockConsumptionList,
  clearStockList,
  StockDocSaveSuccessful,
  ItemHistorySuccessful,
  isItemsListModelVisible,
} from "./StockConsumptionSlice";
import {
  getList,
  getParamsList,
  postAdd,
  postEdit,
  postFormData,
  postDelete,
} from "../../../helpers/Helper";
import { Modal } from "antd";
import { select } from "redux-saga/effects";
import { evaluate } from "mathjs";

import {
  RiCheckboxCircleLine,
  RiAlertLine,
  RiInformationLine,
} from "react-icons/ri";
//If user is login then dispatch redux action's are directly from here.

function* CheckDraftStockConsumption({ payload }) {
  try {
    const response = yield call(
      getList,
      "/stockconsumption/draft/warehouse/" + payload.warehouse_id,
      {}
    );
    yield put(checkDraftStockConsumptionSuccessful(response));
    yield put(
      getStockConsumptionList({
        page_size: 10,
        page: 1,
        warehouse_id: payload.warehouse_id,
      })
    );
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}
function* StockConsumptionList({ payload }) {
  var params = {};
  var data = payload;
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "type") {
        params.type = data.type.join(",");
      } else {
        if (data[k] !== "" && k !== "no_of_pages" && k !== "warehouse_id") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/stockconsumption/warehouse/" + payload.warehouse_id,
      params
    );
    yield put(stockConsumptionListSuccessful(response));
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* StockConsumptionAdd({ payload }) {
  try {
    const response = yield call(
      postAdd,
      "/stockconsumption/create/",
      payload.stockObj
    );
    if (response.status === 200) {
      yield put(
        addStockConsumptionItem({
          stc_items: payload.stc_items,
          unique_id: response.data.unique_id,
          stockObj: payload.stockObj,
          history: payload.history,
          draft: payload.draft,
        })
      );
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Unable to added Stock Consumption. Please try again!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* StockConsumptionItemAdd({ payload }) {
  var stockDocItems = payload.stc_items.map((item) => {
    return item;
  });

  try {
    var response = yield call(postAdd, "/stockconsumption/stcitems/add/", {
      unique_id: payload.unique_id,
      stc_items: stockDocItems,
    });
    if (response.status === 200) {
      yield put(
        addStockDocSave({
          stockObj: payload.stockObj,
          unique_id: payload.unique_id,
          history: payload.history,
          do_save: true,
          draft: payload.draft,
        })
      );
    } else {
      yield put(
        addStockDocSave({
          stockObj: payload.stockObj,
          unique_id: payload.unique_id,
          do_save: false,
          draft: payload.draft,
        })
      );
    }
  } catch (error) {
    yield put(
      addStockDocSave({
        stockObj: payload.stockObj,
        unique_id: payload.unique_id,
        do_save: false,
        draft: payload.draft,
      })
    );

    yield put(apiError(error));
  }
}

function* StockDocSaveAdd({ payload }) {
  try {
    const response = yield call(postAdd, "/stockconsumption/save_draft/", {
      unique_id: payload.unique_id,
      do_save: payload.do_save,
    });
    if (response.status === 200) {
      if (payload.do_save) {
        if (!payload.draft) {
          yield put(
            addStockSubmit({
              id: response.data.stockConsumption_id,
              history: payload.history,
              draft: payload.draft,
            })
          );
        } else {
          Modal.success({
            icon: (
              <span className="remix-icon">
                <RiCheckboxCircleLine />
              </span>
            ),
            title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
            content: (
              <div>
                <p className="hp-p1-body hp-text-color-black-80">
                  Your Stock Consumption Save as Draft Successfully.!
                </p>
              </div>
            ),
          });
          yield put(StockDocSaveSuccessful(false));
        }
      } else {
        Modal.success({
          icon: (
            <span className="remix-icon">
              <RiCheckboxCircleLine />
            </span>
          ),
          title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
          content: (
            <div>
              <p className="hp-p1-body hp-text-color-black-80">
                Sorry! Unable to added Stock Consumption. Please try again!
              </p>
            </div>
          ),
        });
      }
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Unable to Update Stock Consumption. Please try again!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

function* StockSubmitAdd({ payload }) {
  try {
    const response = yield call(
      postAdd,
      "/stockconsumption/save_stc/" + payload.id,
      {}
    );
    if (response.status === 200) {
      yield put(clearStockList());
      Modal.success({
        icon: (
          <span className="remix-icon">
            <RiCheckboxCircleLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">success</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Your Stock Consumption Submitted Successfully.!
            </p>
          </div>
        ),
      });
      yield put(isStockSubmitModelVisible(false));
      payload.history.push("/pages/stock-consumption");
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),
        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! Unable to Update Stock Consumption. Please try again!
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}
function* ItemHistoryGet({ payload }) {
  var params = {};
  var data = payload.params;
  for (const k in data) {
    if (Object.hasOwnProperty.call(data, k)) {
      if (k === "type") {
        params.type = data.type.join(",");
      } else {
        if (data[k] !== "" && k !== "no_of_pages") {
          params[k] = data[k];
        }
      }
    }
  }
  try {
    const response = yield call(
      getParamsList,
      "/stockconsumption/warehouse/" +
        payload.URLData.warehouse +
        "/material_item/" +
        payload.URLData.material_item,

      params
    );
    yield put(ItemHistorySuccessful(response));
    if (response.results.length !== 0) {
      yield put(isItemsListModelVisible(true));
    } else {
      Modal.error({
        icon: (
          <span className="remix-icon">
            <RiAlertLine />
          </span>
        ),

        title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
        content: (
          <div>
            <p className="hp-p1-body hp-text-color-black-80">
              Sorry! this item don't have any consumption History
            </p>
          </div>
        ),
      });
    }
  } catch (error) {
    Modal.error({
      icon: (
        <span className="remix-icon">
          <RiAlertLine />
        </span>
      ),
      title: <h5 className="hp-mb-0 hp-font-weight-500">Error</h5>,
      content: (
        <div>
          <p className="hp-p1-body hp-text-color-black-80">{error}</p>
        </div>
      ),
    });
    yield put(apiError(error));
  }
}

export function* watchCheckStockConsumptionDraft() {
  yield takeEvery(
    "stockConsumption/checkStockConsumptionDraft",
    CheckDraftStockConsumption
  );
}
export function* watchGetStockConsumptionList() {
  yield takeEvery(
    "stockConsumption/getStockConsumptionList",
    StockConsumptionList
  );
}
export function* watchAddStockConsumption() {
  yield takeEvery("stockConsumption/addStockConsumption", StockConsumptionAdd);
}
export function* watchAddStockConsumptionItem() {
  yield takeEvery(
    "stockConsumption/addStockConsumptionItem",
    StockConsumptionItemAdd
  );
}
export function* watchAddStockDocSave() {
  yield takeEvery("stockConsumption/addStockDocSave", StockDocSaveAdd);
}
export function* watchAddStockSubmit() {
  yield takeEvery("stockConsumption/addStockSubmit", StockSubmitAdd);
}
export function* watchGetItemHistory() {
  yield takeEvery("stockConsumption/GetItemHistory", ItemHistoryGet);
}
function* StockConsumptionSaga() {
  yield all([
    fork(watchCheckStockConsumptionDraft),
    fork(watchGetStockConsumptionList),
    fork(watchAddStockConsumption),
    fork(watchAddStockConsumptionItem),
    fork(watchAddStockDocSave),
    fork(watchAddStockSubmit),
    fork(watchGetItemHistory),
  ]);
}

export default StockConsumptionSaga;
