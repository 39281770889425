import { lazy } from "react";

const PagesRoutes = [
  {
    path: "/mobilelogin",
    component: lazy(() =>
      import("../../view/pages/authentication/login/mobilenumber-login")
    ),
    layout: "FullLayout",
  },
  {
    path: "/login",
    component: lazy(() =>
      import("../../view/pages/authentication/login/username-login")
    ),
    layout: "FullLayout",
  },
  // Master pages
  {
    path: "/Master",
    component: lazy(() => import("../../view/pages/MastersDashboard")),
    layout: "VerticalLayout",
  },

  {
    path: "/Masters/country",
    component: lazy(() => import("../../view/pages/MastersDashboard/country")),
    layout: "VerticalLayout",
  },

  {
    path: "/pages/settings/",
    component: lazy(() => import("../../view/pages/settings")),
    layout: "VerticalLayout",
  },

  // *************** SideBar Pages ****************//

  //Material request
  {
    path: "/pages/mrq",
    component: lazy(() =>
      import("../../view/pages/SideMenu/materialRequestQuote")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/MRQ-Add/:id",
    component: lazy(() =>
      import("../../view/pages/SideMenu/materialRequestQuote/MRQAdd")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/MRQ-view/:id",
    component: lazy(() =>
      import("../../view/pages/SideMenu/materialRequestQuote/MRQView")
    ),
    layout: "VerticalLayout",
  },
  // Material Request Approve
  {
    path: "/pages/mrq-approve",
    component: lazy(() => import("../../view/pages/SideMenu/mrqApproval")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/MRQ-Approve-view/:id",
    component: lazy(() =>
      import("../../view/pages/SideMenu/mrqApproval/MRQApproveView")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/MRQ-Print/:id",
    component: lazy(() =>
      import("../../view/pages/SideMenu/materialRequestQuote/MRQPrint")
    ),
    layout: "VerticalLayout",
  },

  //Acknowledgement
  {
    path: "/pages/Acknowledgement",
    component: lazy(() => import("../../view/pages/SideMenu/Acknowledgement")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/Acknowledgement-view/:id",
    component: lazy(() =>
      import("../../view/pages/SideMenu/Acknowledgement/acknowledgementView")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/Acknowledgement-add/:id",
    component: lazy(() =>
      import("../../view/pages/SideMenu/Acknowledgement/AcknowledgementAdd")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/AcknowledgementPint/:id",
    component: lazy(() =>
      import("../../view/pages/SideMenu/Acknowledgement/acknowledgementPrint")
    ),
    layout: "VerticalLayout",
  },
  //ResourceConsumption
  {
    path: "/pages/resource-consumption",
    component: lazy(() =>
      import("../../view/pages/SideMenu/ResourceConsumption")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/resource-add/:id",
    component: lazy(() =>
      import(
        "../../view/pages/SideMenu/ResourceConsumption/ResourceConsumptionAdd"
      )
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/resource-view/:id",
    component: lazy(() =>
      import(
        "../../view/pages/SideMenu/ResourceConsumption/ResourceConsumptionView"
      )
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/resource-employeeList",
    component: lazy(() =>
      import("../../view/pages/SideMenu/RCEmployee/RCEmployeeList")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/resource-consumption-l1",
    component: lazy(() =>
      import(
        "../../view/pages/SideMenu/ResourceConsumption_L1/ResourceConsumptionLevel1"
      )
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/resource-consumption-l2",
    component: lazy(() =>
      import(
        "../../view/pages/SideMenu/ResourceConsumption_L2/ResourceConsumptionLevel2"
      )
    ),
    layout: "VerticalLayout",
  },
  // Stock Consumption
  {
    path: "/pages/stock-consumption",
    component: lazy(() =>
      import("../../view/pages/SideMenu/stockConsumption/StockConsumptionAdd")
    ),
    layout: "VerticalLayout",
  },

  // Internal Branch Transfer
  {
    path: "/pages/ibt",
    component: lazy(() => import("../../view/pages/SideMenu/IBTRequest")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/ibt-in",
    component: lazy(() => import("../../view/pages/SideMenu/IBTRequestIn")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/ibt-add/:id",
    component: lazy(() =>
      import("../../view/pages/SideMenu/IBTRequest/IBTAdd")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/ibt-view/:id",
    component: lazy(() =>
      import("../../view/pages/SideMenu/IBTRequest/IBTView")
    ),
    layout: "VerticalLayout",
  },

  // Internal Branch Transfer Approve
  {
    path: "/pages/ibt-approve",
    component: lazy(() => import("../../view/pages/SideMenu/IBTApprove")),
    layout: "VerticalLayout",
  },

  {
    path: "/pages/ibt-approve-view/:id",
    component: lazy(() =>
      import("../../view/pages/SideMenu/IBTApprove/IBTApproveView")
    ),
    layout: "VerticalLayout",
  },
  // Internal Branch Transfer STN
  {
    path: "/pages/ibt-stn",
    component: lazy(() => import("../../view/pages/SideMenu/IBTSTN/")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/ibt-stn-add/:id",
    component: lazy(() => import("../../view/pages/SideMenu/IBTSTN/IBTSTNAdd")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/ibt-stn-view/:id",
    component: lazy(() =>
      import("../../view/pages/SideMenu/IBTSTN/IBTSTNView")
    ),
    layout: "VerticalLayout",
  },

  // Petty Cash

  {
    path: "/pages/petty-cash",
    component: lazy(() => import("../../view/pages/SideMenu/PettyCash")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/pettyCashAdd/:id",
    component: lazy(() =>
      import("../../view/pages/SideMenu/PettyCash/addPettyCash")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/utility-billing",
    component: lazy(() => import("../../view/pages/SideMenu/UtilityBilling")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/utilityBillingAdd/:id",
    component: lazy(() =>
      import("../../view/pages/SideMenu/UtilityBilling/addUtilityBilling")
    ),
    layout: "VerticalLayout",
  },
  // errors
  {
    path: "/pages/error-page-404",
    component: lazy(() => import("../../view/pages/error/404/index")),
    layout: "FullLayout",
  },
  {
    path: "/pages/error-page-403",
    component: lazy(() => import("../../view/pages/error/403/index")),
    layout: "FullLayout",
  },
  {
    path: "/pages/error-page-500",
    component: lazy(() => import("../../view/pages/error/500/index")),
    layout: "FullLayout",
  },
  {
    path: "/pages/error-page-502",
    component: lazy(() => import("../../view/pages/error/502/index")),
    layout: "FullLayout",
  },
  {
    path: "/pages/error-page-503",
    component: lazy(() => import("../../view/pages/error/503/index")),
    layout: "FullLayout",
  },
  {
    path: "/pages/blank-page",
    component: lazy(() => import("../../view/pages/blank")),
    layout: "VerticalLayout",
  },

  // REPORTS
  {
    path: "/pages/reports/",
    component: lazy(() => import("../../view/pages/Reports/index")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/report/MRQReport",
    component: lazy(() =>
      import("../../view/pages/Reports/MaterialRequestQuote/index")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/report/MRQItemsReport",
    component: lazy(() =>
      import("../../view/pages/Reports/MaterialRequestQuoteItem/index")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/report/AckReport",
    component: lazy(() =>
      import("../../view/pages/Reports/Acknowledgement/index")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/report/AckItemReport",
    component: lazy(() =>
      import("../../view/pages/Reports/AcknowledgementItems/index")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/report/STNReport",
    component: lazy(() =>
      import("../../view/pages/Reports/StockTransferNote/index")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/report/STNItemReport",
    component: lazy(() =>
      import("../../view/pages/Reports/StockTransferNoteItem/index")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/report/SCReport",
    component: lazy(() =>
      import("../../view/pages/Reports/StockConsumption/index")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/report/SCItemsReport",
    component: lazy(() =>
      import("../../view/pages/Reports/StockConsumptionItem/index")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/report/WareHouseStockReport",
    component: lazy(() =>
      import("../../view/pages/Reports/WareHouseStock/index")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/report/IBTReport",
    component: lazy(() => import("../../view/pages/Reports/IBT/index")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/report/IBTItemsReport",
    component: lazy(() => import("../../view/pages/Reports/IBTItem/index")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/report/ACKPendingReport",
    component: lazy(() => import("../../view/pages/Reports/AckPending/index")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/report/pettyCashItemReport",
    component: lazy(() =>
      import("../../view/pages/Reports/PettyCashItem/index")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/report/pettyCashReport",
    component: lazy(() => import("../../view/pages/Reports/PettyCash/index")),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/report/utilityBillingReport",
    component: lazy(() =>
      import("../../view/pages/Reports/UtilityBilling/index")
    ),
    layout: "VerticalLayout",
  },
  {
    path: "/pages/pettyCash-Print/:id",
    component: lazy(() =>
      import("../../view/pages/SideMenu/PettyCash/pettyCashPrint")
    ),
    layout: "VerticalLayout",
  },
];

export default PagesRoutes;
