import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

export const AcknowledgementSlice = createSlice({
  name: "acknowledgement",
  initialState: {
    loginError: "aaa",
    message: null,
    loading: false,
    formSubmitLoading: false,
    apiError: {},
    listCount: 0,
    STNDoclistCount: 0,
    drawer: false,
    acknowledgmentList: [],
    STNPendingList: [],
    STNDocItemsList: [],
    STNDocItemsLoading: false,
    AckItemsByIdList: [],
    StnRecievedList: [],
    MrqApprovedList: [],
    userWarehouseAckList: [],
    screeType: "",
    STNDocData: {},
    stnData: {},
    ackData: {},
    ackItemslistCount: 0,
    ackDocId: 0,
    AckItemsListModel: false,
    STNDocItemsParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    stnParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    acknowledgmentParams: {
      start_date: "",
      end_date: "",
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    AckItemsParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    StnRecievedParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    MrqApprovedParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    userWarehouseAckParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    mrqDrawer: false,
    ModelType: 1,
  },
  reducers: {
    getAcknowledgmentList: (state, action) => {
      if (state.acknowledgmentParams?.page == 1) {
        return {
          ...state,
          loading: true,
          acknowledgmentList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    acknowledgmentListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count / state.acknowledgmentParams.page_size
      );
      return {
        ...state,
        listCount: action.payload.response.count,
        // acknowledgmentList: [
        //   ...state.acknowledgmentList,
        //   ...action.payload.response.results,
        // ],
        loading: false,
        acknowledgmentList: action.payload.response.results,
        acknowledgmentParams: {
          ...state.acknowledgmentParams,
          no_of_pages: noofpages,
        },
      };
    },
    setAcknowledgmentParams: (state, action) => {
      return {
        ...state,
        acknowledgmentParams: action.payload,
      };
    },
    clearAckData: (state) => {
      return {
        ...state,
        loading: true,
        stnData: {},
        ackData: {},
        STNDocItemsList: [],
        STNDocData: {},
      };
    },
    getStnList: (state) => {
      if (state.stnParams?.page == 1) {
        return {
          ...state,
          loading: true,
          STNPendingList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    stnListSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.stnParams?.page == 1) {
        list = response?.results;
      } else {
        list = [...state.STNPendingList, ...response.results];
      }
      var noofpages = Math.ceil(
        action.payload?.count / state.stnParams?.page_size
      );
      return {
        ...state,
        STNPendingList: list,
        loading: false,
        stnParams: {
          ...state.stnParams,
          no_of_pages: noofpages,
        },
      };
    },
    setStnParams: (state, action) => {
      return {
        ...state,
        stnParams: action.payload,
      };
    },
    getDeptBranchData: (state, action) => {
      return {
        ...state,
        loading: false,
        stnData: {
          ...state.stnData,
          branch: action.payload.branch,
          department: action.payload.department,
        },
      };
    },

    InputChangeValue: (state, action) => {
      return {
        ...state,
        ackData: {
          ...state.ackData,
          [action.payload.key]: action.payload.value,
        },
      };
    },

    AckItemInputChangeValue: (state, action) => {
      var selectedAckItem = state.STNDocItemsList.map((item) => {
        if (
          item.id == action.payload.item.id &&
          item.batch.id == action.payload.item.batch.id
        ) {
          if (action.payload.key == "accepted_qty") {
            if (action.payload.value == "") {
              return {
                ...item,
                [action.payload.key]: action.payload.value,
                return_qty: "0",
              };
            } else {
              return {
                ...item,
                [action.payload.key]: action.payload.value,
                return_qty: (
                  parseFloat(item.qty) - parseFloat(action.payload.value)
                ).toFixed(2),
              };
            }
          } else {
            return {
              ...item,
              [action.payload.key]: action.payload.value,
            };
          }
        }
        return item;
      });
      return {
        ...state,
        STNDocItemsList: selectedAckItem,
      };
    },
    acceptAllQty: (state, action) => {
      var acceptAllAckItem = state.STNDocItemsList.map((item) => {
        return {
          ...item,
          accepted_qty: item.qty,
          return_qty: "0",
        };
      });
      return {
        ...state,
        STNDocItemsList: acceptAllAckItem,
      };
    },
    stnInputChangeValue: (state, action) => {
      switch (action.payload.key) {
        case "branch_id":
          return {
            ...state,
            stnData: {
              ...state.stnData,
              [action.payload.key]: action.payload.value,
              department_id: null,
              stn_id: null,
            },
          };
        case "department_id":
          return {
            ...state,
            stnData: {
              ...state.stnData,
              [action.payload.key]: action.payload.value,
              stn_id: null,
            },
          };
          break;
        default:
          return {
            ...state,
            stnData: {
              ...state.stnData,
              [action.payload.key]: action.payload.value,
            },
          };
          break;
      }
    },

    SelectChangeValue: (state, action) => {
      if (action.payload.key == "AckType") {
        return {
          ...state,
          stnData: {
            [action.payload.key]: {
              id: action.payload.value,
              name: action.payload.name,
            },
          },
          STNDocData: {},
        };
      } else if (action.payload.key == "warehouse") {
        return {
          ...state,
          stnData: {
            ...state.stnData,
            [action.payload.key]: {
              id: action.payload.value,
              name: action.payload.name,
            },
          },
        };
      } else {
        return {
          ...state,
          stnData: {
            ...state.stnData,
            stn: {},
            [action.payload.key]: {
              id: action.payload.value,
              name: action.payload.name,
            },
          },
        };
      }
    },
    getSTNDocData: (state, action) => {
      return {
        ...state,
        STNDocItemsLoading: true,
        STNDocData: {},
        STNDocItemsList: [],
        STNDocItemsParams: {
          no_of_pages: 0,
          page_size: 10,
          page: 1,
          search: "",
        },
      };
    },

    getSTNDocDataSuccessful: (state, action) => {
      return {
        ...state,
        STNDocData: action.payload.response,
        STNDocItemsLoading: false,
        STNDocItemsList: [],
        STNDocItemsParams: {
          no_of_pages: 0,
          page_size: 10,
          page: 1,
          search: "",
        },
      };
    },

    getSTNDocItems: (state, action) => {
      if (state.STNDocItemsParams?.page == 1) {
        return {
          ...state,
          loading: true,
          STNDocItemsList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    STNDocItemListSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.STNDocItemsParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.STNDocItemsList, ...response.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.STNDocItemsParams?.page_size
      );
      return {
        ...state,
        STNDoclistCount: action.payload.count,
        STNDocItemsList: list.map(e => {
          return {
            ...e,
            accepted_qty: e.qty,
            return_qty: 0
          }
        }),
        loading: false,
        STNDocItemsParams: {
          ...state.STNDocItemsParams,
          no_of_pages: noofpages,
        },
      };
    },
    setSTNDocItemsParams: (state, action) => {
      return {
        ...state,
        STNDocItemsParams: action.payload,
      };
    },
    isMRQDrawerVisible: (state, action) => {
      return {
        ...state,
        mrqDrawer: action.payload.visible,
        ModelType: action.payload.type,
      };
    },
    getAckDOcViewData: (state, action) => {
      console.log("stnData", action.payload);
      return {
        ...state,
        loading: true,
        stnData: {},
      };
    },
    AckDOcViewDataSuccessful: (state, action) => {
      return {
        ...state,
        stnData: {
          ...action.payload.response,
          // date:
          //   action.payload.response.date == null
          //     ? ""
          //     : moment(action.payload.response.date),
        },
        loading: false,
      };
    },
    getAckItemsById: (state, action) => {
      if (state.AckItemsParams?.page == 1) {
        return {
          ...state,
          loading: true,
          ackDocId: action.payload.id,
          AckItemsByIdList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
          ackDocId: action.payload.id,
        };
      }
    },
    AckItemsByIdListSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.AckItemsParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.AckItemsByIdList, ...response.results];
      }
      var noofpages = Math.ceil(
        response.count / state.AckItemsParams?.page_size
      );
      return {
        ...state,
        AckItemsByIdList: state.screeType == "view" ? response.results : list,
        ackItemslistCount: response.count,
        loading: false,
        AckItemsParams: {
          ...state.AckItemsParams,
          no_of_pages: noofpages,
        },
      };
    },
    setAckItemsByIdParams: (state, action) => {
      return {
        ...state,
        AckItemsParams: action.payload,
      };
    },
    setScreenType: (state, action) => {
      return {
        ...state,
        screeType: action.payload,
      };
    },

    // Add ACK

    addAck: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },

    ACKItemsAdd: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    ACKDocAddMake: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    FilterInputChangeValue: (state, action) => {
      return {
        ...state,
        acknowledgmentParams: {
          ...state.acknowledgmentParams,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    isDrawerVisible: (state, action) => {
      return {
        ...state,
        drawer: action.payload,
      };
    },
    isAckItemsListModelVisible: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          AckItemsListModel: action.payload,
        };
      } else {
        return {
          ...state,
          AckItemsListModel: action.payload,
          // mrqByIdItemsData: [],
        };
      }
    },

    getStnRecievedList: (state) => {
      if (state.StnRecievedParams?.page == 1) {
        return {
          ...state,
          loading: true,
          StnRecievedList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    stnRecievedListSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.StnRecievedParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.StnRecievedList, ...response.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.StnRecievedParams?.page_size
      );
      return {
        ...state,
        StnRecievedList: list,
        loading: false,
        StnRecievedParams: {
          ...state.StnRecievedParams,
          no_of_pages: noofpages,
        },
      };
    },
    setStnRecievedParams: (state, action) => {
      return {
        ...state,
        StnRecievedParams: action.payload,
      };
    },

    getMrqApprovedList: (state) => {
      if (state.MrqApprovedParams?.page == 1) {
        return {
          ...state,
          loading: true,
          MrqApprovedList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    mrqApprovedListSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.MrqApprovedParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.MrqApprovedList, ...response.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.MrqApprovedParams?.page_size
      );
      return {
        ...state,
        MrqApprovedList: list,
        loading: false,
        MrqApprovedParams: {
          ...state.MrqApprovedParams,
          no_of_pages: noofpages,
        },
      };
    },
    setMrqApprovedParams: (state, action) => {
      return {
        ...state,
        MrqApprovedParams: action.payload,
      };
    },

    getUserWarehouseAck: (state) => {
      if (state.userWarehouseAckParams?.page == 1) {
        return {
          ...state,
          loading: true,
          userWarehouseAckList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    userWarehouseAckSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.userWarehouseAckParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.userWarehouseAckList, ...response.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.userWarehouseAckParams?.page_size
      );
      if (list.length == 1) {
        return {
          ...state,
          userWarehouseAckList: list,
          stnData: {
            ...state.stnData,
            warehouse: { id: list[0].id, name: list[0].name },
            branch: list[0].branch,
            department: list[0].department,
          },
          loading: false,
        };
      }
      return {
        ...state,
        userWarehouseAckList: list,
        loading: false,
        userWarehouseAckParams: {
          ...state.userWarehouseAckParams,
          no_of_pages: noofpages,
        },
      };
    },
    setUserWarehouseAckParams: (state, action) => {
      return {
        ...state,
        userWarehouseAckParams: action.payload,
      };
    },
    fsyncStatusUpdate: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    apiError: (state, action) => {
      return {
        ...state,
        loading: false,
        formSubmitLoading: false,
        error_msg: action.payload,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getAcknowledgmentList,
  acknowledgmentListSuccessful,
  setAcknowledgmentParams,
  getStnList,
  stnListSuccessful,
  setStnParams,
  getDeptBranchData,
  InputChangeValue,
  stnInputChangeValue,
  SelectChangeValue,
  getSTNDocData,
  getSTNDocDataSuccessful,
  getSTNDocItems,
  STNDocItemListSuccessful,
  setSTNDocItemsParams,
  isMRQDrawerVisible,
  FilterInputChangeValue,
  isDrawerVisible,
  getAckDOcViewData,
  AckDOcViewDataSuccessful,
  getAckItemsById,
  AckItemsByIdListSuccessful,
  setAckItemsByIdParams,
  addAck,
  ACKItemsAdd,
  ACKDocAddMake,
  AckItemInputChangeValue,
  acceptAllQty,
  clearAckData,
  isAckItemsListModelVisible,
  getStnRecievedList,
  stnRecievedListSuccessful,
  setStnRecievedParams,
  getMrqApprovedList,
  mrqApprovedListSuccessful,
  setMrqApprovedParams,
  getUserWarehouseAck,
  userWarehouseAckSuccessful,
  setUserWarehouseAckParams,
  setScreenType,
  fsyncStatusUpdate,
  apiError,
} = AcknowledgementSlice.actions;

export default AcknowledgementSlice.reducer;
