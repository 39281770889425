import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

export const importExportSlice = createSlice({
  name: "importExport",
  initialState: {
    exportModelList: [],
    importModelList: [],
    importType: 0,
    exportData: {},
    importData: {},
    importTableColumns: [],
    importValidRowData: [],
    importTableRowData: [],
    submit_ImportData: {},
    currentStep: 0,
    model: false,
    managesParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
  },
  reducers: {
    getImportModelsList: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    ImportModelsListSuccessful: (state, action) => {
      return {
        ...state,
        importModelList: action.payload,
        loading: false,
      };
    },
    clearData: (state) => {
      return {
        ...state,
        loading: false,
        exportModelList: [],
        importModelList: [],
        importType: 0,
        exportData: {},
        importData: {},
      };
    },
    getExportModelsList: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    ExportModelsListSuccessful: (state, action) => {
      return {
        ...state,
        exportModelList: action.payload,
        loading: false,
      };
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        exportData: {
          ...state.exportData,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    uploadImportData: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    uploadImportDataSuccessful: (state, action) => {
      var columns = [];
      if (!action.payload.has_errors) {
        if (!action.payload.has_validation_errors) {
          if (action.payload.valid_rows?.length > 0) {
            columns = Object.keys(action.payload.valid_rows[0]);
          }
        } else {
          if (action.payload.invalid_rows?.length > 0) {
            columns = Object.keys(action.payload.invalid_rows[0]);
          }
        }
      }
      return {
        ...state,
        currentStep: 1,
        loading: false,
        importType: 1,
        importData: action.payload,
        importTableColumns: columns,
        importTableRowData:
          !action.payload.has_errors && action.payload.has_validation_errors
            ? action.payload?.invalid_rows
            : action.payload?.valid_rows,
      };
    },
    uploadImportStatus: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    delayImport: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    progressImportStatus: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    ImportProgessDelay: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    uploadImportType2Data: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    uploadImportType2DataSuccessful: (state, action) => {
      var columns = [];
      var columerformater = function (
        fields,
        columns = [],
        label = "",
        key = ""
      ) {
        for (let i = 0; i < fields.length; i++) {
          const field = fields[i];

          if (
            Object.keys(field).indexOf("is_serializer") !== -1 &&
            field.is_serializer
          ) {
            columns = columerformater(
              field.serializer_fields,
              columns,
              field.label + " ",
              field.key + "__"
            );
          } else {
            field.key = key + field.key;
            // field.label = label + field.label;
            columns.push(field);
          }
          if (i == fields.length - 1) {
            return columns;
          }
        }
      };

      columns = columerformater(action.payload.import_fields);
      columns.unshift(
        { key: "import_status", label: "Import Status" },
        { key: "errors", label: "errors" }
      );
      return {
        ...state,
        currentStep: 1,
        loading: false,
        importType: 2,
        importData: action.payload,
        importTableColumns: columns,
        importTableRowData: action.payload.data_list,
      };
    },
    submitImportData: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    submitImportType2Data: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    submitImportDataSuccessful: (state, action) => {
      return {
        ...state,
        currentStep: 2,
        loading: false,
        submit_ImportData: action.payload,
      };
    },
    changeType: (state, action) => {
      console.log("changeType", action.payload);
      return {
        ...state,
        importType: action.payload,
      };
    },

    nextStep: (state) => {
      state.currentStep = state.currentStep + 1;
    },
    prevStep: (state) => {
      state.currentStep = state.currentStep - 1;
    },
    finishStep: (state) => {
      return {
        ...state,
        exportData: {},
        importData: {},
        importTableColumns: [],
        importValidRowData: [],
        submit_ImportData: {},
        currentStep: 0,
      };
    },

    downloadExportData: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },

    downloadRequeststatus: (state, action) => {
      return {
        ...state,
        exportData: {
          requestBody: action.payload, // {request_id : resaksdfhn}
        },
      };
    },

    exportDownloadSuccess: (state, action) => {
      return {
        ...state,
        loading: false,
        exportData: {
          ...state.exportData,
          data: action.payload,
        },
      };
    },
    loadingStatus: (state, action) => {
      return { ...state, loading: action.payload };
    },
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getImportModelsList,
  ImportModelsListSuccessful,
  getExportModelsList,
  ExportModelsListSuccessful,
  InputChangeValue,
  uploadImportData,
  uploadImportDataSuccessful,
  uploadImportType2Data,
  uploadImportType2DataSuccessful,
  submitImportData,
  submitImportDataSuccessful,
  submitImportType2Data,
  downloadRequeststatus,
  downloadExportData,
  exportDownloadSuccess,
  loadingStatus,
  nextStep,
  prevStep,
  finishStep,
  changeType,
  clearData,
  apiError,
  uploadImportStatus,
  delayImport,
  progressImportStatus,
  ImportProgessDelay,
} = importExportSlice.actions;

export default importExportSlice.reducer;
