import { createSlice } from "@reduxjs/toolkit";
export const ResourceConsumptionSlice = createSlice({
  name: "resourceConsumption",
  initialState: {
    loginError: "aaa",
    message: null,
    loading: false,
    formSubmitLoading: false,
    apiError: {},
    listCount: 0,
    drawer: false,
    RCEmpDrawer: false,
    resourceConsumptionList: [],
    resourceConsumptionParams: {
      start_date: "",
      end_date: "",
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    workOrderData: {},
    workOrderList: [],
    workOrderParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    currentStep: 0,
    ResourceModel: false,
    selectedRCItem: {},
    selectedRCSubItemsList: [],
    RCItemList: [],
    RCSubItemsList: [],
    RCDocData: {},
    RCDocId: 0,
    RCDocItemsByIdList: [],
    RCDocItemslistCount: 0,
    RCDocItemsParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    RCDocItemId: 0,
    RCDocSubItemsByIdList: [],
    RCDocSubItemslistCount: 0,
    RCDocSubItemsParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    RCSubItemsListModel: false,
    ResourceItemEdit: false,
    rcSubmitModel: false,
    RCDocItemsData: {},
    RCEmployeeListParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    RCEmployeeList: [],
    RCEmployeeDrawer: false,
    RCEmployeeModel: false,
    RCEmployeeData: {},

    RCEmployeeByIDList: [],
    RCEmployeeByIDListParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    RCDataLevel1: {},
    RCL1ApprovalsList: [],
    RCL1ApprovalsListParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    RCL1ItemData: {},
    RCL1ItemModel: false,

    RCDataLevel2: {},
    RCL2ApprovalsList: [],
    RCL2ApprovalsListParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    RCL2EmployeesListCount: 0,
    RCL2EmployeesList: [],
    RCL2EmployeesListParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    RCL2EmployeesListModel: false,


    miniResourceEmployeeList: [],
    miniResourceEmployeeParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
  },
  reducers: {
    clearRCData: (state) => {
      return {
        ...state,
        loading: false,
        workOrderData: {},
        workOrderList: [],
        workOrderParams: {
          no_of_pages: 0,
          page_size: 10,
          page: 1,
          search: "",
        },
        currentStep: 0,
        ResourceModel: false,
        selectedRCItem: {},
        selectedRCSubItemsList: [],
        RCItemList: [],
        RCSubItemsList: [],
        rcSubmitModel: false,
        resourceConsumptionList: [],
        resourceConsumptionParams: {
          start_date: "",
          end_date: "",
          no_of_pages: 0,
          page_size: 10,
          page: 1,
          search: "",
        },
      };
    },
    getResourceConsumptionList: (state, action) => {
      if (state.resourceConsumptionParams?.page == 1) {
        return {
          ...state,
          loading: true,
          resourceConsumptionList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    resourceConsumptionListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count /
          state.resourceConsumptionParams.page_size
      );
      return {
        ...state,
        listCount: action.payload.response.count,
        loading: false,
        resourceConsumptionList: action.payload.response.results,
        resourceConsumptionParams: {
          ...state.resourceConsumptionParams,
          no_of_pages: noofpages,
        },
      };
    },
    setResourceConsumptionParams: (state, action) => {
      return {
        ...state,
        resourceConsumptionParams: action.payload,
      };
    },
    InputChangeValue: (state, action) => {
      return {
        ...state,
        workOrderData: {
          ...state.workOrderData,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    isDrawerVisible: (state, action) => {
      return {
        ...state,
        drawer: action.payload,
      };
    },
    FilterInputChangeValue: (state, action) => {
      return {
        ...state,
        resourceConsumptionParams: {
          ...state.resourceConsumptionParams,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    isRCItemModelVisible: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          ResourceModel: action.payload,
        };
      } else {
        return {
          ...state,
          ResourceModel: action.payload,
          // materialItemList: [],
          // materialItemParams: {
          //   start_date: "",
          //   end_date: "",
          //   no_of_pages: 0,
          //   page_size: 20,
          //   page: 1,
          //   search: "",
          // },
        };
      }
    },
    ResourceDocvalidate: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    getWorkOrderData: (state, action) => {
      if (state.workOrderParams.page == 1) {
        return {
          ...state,
          loading: true,
          workOrderList: [],
          workOrderParams: {
            no_of_pages: 0,
            page_size: 10,
            page: 1,
            search: "",
          },
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },

    WorkOrderDataSuccessful: (state, action) => {
      var rcItemsList = [];
      var selectedRCItemList_ids = state.RCItemList.map((i) => {
        return i.id;
      });
      rcItemsList = action.payload.results.filter((item) => {
        return selectedRCItemList_ids.indexOf(item.id) == -1;
      });
      var list = [];
      if (state.workOrderParams?.page == 1) {
        list = rcItemsList;
      } else {
        list = [...state.workOrderList, ...rcItemsList];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.workOrderParams.page_size
      );
      return {
        ...state,
        listCount: action.payload.count,
        loading: false,
        workOrderList: list,
        workOrderParams: {
          ...state.workOrderParams,
          no_of_pages: noofpages,
        },
        ResourceModel: true,
        currentStep: 0,
      };
    },
    setWorkOrderDataParams: (state, action) => {
      return {
        ...state,
        workOrderParams: action.payload,
      };
    },

    selectWorkOrder: (state, action) => {
      return {
        ...state,
        loading: false,
        currentStep: 1,
        selectedRCItem: {
          ...action.payload,
          no_of_items: 0,
          consumption_qty: 0,
          ot_qty: 0,
        },
        selectedRCSubItemsList: [],
        // workOrderData: {},
        // workOrderList: [],
        // workOrderParams: {
        //   no_of_pages: 0,
        //   page_size: 10,
        //   page: 1,
        //   search: "",
        // },
        // ResourceModel: false,
      };
    },
    prevStep: (state, action) => {
      return {
        ...state,
        loading: false,
        currentStep: state.currentStep - 1,
      };
    },

    RCItemInputChangeValue: (state, action) => {
      return {
        ...state,
        selectedRCItem: {
          ...state.selectedRCItem,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    ResourceItemSubmit: (state, action) => {
      var rcSubItemlist = [];
      if (parseInt(action.payload.no_of_items) != 0) {
        for (let i = 0; i < action.payload.no_of_items; i++) {
          rcSubItemlist.push({
            id: i,
            work_order_item_id: action.payload.id,
            resource_consumption_item_id: action.payload.resource_item?.id,
            uom: action.payload.uom,
            resource_employee_id: "",
            consumption_qty: "",
            ot_qty: "",
            remarks: "",
          });
        }
      } else {
        rcSubItemlist = [];
      }

      return {
        ...state,
        loading: false,
        // currentStep: state.currentStep + 1,
        selectedRCSubItemsList: rcSubItemlist,
      };
    },

    RCSubItemInputChangeValue: (state, action) => {
      var selectedRCSubItem = state.selectedRCSubItemsList.map((item) => {
        if (item.id == action.payload.item.id) {
          return {
            ...item,
            [action.payload.key]: action.payload.value,
          };
        }
        return item;
      });
      return {
        ...state,
        selectedRCSubItemsList: selectedRCSubItem,
      };
    },
    checkTotalConsumptionQty: (state) => {
      const Total_consumption_qty = state.selectedRCSubItemsList.reduce(
        (partialSum, a) =>
          partialSum +
          parseFloat(a.consumption_qty == "" ? "0" : a.consumption_qty),
        0
      );
      return {
        ...state,
        loading: false,
        selectedRCItem: {
          ...state.selectedRCItem,
          consumption_qty: Total_consumption_qty,
        },
      };
    },
    checkTotalOTQty: (state) => {
      const Total_OT_qty = state.selectedRCSubItemsList.reduce(
        (partialSum, a) =>
          partialSum + parseFloat(a.ot_qty == "" ? "0" : a.ot_qty),
        0
      );
      return {
        ...state,
        loading: false,
        selectedRCItem: {
          ...state.selectedRCItem,
          ot_qty: Total_OT_qty,
        },
      };
    },
    ResourceSubItemSubmit: (state, action) => {
      var SubmitRCItem = state.RCItemList.filter((item) => {
        return (
          item.resource_item.id !=
          action.payload.selectedRCItem.resource_item.id
        );
      });
      var submitRCSubItem = state.RCSubItemsList.filter((item) => {
        return (
          item.resource_consumption_item_id !=
          action.payload.selectedRCItem.resource_item.id
        );
      });
      return {
        ...state,
        loading: false,
        RCItemList: [...SubmitRCItem, action.payload.selectedRCItem],
        RCSubItemsList: [
          ...submitRCSubItem,
          ...action.payload.selectedRCSubItemsList,
        ],
        ResourceModel: false,
        currentStep: 0,
        ResourceItemEdit: false,
      };
    },
    AddResourceConsumption: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    ResourceConsumptionItemsAdd: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    ResourceConsumptionSubItemsAdd: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    RCDocAddMake: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    EditResourceItem: (state, action) => {
      var rcSubItem = state.RCSubItemsList.filter((item) => {
        return (
          item.resource_consumption_item_id == action.payload.resource_item.id
        );
      });
      return {
        ...state,
        loading: false,
        selectedRCItem: action.payload,
        selectedRCSubItemsList: rcSubItem,
        ResourceModel: true,
        currentStep: 1,
        ResourceItemEdit: true,
      };
    },
    RCItemDelect: (state, action) => {
      var SubmitRCItem = state.RCItemList.filter((item) => {
        return item.resource_item.id != action.payload.resource_item.id;
      });
      var submitRCSubItem = state.RCSubItemsList.filter((item) => {
        return (
          item.resource_consumption_item_id != action.payload.resource_item.id
        );
      });
      return {
        ...state,
        loading: false,
        RCItemList: SubmitRCItem,
        RCSubItemsList: submitRCSubItem,
      };
    },
    getRCDocViewData: (state, action) => {
      return {
        ...state,
        loading: true,
        RCDocData: {},
      };
    },
    RCDocViewDataSuccessful: (state, action) => {
      return {
        ...state,
        RCDocData: {
          ...action.payload.response,
          // date:
          //   action.payload.response.date == null
          //     ? ""
          //     : moment(action.payload.response.date),
        },
        loading: false,
      };
    },
    getRCDocItemsById: (state, action) => {
      if (state.RCDocItemsParams?.page == 1) {
        return {
          ...state,
          loading: true,
          RCDocId: action.payload.id,
          RCDocItemsByIdList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
          RCDocId: action.payload.id,
        };
      }
    },
    RCDocItemsByIdListSuccessful: (state, action) => {
      var response = action.payload;
      // var list = [];
      // if (state.RCDocItemsParams?.page == 1) {
      //   list = response.results;
      // } else {
      //   list = [...state.RCDocItemsByIdList, ...response.results];
      // }
      var noofpages = Math.ceil(
        action.payload.count / state.RCDocItemsParams?.page_size
      );
      return {
        ...state,
        RCDocItemsByIdList: response.results,
        RCDocItemslistCount: action.payload.count,
        loading: false,
        RCDocItemsParams: {
          ...state.RCDocItemsParams,
          no_of_pages: noofpages,
        },
      };
    },
    setRCDocItemsByIdParams: (state, action) => {
      return {
        ...state,
        RCDocItemsParams: action.payload,
      };
    },
    getRCDocSubItemsById: (state, action) => {
      if (state.RCDocSubItemsParams?.page == 1) {
        return {
          ...state,
          loading: true,
          RCDocItemId: action.payload.id,
          RCDocSubItemsByIdList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
          RCDocItemId: action.payload.id,
        };
      }
    },
    RCDocSubItemsByIdListSuccessful: (state, action) => {
      var response = action.payload;
      // var list = [];
      // if (state.RCDocSubItemsParams?.page == 1) {
      //   list = response.results;
      // } else {
      //   list = [...state.RCDocSubItemsByIdList, ...response.results];
      // }
      var noofpages = Math.ceil(
        action.payload.count / state.RCDocSubItemsParams?.page_size
      );
      return {
        ...state,
        RCDocSubItemsByIdList: response.results,
        RCDocSubItemslistCount: action.payload.count,
        loading: false,
        RCSubItemsListModel: true,
        RCDocSubItemsParams: {
          ...state.RCDocSubItemsParams,
          no_of_pages: noofpages,
        },
      };
    },
    setRCDocSubItemsByIdParams: (state, action) => {
      return {
        ...state,
        RCDocSubItemsParams: action.payload,
      };
    },
    isRCSubItemsListModelVisible: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          RCSubItemsListModel: action.payload,
        };
      } else {
        return {
          ...state,
          RCSubItemsListModel: action.payload,
          // mrqByIdItemsData: [],
        };
      }
    },
    isRCSubmitModelVisible: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          rcSubmitModel: action.payload,
          RCDocItemsData: {},
        };
      } else {
        return {
          ...state,
          rcSubmitModel: action.payload,
        };
      }
    },
    RCSubmitInputChangeValue: (state, action) => {
      return {
        ...state,
        RCDocItemsData: {
          ...state.RCDocItemsData,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    /************************* RC Employee Data ********************************* */
    getRCEmployeeList: (state, action) => {
      if (state.RCEmployeeListParams?.page == 1) {
        return {
          ...state,
          loading: true,
          RCEmployeeList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    RCEmployeeListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.response.count / state.RCEmployeeListParams.page_size
      );
      return {
        ...state,
        listCount: action.payload.response.count,
        loading: false,
        RCEmployeeList: action.payload.response.results,
        RCEmployeeListParams: {
          ...state.RCEmployeeListParams,
          no_of_pages: noofpages,
        },
      };
    },
    setRCEmployeeListParams: (state, action) => {
      return {
        ...state,
        RCEmployeeListParams: action.payload,
      };
    },
    RCEmployeeChangeStatus: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },

    isRCEmployeeModelVisible: (state, action) => {
      if (action.payload) {
        return {
          ...state,

          RCEmployeeModel: action.payload,
          loading: false,
        };
      } else {
        return {
          ...state,
          RCEmployeeModel: action.payload,
          RCEmployeeData: {},
          loading: false,
        };
      }
    },
    addRCEmployeeDrawer: (state, action) => {
      if (action.payload) {
        return {
          ...state,

          RCEmployeeDrawer: action.payload,
          loading: false,
        };
      } else {
        return {
          ...state,
          RCEmployeeDrawer: action.payload,
          RCEmployeeData: {},
          loading: false,
        };
      }
    },
    RCEmployeeInputChangeValue: (state, action) => {
      return {
        ...state,
        RCEmployeeData: {
          ...state.RCEmployeeData,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    RCEmployeeSubmit: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    isRCEmpDrawerVisible: (state, action) => {
      return {
        ...state,
        RCEmpDrawer: action.payload,
      };
    },
    RCEmpFilterInputChangeValue: (state, action) => {
      return {
        ...state,
        RCEmployeeListParams: {
          ...state.RCEmployeeListParams,
          [action.payload.key]: action.payload.value,
        },
      };
    },

    //************************** Leave 1 *************************** */
    clearRCL1Data: (state) => {
      return {
        ...state,
        loading: false,
        RCDataLevel1: {},
        RCL1ApprovalsList: [],
        RCL1ApprovalsListParams: {
          no_of_pages: 0,
          page_size: 10,
          page: 1,
          search: "",
        },
        RCL1ItemData: {},
        RCL1ItemModel: false,
      };
    },
    getRCEmployeeByIDList: (state, action) => {
      if (state.RCEmployeeListParams?.page == 1) {
        return {
          ...state,
          loading: true,
          RCEmployeeByIDList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    RCEmployeeByIDListSuccessful: (state, action) => {
      var noofpages = Math.ceil(
        action.payload.count / state.RCEmployeeListParams.page_size
      );
      return {
        ...state,
        listCount: action.payload.count,
        loading: false,
        RCEmployeeByIDList: action.payload.results,
        RCEmployeeByIDListParams: {
          ...state.RCEmployeeByIDListParams,
          no_of_pages: noofpages,
        },
      };
    },
    setRCEmployeeByIDListParams: (state, action) => {
      return {
        ...state,
        RCEmployeeByIDListParams: action.payload,
      };
    },
    RCEmployeeByIDModelVisible: (state, action) => {
      return {
        ...state,
        loading: false,
        isRCEmployeeByIDModel: action.payload,
      };
    },
    l1InputChangeValue: (state, action) => {
      return {
        ...state,
        RCDataLevel1: {
          ...state.RCDataLevel1,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    getRCL1ApprovalsList: (state, action) => {
      if (state.RCL1ApprovalsListParams?.page == 1) {
        return {
          ...state,
          loading: true,
          RCL1ApprovalsList: [],
          RCL1ApprovalsListParams: {
            no_of_pages: 0,
            page_size: 10,
            page: 1,
            search: "",
          },
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },

    RCL1ApprovalsListSuccessful: (state, action) => {
      var rcItemsList = [];
      var rcItemsList = action.payload.results.map((i) => {
        return { ...i, update_ot_qty: i.ot_qty };
      });

      console.log("rcItemsList", rcItemsList);
      var list = [];
      if (state.RCL1ApprovalsListParams?.page == 1) {
        list = rcItemsList;
      } else {
        list = [...state.RCL1ApprovalsList, ...rcItemsList];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.RCL1ApprovalsListParams.page_size
      );
      return {
        ...state,
        listCount: action.payload.count,
        loading: false,
        RCL1ApprovalsList: list,
        RCL1ApprovalsListParams: {
          ...state.RCL1ApprovalsListParams,
          no_of_pages: noofpages,
        },
        // ResourceModel: true,
        // currentStep: 0,
      };
    },
    setRCL1ApprovalsListParams: (state, action) => {
      return {
        ...state,
        RCL1ApprovalsListParams: action.payload,
      };
    },
    RCL1SubItemUpdateValue: (state, action) => {
      console.log("RCL1SubItemInputChangeValue", action.payload);
      var selectedRCL1SubItem = state.RCL1ApprovalsList.map((item) => {
        if (item.id == action.payload.id) {
          return {
            ...action.payload,
          };
        }
        return item;
      });
      return {
        ...state,
        RCL1ApprovalsList: selectedRCL1SubItem,
        loading: false,
        RCL1ItemModel: false,
      };
    },
    RCL1SubItemInputChangeValue: (state, action) => {
      return {
        ...state,
        loading: false,
        RCL1ItemData: {
          ...state.RCL1ItemData,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    RCL1ItemModelVisible: (state, action) => {
      return {
        ...state,
        loading: false,
        RCL1ItemModel: action.payload,
      };
    },
    EditRCL1Item: (state, action) => {
      return {
        ...state,
        loading: false,
        RCL1ItemData: action.payload,
        RCL1ItemModel: true,
      };
    },
    RCSubItemL1Check: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    RCSubItemL1CheckSuccessful: (state, action) => {
      console.log("RCSubItemL1CheckSuccessful", action.payload);
      var selectedRCL1SubItem = state.RCL1ApprovalsList.map((item) => {
        if (item.id == action.payload.item.id) {
          return {
            ...item,
            l1_checked_status: action.payload.obj?.check_status,
            ot_qty: action.payload.obj?.ot_qty,
          };
        }
        return item;
      });
      return {
        ...state,
        RCL1ApprovalsList: selectedRCL1SubItem,
        loading: false,
      };
    },
    RCApproveLeave1: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    //************************** Leave 2 *************************** */
    clearRCL2Data: (state) => {
      return {
        ...state,
        loading: false,
        RCDataLevel2: {},
        RCL2ApprovalsList: [],
        RCL2ApprovalsListParams: {
          no_of_pages: 0,
          page_size: 10,
          page: 1,
          search: "",
        },
        RCL2EmployeesListCount: 0,
        RCL2EmployeesList: [],
        RCL2EmployeesListParams: {
          no_of_pages: 0,
          page_size: 10,
          page: 1,
          search: "",
        },
        RCL2EmployeesListModel: false,
      };
    },
    l2InputChangeValue: (state, action) => {
      return {
        ...state,
        RCDataLevel2: {
          ...state.RCDataLevel2,
          [action.payload.key]: action.payload.value,
        },
      };
    },
    getRCL2ApprovalsList: (state, action) => {
      if (state.RCL2ApprovalsListParams?.page == 1) {
        return {
          ...state,
          loading: true,
          RCL2ApprovalsList: [],
          RCL2ApprovalsListParams: {
            no_of_pages: 0,
            page_size: 10,
            page: 1,
            search: "",
          },
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },

    RCL2ApprovalsListSuccessful: (state, action) => {
      var list = [];
      if (state.RCL2ApprovalsListParams?.page == 1) {
        list = action.payload.results;
      } else {
        list = [...state.RCL2ApprovalsList, ...action.payload.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.RCL2ApprovalsListParams.page_size
      );
      return {
        ...state,
        listCount: action.payload.count,
        loading: false,
        RCL2ApprovalsList: list,
        RCL2ApprovalsListParams: {
          ...state.RCL2ApprovalsListParams,
          no_of_pages: noofpages,
        },
      };
    },
    setRCL2ApprovalsListParams: (state, action) => {
      return {
        ...state,
        RCL2ApprovalsListParams: action.payload,
      };
    },
    getRCL2EmployeesList: (state, action) => {
      if (state.RCL2EmployeesListParams?.page == 1) {
        return {
          ...state,
          loading: true,
          RCL2EmployeesList: [],
          // RCL2ApprovalsListParams: {
          //   no_of_pages: 0,
          //   page_size: 10,
          //   page: 1,
          //   search: "",
          // },
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },

    RCL2EmployeesListSuccessful: (state, action) => {
      var list = [];
      if (state.RCL2EmployeesListParams?.page == 1) {
        list = action.payload.results;
      } else {
        list = [...state.RCL2EmployeesList, ...action.payload.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.RCL2EmployeesListParams.page_size
      );
      return {
        ...state,
        RCL2EmployeesListCount: action.payload.count,
        loading: false,
        RCL2EmployeesList: list,
        RCL2EmployeesListParams: {
          ...state.RCL2EmployeesListParams,
          no_of_pages: noofpages,
        },
        RCL2EmployeesListModel: true,
      };
    },
    setRCL2EmployeesListParams: (state, action) => {
      return {
        ...state,
        RCL2EmployeesListParams: action.payload,
      };
    },
    isRCL2EmployeesListModelVisible: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          RCL2EmployeesListModel: action.payload,
        };
      } else {
        return {
          ...state,
          RCL2EmployeesListModel: action.payload,
          // mrqByIdItemsData: [],
        };
      }
    },
    RCApproveLeave2: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },


    getMiniResourceEmployee: (state) => {
      if (state.miniResourceEmployeeParams?.page == 1) {
        return {
          ...state,
          loading: true,
          miniResourceEmployeeList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    miniResourceEmployeeSuccessful: (state, action) => {
      
      // var response = action.payload.results;
      // var selectedItemIds = []
      // if (response.length != 0) {
      //   selectedItemIds = state.selectedRCItem
      //     ?.filter((item) => !item.dodelete)
      //     ?.map((repairType) => {
      //       return repairType.repair?.id;
      //     });
      // }
      // var repairTypeListData = response.filter((item) => {
      //   return selectedItemIds.indexOf(item.id) == -1;
      // });


      // var list = [];
      // if (state.miniResourceEmployeeParams?.page == 1) {
      //   list = response.results;
      // } else {
      //   list = [...state.miniResourceEmployeeList, ...response.results];
      // }
      // var noofpages = Math.ceil(
      //   action.payload.count / state.miniResourceEmployeeParams?.page_size
      // );

      
      var response = action.payload;
      var list = [];
      if (state.miniResourceEmployeeParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.miniResourceEmployeeList, ...response.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.miniResourceEmployeeParams?.page_size
      );
      return {
        ...state,
        miniResourceEmployeeList: list,
        loading: false,
        miniResourceEmployeeParams: {
          ...state.miniResourceEmployeeParams,
          no_of_pages: noofpages,
        },
      };
    },
    setMiniResourceEmployeeParams: (state, action) => {
      return {
        ...state,
        miniResourceEmployeeParams: action.payload,
      };
    },

    apiError: (state, action) => {
      return {
        ...state,
        loading: false,
        formSubmitLoading: false,
        error_msg: action.payload,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getResourceConsumptionList,
  resourceConsumptionListSuccessful,
  setResourceConsumptionParams,
  ResourceDocvalidate,
  getWorkOrderData,
  WorkOrderDataSuccessful,
  setWorkOrderDataParams,
  isRCItemModelVisible,
  InputChangeValue,
  RCItemInputChangeValue,
  selectWorkOrder,
  prevStep,
  ResourceItemSubmit,
  RCSubItemInputChangeValue,
  checkTotalConsumptionQty,
  checkTotalOTQty,
  ResourceSubItemSubmit,
  AddResourceConsumption,
  ResourceConsumptionItemsAdd,
  ResourceConsumptionSubItemsAdd,
  RCDocAddMake,
  clearRCData,
  EditResourceItem,
  RCItemDelect,
  getRCDocViewData,
  RCDocViewDataSuccessful,
  getRCDocItemsById,
  RCDocItemsByIdListSuccessful,
  setRCDocItemsByIdParams,
  getRCDocSubItemsById,
  RCDocSubItemsByIdListSuccessful,
  setRCDocSubItemsByIdParams,
  isRCSubItemsListModelVisible,
  isRCSubmitModelVisible,
  RCSubmitInputChangeValue,

  getRCEmployeeList,
  RCEmployeeListSuccessful,
  setRCEmployeeListParams,
  RCEmployeeByIDModelVisible,
  RCEmployeeChangeStatus,
  isRCEmployeeModelVisible,
  addRCEmployeeDrawer,
  RCEmployeeInputChangeValue,
  RCEmployeeSubmit,
  isRCEmpDrawerVisible,
  RCEmpFilterInputChangeValue,
  FilterInputChangeValue,
  isDrawerVisible,

  clearRCL1Data,
  getRCEmployeeByIDList,
  RCEmployeeByIDListSuccessful,
  setRCEmployeeByIDListParams,
  l1InputChangeValue,
  getRCL1ApprovalsList,
  RCL1ApprovalsListSuccessful,
  setRCL1ApprovalsListParams,
  RCL1SubItemInputChangeValue,
  RCL1SubItemUpdateValue,
  RCL1ItemModelVisible,
  EditRCL1Item,
  RCSubItemL1Check,
  RCSubItemL1CheckSuccessful,
  RCApproveLeave1,

  clearRCL2Data,
  l2InputChangeValue,
  getRCL2ApprovalsList,
  RCL2ApprovalsListSuccessful,
  setRCL2ApprovalsListParams,
  RCApproveLeave2,
  getRCL2EmployeesList,
  RCL2EmployeesListSuccessful,
  setRCL2EmployeesListParams,
  isRCL2EmployeesListModelVisible,



  getMiniResourceEmployee,
  miniResourceEmployeeSuccessful,
  setMiniResourceEmployeeParams,
  apiError,
} = ResourceConsumptionSlice.actions;

export default ResourceConsumptionSlice.reducer;
