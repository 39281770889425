import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { Row, Col, Select, Card, Progress, Space } from "antd";

import {
  getStockValues,
  setStockValueParams,
  setWarehouseId,
  setUserWarehousesParams,
  getUserWarehousesList,
} from "../../store/dashboard/dashboardSlice";

const { Option } = Select;

function StockHistoryCard(props) {
  const onLoadMore = (page) => {
    props.setStockValueParams({
      ...props.StockDataListParams,
      page: page,
    });
    props.getStockValues({
      params: { ...props.StockDataListParams, page: page },
      Warehouse_id: props.Warehouse_id,
    });
  };
  function handleScroll(e) {
    console.log("scroll", e);
    const target = e.target;
    if (target.scrollTop !== 0) {
      if (target.scrollHeight - target.scrollTop === target.clientHeight) {
        if (
          props.StockDataListParams.page < props.StockDataListParams.no_of_pages
        ) {
          onLoadMore(props.StockDataListParams.page + 1);
        }
      }
    }
  }
  return (
    <>
      <Card>
        <Row gutter={[0, 16]} align="middle" justify="space-between">
          <Col sm={12} span={24}>
            <span className="h3 hp-font-weight-600 hp-text-color-black-bg hp-text-color-dark-0 hp-d-block">
              Stock Values
            </span>

            {/* <p className="hp-p1-body hp-mt-4 hp-mb-0">
              Transection of last 6 months
            </p> */}
          </Col>

          <Col>
            <Select
              // showSearch
              style={{ width: 160 }}
              defaultActiveFirstOption={false}
              showArrow={true}
              filterOption={false}
              // onSearch={(value) => {
              //   props.setUserWarehousesParams({
              //     ...props.UserWarehousesListParams,
              //     page: 1,
              //     search: value,
              //   });
              //   props.getUserWarehousesList({
              //     ...props.UserWarehousesListParams,
              //     page: 1,
              //     search: value,
              //   });
              // }}
              onPopupScroll={() => {
                if (
                  props.UserWarehousesListParams.page <
                  props.UserWarehousesListParams.no_of_pages
                ) {
                  props.setUserWarehousesParams({
                    ...props.UserWarehousesListParams,
                    page: props.UserWarehousesListParams.page + 1,
                  });
                  props.getUserWarehousesList({
                    ...props.UserWarehousesListParams,
                    page: props.UserWarehousesListParams.page + 1,
                  });
                }
              }}
              onChange={(value) => {
                console.log("setWarehouseId", value);
                if (value != "") {
                  props.setWarehouseId(value);
                  props.setStockValueParams({
                    ...props.StockDataListParams,
                    page: 1,
                  });
                  props.getStockValues({
                    params: { ...props.StockDataListParams, page: 1 },
                    Warehouse_id: value,
                  });
                }
              }}
              value={props.Warehouse_id == 0 ? "" : props.Warehouse_id}
            >
              {props.UserWarehousesList.map((warehouse, index) => {
                return (
                  <Option key={index} value={warehouse.id}>
                    {warehouse.name}
                  </Option>
                );
              })}
            </Select>
          </Col>
        </Row>
        <div
          onScroll={handleScroll}
          style={{
            height: "420px",
            overflowY: "auto",
          }}
        >
          {props.StockDataList && (
            <Row gutter={[0, 12]} className="hp-mt-12 hp-p-0">
              {props.StockDataList.length != 0 ? (
                <>
                  {props.StockDataList.map((item, index) => (
                    <Col
                      key={index}
                      span={24}
                      className="hp-bg-primary-4  hp-border-radius-xl hp-border-1 hp-border-color-primary-4 hp-border-color-primary-4 hp-p-12"
                    >
                      <Row align="middle" wrap={false}>
                        <Col>
                          {item.material_item && (
                            <span className="hp-d-block h6 hp-font-weight-600">
                              {item.material_item?.name}
                            </span>
                          )}
                        </Col>
                      </Row>
                      <Row align="middle" justify="space-between">
                        <Col
                          sm={6}
                          span={12}
                          className="hp-text-sm-left "
                          style={{ minHeight: 40 }}
                        >
                          <span className="hp-d-block h6 hp-font-weight-200">
                            Total Stock
                          </span>

                          <span className="hp-d-block h6 hp-font-weight-600">
                            {item.warehouse_stock_qty}
                          </span>
                        </Col>
                        <Col
                          sm={6}
                          span={12}
                          className="hp-text-sm-left "
                          style={{ minHeight: 40 }}
                        >
                          <span className="hp-d-block h6 hp-font-weight-200">
                            Open MRQ
                          </span>

                          <span className="hp-d-block h6 hp-font-weight-600">
                            {item.open_mrq_qty}
                          </span>
                        </Col>
                        <Col
                          sm={6}
                          span={12}
                          className="hp-text-sm-left "
                          style={{ minHeight: 40 }}
                        >
                          <span className="hp-d-block h6 hp-font-weight-200">
                            Un ACK Stock
                          </span>

                          <span className="hp-d-block h6 hp-font-weight-600">
                            {item.unack_qty}
                          </span>
                        </Col>

                        <Col
                          sm={5}
                          span={11}
                          className="hp-text-right"
                          style={{ maxHeight: 40 }}
                        >
                          {/* <Progress
                            key={index}
                            type="circle"
                            percent={item.max_stock_qty - item.open_mrq_qty}
                            width={40}
                            // strokeWidth={1}
                            format={(percent) => `${percent}`}
                          /> */}
                        </Col>
                      </Row>
                    </Col>
                  ))}
                </>
              ) : (
                <>
                  <Col
                    span={24}
                    className="hp-bg-primary-4  hp-border-radius-xl hp-border-1 hp-border-color-primary-4 hp-border-color-primary-4 hp-p-12 "
                  >
                    <span className="hp-text-center hp-d-block h6 hp-font-weight-600">
                      No Data
                    </span>
                  </Col>
                </>
              )}
            </Row>
          )}
        </div>
      </Card>
    </>
  );
}
function mapStateToProps({ dashboard, reports, system }) {
  return {
    MRQCountData: dashboard.MRQCountData,
    UserWarehousesList: dashboard.UserWarehousesList,
    UserWarehousesListParams: dashboard.UserWarehousesListParams,
    Warehouse_id: dashboard.Warehouse_id,
    StockDataList: dashboard.StockDataList,
    StockDataListParams: dashboard.StockDataListParams,
  };
}

export default connect(mapStateToProps, {
  getStockValues,
  setStockValueParams,
  setWarehouseId,
  setUserWarehousesParams,
  getUserWarehousesList,
})(StockHistoryCard);
