import { Fragment, useState, useEffect } from "react";

import { connect } from "react-redux";
import moment from "moment";
import "flatpickr/dist/themes/light.css";
import { RiCloseFill } from "react-icons/ri";
import {
  Button,
  Input,
  Form,
  Modal,
  Badge,
  Row,
  Col,
  Select,
  DatePicker,
  Alert,
} from "antd";
import {
  // countryAdd,
  isUserChangePwdModel,
  InputChangeValue,
  ChangePasswordSubmit,
} from "../../../store/settings/ManageUsers/manageUserSlice";

const UserChangePwdModel = (props) => {
  const [confimPasswordError, setconfimPasswordError] = useState(false);
  const [form] = Form.useForm();

  function ChangeHandeler(e) {
    props.InputChangeValue({
      key: e.target.name,
      value: e.target.value,
    });
  }

  const handleUpdate = () => {
    const obj = {
      old_password: props.changePwdData.old_password,
      password: props.changePwdData.password,
    };
    props.ChangePasswordSubmit(obj);
  };

  // Reset Input Values on Close
  const handleResetInputValues = () => {
    form.resetFields();
    props.isUserChangePwdModel(false);
    setconfimPasswordError(false);
  };

  function confirmPasswordHandeler(e) {
    console.log(props.changePwdData?.password)
    console.log(e.target.value)
    if (e.target.value == props.changePwdData?.password) {
      setconfimPasswordError(false);
      props.InputChangeValue({
        key: e.target.name,
        value: e.target.value,
      });
    } else {
      setconfimPasswordError(true);
    }
  }

  const FooterAddOrUpdate = () => {
    return (
      <Fragment>
        <Button
          type="primary"
          onClick={() => {
            // handleUpdate();
            form.submit();
          }}
          block
        >
          Update
        </Button>
      </Fragment>
    );
  };

  const FooterCancel = () => {
    return (
      <Fragment>
        <Button
          onClick={() => {
            handleResetInputValues();
          }}
          type="primary"
          block
          ghost
        >
          Cancel
        </Button>
      </Fragment>
    );
  };

  const modalTitle = <h5 className="modal-title">Change Password</h5>;
  useEffect(() => {
    form.setFieldsValue({
      old_password: props.changePwdData.old_password,
      password: props.changePwdData.password,
      confimPassword: props.changePwdData.confimPassword,
    });
  }, [props.changePwdData]);

  return (
    <Modal
      visible={props.changePwdModel}
      title={modalTitle}
      maskClosable={false}
      onCancel={() => {
        handleResetInputValues();
      }}
      className="hp-modal-p-24"
      footer={
        <Row justify="center">
          <Col span={12} className="hp-pr-4">
            <FooterCancel />
          </Col>

          <Col span={12} className="hp-pl-4">
            <FooterAddOrUpdate />
          </Col>
        </Row>
      }
      centered
      closeIcon={
        <RiCloseFill className="remix-icon text-color-black-100" size={24} />
      }
      width={516}
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={() => {
          handleUpdate();
        }}
        initialValues={{ remember: true }}
      >
        <Row gutter={[12, 0]}>
          <Col span={24} xs={24} xl={24} md={24}>
            <Form.Item
              label="Old Password :"
              name="old_password"
              rules={[
                {
                  required: true,
                  message: "Please Input old password",
                },
              ]}
              hasFeedback
            >
              <Input.Password
                id="old_password"
                name="old_password"
                style={{ width: "100%" }}
                placeholder="Password"
                value={
                  props.changePwdData.old_password == null
                    ? ""
                    : props.changePwdData?.old_password
                }
                onChange={ChangeHandeler}
              />
            </Form.Item>
          </Col>
          <Col span={24} xs={24} xl={24} md={24}>
            <Form.Item
              label="Password :"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please Input password",
                },
              ]}
              hasFeedback
            >
              <Input.Password
                id="password"
                name="password"
                style={{ width: "100%" }}
                placeholder="Password"
                value={
                  props.changePwdData.password == null
                    ? ""
                    : props.changePwdData?.password
                }
                onChange={ChangeHandeler}
              />
            </Form.Item>
            <Form.Item
              label="Confim Password"
              name="confimPassword"
              rules={[
                {
                  required: true,
                  message: "Please Input password",
                },
              ]}
            >
              <Input.Password
                id="confimPassword"
                name="confimPassword"
                style={{ width: "100%" }}
                placeholder="Old Password"
                value={props.changePwdData?.confimPassword}
                onChange={confirmPasswordHandeler}
                rules={[
                  {
                    required: true,
                    message: "Please input your Confim password!",
                  },
                ]}
              />
            </Form.Item>
            {confimPasswordError && (
              <Alert message="Passwords don't match" type="error" />
            )}
            {props.apiError?.old_password && (
              <Alert
                message={props.apiError?.old_password?.old_password}
                type="error"
              />
            )}
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

function mapStateToProps({ manageUser }) {
  return {
    changePwdData: manageUser.employeeUserdata,
    changePwdModel: manageUser.changePwdModel,
    apiError: manageUser.apiError,
  };
}

export default connect(mapStateToProps, {
  isUserChangePwdModel,
  InputChangeValue,
  ChangePasswordSubmit,
})(UserChangePwdModel);
