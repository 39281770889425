import React, { useState } from "react";

import { Card, Row, Col, Select } from "antd";
import Chart from "react-apexcharts";
import moment from "moment";

export default function StockConsumptionColumnCard(props) {
  const [yAxisMaxValue, setYaxisMaxValue] = useState(50);

  // var list = [
  //   { value: 50, name: "50" },
  //   { value: 100, name: "100" },
  //   { value: 150, name: "150" },
  //   { value: 200, name: "200" },
  //   { value: 250, name: "250" },
  // ];

  console.log("yAxisMaxValue", yAxisMaxValue);
  return (
    <Card className="hp-border-color-black-40 hp-card-6 hp-chart-text-color">
      <Row>
        <Col className="hp-mb-16" span={24}>
          <Row justify="space-between">
            <Col sm={12} span={24}>
              <span className="h3 hp-font-weight-600 hp-text-color-black-bg hp-text-color-dark-0 hp-d-block">
                Stock Values
              </span>

              <p className="hp-p1-body hp-mt-4 hp-mb-0">
                Transactions of last week
              </p>
            </Col>
            {/* <Col>
              <Select
                // showSearch
                style={{ width: 160 }}
                defaultActiveFirstOption={false}
                showArrow={true}
                filterOption={false}
                onChange={(value) => {
                  console.log("setWarehouseId", value);
                  if (value != "") {
                    setYaxisMaxValue(value);
                  }
                }}
                value={yAxisMaxValue}
              >
                {list.map((item, index) => {
                  return (
                    <Option key={index} value={item.value}>
                      {item.name}
                    </Option>
                  );
                })}
              </Select>
            </Col> */}
          </Row>
        </Col>

        <Col span={24}>
          <div id="revenue-column-card">
            <Chart
              options={{
                chart: {
                  type: "line",
                  id: "revenue-line-1",
                  toolbar: {
                    show: false,
                  },
                  zoom: {
                    enabled: false,
                  },
                },
                legend: {
                  show: false,
                },
                markers: {
                  size: 2,
                  strokeColors: "#FF8B9A",
                  strokeOpacity: 5,
                },

                colors: ["#FF8B9A"],
                stroke: {
                  curve: "smooth",
                  lineCap: "round",
                  width: 2,
                },
                // tooltip: {
                //   enabled: false,
                //   style: {
                //     fontSize: "1rem",
                //   },
                //   // items: {
                //   //   display: "none",
                //   // },
                //   x: {
                //     show: true,
                //     // format: string
                //     formatter(val, opts) {
                //       return val;
                //     },
                //   },
                // },
                dataLabels: {
                  enabled: false,
                },
                grid: {
                  show: true,
                  borderColor: "#B2BEC3",
                  strokeDashArray: props.data.length,
                  position: "back",
                  xaxis: {
                    lines: {
                      show: false,
                    },
                  },
                  yaxis: {
                    lines: {
                      show: false,
                    },
                  },
                },
                xaxis: {
                  axisTicks: {
                    show: false,
                    borderType: "solid",
                    color: "#78909C",
                    height: 7,
                    offsetX: 0,
                    offsetY: 0,
                  },

                  tickPlacement: "between",
                  labels: {
                    style: {
                      colors: ["636E72"],
                      fontSize: "14px",
                    },
                  },
                  categories: props.data.map((d) => d.title),
                },

                responsive: [
                  {
                    breakpoint: 426,
                    options: {
                      legend: {
                        itemMargin: {
                          horizontal: 16,
                          vertical: 8,
                        },
                      },
                    },
                  },
                ],
                yaxis: {
                  labels: {
                    style: {
                      colors: ["636E72"],
                      fontSize: "14px",
                    },
                    formatter: (value) => {
                      return value;
                    },
                  },

                  // min: 0,
                  // max: yAxisMaxValue,
                  // tickAmount: 5,
                },
              }}
              series={[
                {
                  name: "Stock",
                  data: props.data.map((d) => d.total),
                },
              ]}
              type="line"
              height={400}
              legend="legend"
            />
          </div>
        </Col>
      </Row>
    </Card>
  );
}
