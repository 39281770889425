import { createSlice, current } from "@reduxjs/toolkit";
import moment from "moment";

export const manageuserSlice = createSlice({
  name: "manageUser",
  initialState: {
    loginListCount: 0,
    deviceListCount: 0,
    activityListCount: 0,
    listCount: 0,
    Userslist: [],
    usersParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    changePwdModel: false,
    changePwdData: {},
    userId: 0,
    drawer: false,
    employeeUserdata: {},
    userDeptObj: {},
    passwordModel: false,
    showPassword: true,
    filterStatus: false,
  },

  reducers: {
    getUsers: (state, action) => {
      if (state.usersParams?.page == 1) {
        return {
          ...state,
          loading: true,
          Userslist: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
      // if (action.payload?.page == 1) {
      //   return {
      //     ...state,
      //     loading: true,
      //     Userslist: [],
      //     // usersParams: {
      //     //   no_of_pages: 0,
      //     //   page_size: action.payload.page_size,
      //     //   page: 1,
      //     //   search: "",
      //     //   currentSort: "code",
      //     //   sortOrder: "-",
      //     // },
      //   };
      // } else {
      //   return {
      //     ...state,
      //     loading: true,
      //   };
      // }
    },
    usersListSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.usersParams?.page == 1) {
        list = response.results;
      } else {
        list = response.results;
      }
      var noofpages = Math.ceil(
        action.payload.count / state.usersParams?.page_size
      );
      return {
        ...state,
        Userslist: list,
        listCount: action.payload.count,
        loading: false,
        usersParams: {
          ...state.usersParams,
          no_of_pages: noofpages,
        },
      };
      // console.log("usersListSuccessful", action.payload);
      // var noofpages = Math.ceil(
      //   action.payload.count / state.usersParams.page_size
      // );
      // return {
      //   ...state,
      //   listCount: action.payload.count,
      //   Userslist: action.payload.results,
      //   // userDeptObj: action.payload.results,
      //   loading: false,
      //   usersParams: {
      //     ...state.usersParams,
      //     no_of_pages: noofpages,
      //   },
      // };
    },
    isUserChangePwdModel: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          changePwdModel: action.payload,
          employeeUserdata: {},
        };
      } else {
        return {
          ...state,
          changePwdModel: action.payload,
        };
      }
    },

    ChangePasswordSubmit: (state, action) => {
      return {
        ...state,
        loading: true,
        // changePwdModel: false,
      };
    },
    userActive: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    userInActive: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    getUserID: (state, action) => {
      return {
        ...state,
        loading: false,
        userId: action.payload,
      };
    },
    isPasswordModel: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          passwordModel: action.payload,
          employeeUserdata: {},
        };
      } else {
        return {
          ...state,
          passwordModel: action.payload,
        };
      }
    },
    updatePassword: (state) => {
      return {
        ...state,
        loading: true,
      };
    },

    isDrawerVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          drawer: action.payload,
        };
      } else {
        return {
          ...state,
          drawer: action.payload,
        };
      }
    },
    FilterInputChangeValue: (state, action) => {
      return {
        ...state,
        usersParams: {
          ...state.usersParams,
          [action.payload.key]: action.payload.value,
        },
      };
    },

    InputChangeValue: (state, action) => {
      if (action.payload.key == "role") {
        return {
          ...state,
          employeeUserdata: {
            ...state.employeeUserdata,
            [action.payload.key]: action.payload.value,
            branch: null,
            department: null,
            warehouse_id: null,
            approver_id: null,
          },
        };
      } else {
        return {
          ...state,
          employeeUserdata: {
            ...state.employeeUserdata,
            [action.payload.key]: action.payload.value,
          },
        };
      }
    },
    SelectChangeValue: (state, action) => {
      if (action.payload.key == "branch") {
        return {
          ...state,
          employeeUserdata: {
            ...state.employeeUserdata,
            [action.payload.key]: {
              id: action.payload.value,
              name: action.payload.name,
            },
            department: null,
            warehouse: null,
          },
        };
      } else if (action.payload.key == "department") {
        return {
          ...state,
          employeeUserdata: {
            ...state.employeeUserdata,
            [action.payload.key]: {
              id: action.payload.value,
              name: action.payload.name,
            },
            warehouse: null,
          },
        };
      } else {
        return {
          ...state,
          employeeUserdata: {
            ...state.employeeUserdata,
            [action.payload.key]: {
              id: action.payload.value,
              name: action.payload.name,
            },
          },
        };
      }
    },
    MultipleSelectChangeValue: (state, action) => {
      if (action.payload.key == "branch") {
        return {
          ...state,
          employeeUserdata: {
            ...state.employeeUserdata,
            [action.payload.key]: {
              id: action.payload.value,
              name: action.payload.name,
            },

            department: null,
            warehouse_id: null,
            approver_id: null,
          },
        };
      } else {
        return {
          ...state,
          employeeUserdata: {
            ...state.employeeUserdata,
            [action.payload.key]: {
              id: action.payload.value,
              name: action.payload.name,
            },
          },
        };
      }
    },
    setUsersParams: (state, action) => {
      return {
        ...state,
        usersParams: action.payload,
      };
    },
    clearProfileData: (state, action) => {
      return {
        ...state,
        loading: false,
        employeeUserdata: {},
        usersParams: {
          no_of_pages: 0,
          page_size: 10,
          page: 1,
          search: "",
          currentSort: "code",
          sortOrder: "-",
        },
      };
    },
    isModelVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          model: action.payload,
          employeeUserdata: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },
    userAdd: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    userDeptAdd: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    userNameValidation: (state) => {
      return {
        ...state,
      };
    },
    userEmailValidation: (state) => {
      return {
        ...state,
      };
    },
    getUserById: (state, action) => {
      return {
        ...state,
        loading: true,
        employeeUserdata: {},
      };
    },
    userByIdSuccessful: (state, action) => {
      return {
        ...state,
        employeeUserdata: {
          ...action.payload,
          // phone: action.payload.phone,
          group_ids:
            action.payload.groups?.length != 0
              ? action.payload.groups[0]?.id
              : "",
          warehouse_id: action.payload.warehouse?.id,
          approver: {
            id: action.payload.approver?.id,
            name: action.payload.approver?.username,
            fullname: action.payload.approver?.fullname,
          },
        },

        loading: false,
      };
    },
    getBranchDeptById: (state, action) => {
      return {
        ...state,
        loading: true,
        // employeeUserdata: {},
        // userId: action.payload.id,
      };
    },
    branchDeptByIdSuccessful: (state, action) => {
      console.log("branchDeptByIdSuccessful", action.payload);
      var DepartmentIds;
      var Branch = action.payload.map((item) => {
        return item.branch;
      });

      action.payload.map((item) => {
        if (item.departments.length != 0) {
          DepartmentIds = item.departments?.map((departments) => {
            return departments;
          });
        }
      });
      console.log("DepartmentIds", DepartmentIds);
      return {
        ...state,
        employeeUserdata: {
          ...state.employeeUserdata,
          department:
            state.employeeUserdata.role == 1
              ? DepartmentIds[0]
              : state.employeeUserdata.role == 2
                ? {
                  id: DepartmentIds.map((item) => item.id),
                  name: DepartmentIds.map((item) => item.name),
                }
                : null,
          branch:
            state.employeeUserdata.role != 3
              ? action.payload[0].branch
              : {
                id: Branch.map((item) => item.id),
                name: Branch.map((item) => item.name),
              },
        },
        loading: false,
      };
    },
    checkFilterStatus: (state, action) => {
      return {
        ...state,
        filterStatus: action.payload,
      };
    },
    ackStatusChange: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },
    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getUsers,
  usersListSuccessful,
  isUserChangePwdModel,
  userActive,
  userInActive,
  ChangePasswordSubmit,
  getUserID,
  isDrawerVisible,
  FilterInputChangeValue,
  updatePassword,
  InputChangeValue,
  SelectChangeValue,
  MultipleSelectChangeValue,
  isPasswordModel,
  setUsersParams,
  clearProfileData,
  isModelVisible,
  userAdd,
  userDeptAdd,
  userNameValidation,
  userEmailValidation,
  getUserById,
  userByIdSuccessful,
  getBranchDeptById,
  branchDeptByIdSuccessful,
  checkFilterStatus,
  ackStatusChange,
  apiError,
} = manageuserSlice.actions;

export default manageuserSlice.reducer;
