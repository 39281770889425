import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

export const miniSlice = createSlice({
  name: "mini",
  initialState: {
    userAccessList: [],
    miniBrancheList: [],
    miniDepartmentList: [],
    miniDesignationsList: [],
    miniWarehouseList: [],
    miniMaterialCategoryList: [],
    miniMaterialGroupList: [],
    miniMaterialItemList: [],
    miniStockPointList: [],
    userData: {},
    allDepatByBranchList: [],
    allWarehouseByDeptBranchList: [],
    UserDeptByBranchList: [],
    userMiniBranchList: [],
    userWarehouseList: [],
    miniUserList: [],
    miniReportingUserList: [],
    miniMaterialTypeList: [],
    miniResourceCategoryList: [],  
    miniResourceCategoryParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      // currentSort: "code",
      // sortOrder: "-",
    },
   
    miniResourceVendorList: [],
    miniResourceVendorParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    miniBranchParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    miniDepartmentParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    miniDesignationsParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    miniWarehouseParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    miniMaterialCategoryParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    miniMaterialGroupParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    miniMaterialItemParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    miniStockPointParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    allDepatByBranchParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    allWarehouseByDeptBranchParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    userDeptByBranchParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    userMiniBranchParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },

    userWarehouseParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    miniUserParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    miniReportingUserParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    miniMaterialTypeParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    miniDeptWarehouseList: [],
    miniDeptWarehouseListParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    miniUserStockpointList: [],
    miniUserStockpointListParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    miniPettyCashItems: [],
    miniPettyCashItemsParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    miniUtilityBilling: [],
    miniUtilityBillingParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    utilitySeriveNum: [],
    utilitySeriveNumParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },


    // Wheelsmart Code
    miniStatesList: [],
    miniCityList: [],
    miniAreaList: [],
    miniDistrictList: [],
    miniFirmList: [],
    miniVehiclesList: [],
    miniVehiclesNonStockList: [],
    purchaseAvaliableStockList: [],
    purchaseStockList: [],
    saleQuotationStockList: [],
    miniCountersList: [],
    allCountersList: [],
    miniZonesList: [],
    miniClustersList: [],
    miniDivisionsList: [],
    miniBanksList: [],
    miniOccupationList: [],
    miniBrandsList: [],
    miniBrandFamilyList: [],
    miniColorsList: [],
    versionColorsList: [],
    miniFinanciersList: [],
    miniMechanicsList: [],
    miniAddressProofList: [],
    miniEmissionnorms: [],
    miniPriority: [],
    miniVersionList: [],
    miniModelsList: [],
    miniPercentageInterestsList: [],
    miniRejectReasonsList: [],
    miniPurchaseEnquiryList: [],
    miniSaleQuotationList: [],
    miniVehicleEstimationList: [],
    // miniEMIdatesList: [],
    miniInsuranceList: [],
    miniVehicletypesList: [],
    miniTenuresList: [],
    miniManagersList: [],
    searchVehicleNonStockId: 0,
    FVRequestUsersList: [],
    miniRepairTypesList: [],
    miniLeaveReasonList: [],
    FVRequestUsersParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
    purchaseEnquiryParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
    miniVehiclesNonStockParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
    miniVehiclesParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
    purchaseAvailableStockParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
    purchaseStockParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      currentSort: "code",
      sortOrder: "-",
    },
    saleQuotationStockListParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
    },
    
  },
  reducers: {
    getUserPermissions: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    userPermissionsSuccessful: (state) => {
      return {
        ...state,
        userAccessList: action.payload.response.data,
        loading: false,
      };
    },

    getMiniDesignations: (state) => {
      if (state.miniDesignationsParams?.page == 1) {
        return {
          ...state,
          loading: true,
          miniDesignationsList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    miniDesignationSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.miniDesignationsParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.miniDesignationsList, ...response.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.miniDesignationsParams?.page_size
      );
      return {
        ...state,
        miniDesignationsList: list,
        loading: false,
        miniDesignationsParams: {
          ...state.miniDesignationsParams,
          no_of_pages: noofpages,
        },
      };
    },
    setDesignationParams: (state, action) => {
      return {
        ...state,
        miniDesignationsParams: action.payload,
      };
    },
    getMiniWarehouse: (state) => {
      if (state.miniWarehouseParams?.page == 1) {
        return {
          ...state,
          loading: true,
          miniWarehouseList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    miniWarehouseSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.miniWarehouseParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.miniWarehouseList, ...response.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.miniWarehouseParams?.page_size
      );
      return {
        ...state,
        miniWarehouseList: list,
        loading: false,
        miniWarehouseParams: {
          ...state.miniWarehouseParams,
          no_of_pages: noofpages,
        },
      };
    },
    setWarehouseParams: (state, action) => {
      return {
        ...state,
        miniWarehouseParams: action.payload,
      };
    },

    getMiniMaterialCategory: (state) => {
      if (state.miniMaterialCategoryParams?.page == 1) {
        return {
          ...state,
          loading: true,
          miniMaterialCategoryList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    miniMaterialCategorySuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.miniMaterialCategoryParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.miniMaterialCategoryList, ...response.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.miniMaterialCategoryParams?.page_size
      );
      return {
        ...state,
        miniMaterialCategoryList: list,
        loading: false,
        miniMaterialCategoryParams: {
          ...state.miniMaterialCategoryParams,
          no_of_pages: noofpages,
        },
      };
    },
    setMaterialCategoryParams: (state, action) => {
      return {
        ...state,
        miniMaterialCategoryParams: action.payload,
      };
    },
    getMiniMaterialGroup: (state) => {
      if (state.miniMaterialGroupParams?.page == 1) {
        return {
          ...state,
          loading: true,
          miniMaterialGroupList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    miniMaterialGroupSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.miniMaterialGroupParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.miniMaterialGroupList, ...response.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.miniMaterialGroupParams?.page_size
      );
      return {
        ...state,
        miniMaterialGroupList: list,
        loading: false,
        miniMaterialGroupParams: {
          ...state.miniMaterialGroupParams,
          no_of_pages: noofpages,
        },
      };
    },
    setMaterialGroupParams: (state, action) => {
      return {
        ...state,
        miniMaterialGroupParams: action.payload,
      };
    },
    getMiniMaterialItem: (state) => {
      if (state.miniMaterialItemParams?.page == 1) {
        return {
          ...state,
          loading: true,
          miniMaterialItemList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    miniMaterialItemSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.miniMaterialItemParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.miniMaterialItemList, ...response.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.miniMaterialItemParams?.page_size
      );
      return {
        ...state,
        miniMaterialItemList: list,
        loading: false,
        miniMaterialItemParams: {
          ...state.miniMaterialItemParams,
          no_of_pages: noofpages,
        },
      };
    },
    setMaterialItemParams: (state, action) => {
      return {
        ...state,
        miniMaterialItemParams: action.payload,
      };
    },

    getMiniStockPoint: (state) => {
      if (state.miniStockPointParams?.page == 1) {
        return {
          ...state,
          loading: true,
          miniStockPointList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    miniStockPointSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.miniStockPointParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.miniStockPointList, ...response.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.miniStockPointParams?.page_size
      );
      return {
        ...state,
        miniStockPointList: list,
        loading: false,
        miniStockPointParams: {
          ...state.miniStockPointParams,
          no_of_pages: noofpages,
        },
      };
    },
    setStockPointParams: (state, action) => {
      return {
        ...state,
        miniStockPointParams: action.payload,
      };
    },
    getUserData: (state, action) => {
      return {
        ...state,
        loading: true,
        userData: {},
      };
    },
    userDataSuccessful: (state, action) => {
      return {
        ...state,
        userData: action.payload,
        loading: false,
      };
    },

    //  Mini Branches
    getMiniBranches: (state, action) => {
      if (state.miniBranchParams?.page == 1) {
        return {
          ...state,
          loading: true,
          miniBrancheList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    miniBranchListSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.miniBranchParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.miniBrancheList, ...response.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.miniBranchParams?.page_size
      );
      return {
        ...state,
        miniBrancheList: list,
        loading: false,
        miniBranchParams: {
          ...state.miniBranchParams,
          no_of_pages: noofpages,
        },
      };
    },
    setBranchParams: (state, action) => {
      return {
        ...state,
        miniBranchParams: action.payload,
      };
    },

    // Mini Departments
    getMiniDepartment: (state) => {
      if (state.miniDepartmentParams?.page == 1) {
        return {
          ...state,
          loading: true,
          miniDepartmentList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    miniDepartmentSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.miniDepartmentParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.miniDepartmentList, ...response.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.miniDepartmentParams?.page_size
      );
      return {
        ...state,
        miniDepartmentList: list,
        loading: false,
        miniDepartmentParams: {
          ...state.miniDepartmentParams,
          no_of_pages: noofpages,
        },
      };
    },
    setDepartmentParams: (state, action) => {
      return {
        ...state,
        miniDepartmentParams: action.payload,
      };
    },

    // All Departments By Branch

    getAllDeptByBranch: (state) => {
      if (state.allDepatByBranchParams?.page == 1) {
        return {
          ...state,
          loading: true,
          allDepatByBranchList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    allDeptByBranchSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.allDepatByBranchParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.allDepatByBranchList, ...response.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.allDepatByBranchParams?.page_size
      );
      return {
        ...state,
        allDepatByBranchList: list,
        loading: false,
        allDepatByBranchParams: {
          ...state.allDepatByBranchParams,
          no_of_pages: noofpages,
        },
      };
    },
    setAllDeptByBranchParams: (state, action) => {
      return {
        ...state,
        allDepatByBranchParams: action.payload,
      };
    },

    // All Warehouse By Departments, Branch

    getAllWarehouseByDeptBranch: (state) => {
      if (state.allDepatByBranchParams?.page == 1) {
        return {
          ...state,
          loading: true,
          allWarehouseByDeptBranchList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    allWarehouseByDeptBranchSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.allWarehouseByDeptBranchParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.allWarehouseByDeptBranchList, ...response.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.allWarehouseByDeptBranchParams?.page_size
      );
      return {
        ...state,
        allWarehouseByDeptBranchList: list,
        loading: false,
        allWarehouseByDeptBranchParams: {
          ...state.allWarehouseByDeptBranchParams,
          no_of_pages: noofpages,
        },
      };
    },
    setAllWarehouseByDeptBranchParams: (state, action) => {
      return {
        ...state,
        allWarehouseByDeptBranchParams: action.payload,
      };
    },

    // User  Departments By Branch
    getUserDeptByBranch: (state, action) => {
      if (state.userDeptByBranchParams?.page == 1) {
        return {
          ...state,
          loading: true,
          UserDeptByBranchList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    userDeptByBranchSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.userDeptByBranchParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.UserDeptByBranchList, ...response.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.userDeptByBranchParams?.page_size
      );
      return {
        ...state,
        UserDeptByBranchList: list,
        loading: false,
        userDeptByBranchParams: {
          ...state.userDeptByBranchParams,
          no_of_pages: noofpages,
        },
      };
    },
    setUserDeptByBranchParams: (state, action) => {
      return {
        ...state,
        userDeptByBranchParams: action.payload,
      };
    },

    // User Branch

    getMiniUserBranches: (state, action) => {
      if (state.userMiniBranchParams?.page == 1) {
        return {
          ...state,
          loading: true,
          userMiniBranchList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    miniUserBranchSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.userMiniBranchParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.userMiniBranchList, ...response.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.userMiniBranchParams?.page_size
      );
      return {
        ...state,
        userMiniBranchList: list,
        loading: false,
        userMiniBranchParams: {
          ...state.userMiniBranchParams,
          no_of_pages: noofpages,
        },
      };
    },
    setMiniUserBranchsParams: (state, action) => {
      return {
        ...state,
        userMiniBranchParams: action.payload,
      };
    },

    getUserWarehouse: (state) => {
      if (state.userWarehouseParams?.page == 1) {
        return {
          ...state,
          loading: true,
          userWarehouseList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    userWarehouseSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.userWarehouseParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.userWarehouseList, ...response.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.userWarehouseParams?.page_size
      );
      return {
        ...state,
        userWarehouseList: list,
        loading: false,
        userWarehouseParams: {
          ...state.userWarehouseParams,
          no_of_pages: noofpages,
        },
      };
    },
    setUserWarehouseParams: (state, action) => {
      return {
        ...state,
        userWarehouseParams: action.payload,
      };
    },

    getMiniUsers: (state) => {
      if (state.miniUserParams?.page == 1) {
        return {
          ...state,
          loading: true,
          miniUserList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    miniUserSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.miniUserParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.miniUserList, ...response.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.miniUserParams?.page_size
      );
      return {
        ...state,
        miniUserList: list,
        loading: false,
        miniUserParams: {
          ...state.miniUserParams,
          no_of_pages: noofpages,
        },
      };
    },
    setMiniUserParams: (state, action) => {
      return {
        ...state,
        miniUserParams: action.payload,
      };
    },
    getMiniReportingUsers: (state) => {
      if (state.miniReportingUserParams?.page == 1) {
        return {
          ...state,
          loading: true,
          miniReportingUserList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    miniReportingUserSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.miniReportingUserParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.miniReportingUserList, ...response.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.miniReportingUserParams?.page_size
      );
      return {
        ...state,
        miniReportingUserList: list,
        loading: false,
        miniReportingUserParams: {
          ...state.miniReportingUserParams,
          no_of_pages: noofpages,
        },
      };
    },
    setMiniReportingUserParams: (state, action) => {
      return {
        ...state,
        miniReportingUserParams: action.payload,
      };
    },
    getMiniMaterialType: (state) => {
      if (state.miniMaterialTypeParams?.page == 1) {
        return {
          ...state,
          loading: true,
          miniMaterialTypeList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    miniMaterialTypeSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.miniMaterialTypeParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.miniMaterialTypeList, ...response.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.miniMaterialTypeParams?.page_size
      );
      return {
        ...state,
        miniMaterialTypeList: list,
        loading: false,
        miniMaterialTypeParams: {
          ...state.miniMaterialTypeParams,
          no_of_pages: noofpages,
        },
      };
    },
    setMiniMaterialTypeParams: (state, action) => {
      return {
        ...state,
        miniMaterialTypeParams: action.payload,
      };
    },
    getMiniResourceCategory: (state) => {
      if (state.miniResourceCategoryParams?.page == 1) {
        return {
          ...state,
          loading: true,
          miniResourceCategoryList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    miniResourceCategorySuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.miniResourceCategoryParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.miniResourceCategoryList, ...response.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.miniResourceCategoryParams?.page_size
      );
      return {
        ...state,
        miniResourceCategoryList: list,
        loading: false,
        miniResourceCategoryParams: {
          ...state.miniResourceCategoryParams,
          no_of_pages: noofpages,
        },
      };
    },
    setMiniResourceCategoryParams: (state, action) => {
      return {
        ...state,
        miniResourceCategoryParams: action.payload,
      };
    },
    
    getMiniResourceVendor: (state) => {
      if (state.miniResourceVendorParams?.page == 1) {
        return {
          ...state,
          loading: true,
          miniResourceVendorList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    miniResourceVendorSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.miniResourceVendorParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.miniResourceVendorList, ...response.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.miniResourceVendorParams?.page_size
      );
      return {
        ...state,
        miniResourceVendorList: list,
        loading: false,
        miniResourceVendorParams: {
          ...state.miniResourceVendorParams,
          no_of_pages: noofpages,
        },
      };
    },
    setMiniResourceVendorParams: (state, action) => {
      return {
        ...state,
        miniResourceVendorParams: action.payload,
      };
    },

    getMiniDeptWarehouseList: (state) => {
      if (state.miniDeptWarehouseListParams?.page == 1) {
        return {
          ...state,
          loading: true,
          miniDeptWarehouseList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    miniDeptWarehouseListSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.miniDeptWarehouseListParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.miniDeptWarehouseList, ...response.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.miniDeptWarehouseListParams?.page_size
      );
      return {
        ...state,
        miniDeptWarehouseList: list,
        loading: false,
        miniDeptWarehouseListParams: {
          ...state.miniDeptWarehouseListParams,
          no_of_pages: noofpages,
        },
      };
    },
    setMiniDeptWarehouseListParams: (state, action) => {
      return {
        ...state,
        miniDeptWarehouseListParams: action.payload,
      };
    },
    getMiniUserStockpointList: (state) => {
      if (state.miniUserStockpointListParams?.page == 1) {
        return {
          ...state,
          loading: true,
          miniUserStockpointList: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    miniUserStockpointListSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.miniUserStockpointListParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.miniUserStockpointList, ...response.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.miniUserStockpointListParams?.page_size
      );
      return {
        ...state,
        miniUserStockpointList: list,
        loading: false,
        miniUserStockpointListParams: {
          ...state.miniUserStockpointListParams,
          no_of_pages: noofpages,
        },
      };
    },
    setMiniUserStockpointListParams: (state, action) => {
      return {
        ...state,
        miniUserStockpointListParams: action.payload,
      };
    },

// Petty Cash
    getMiniPettyCashItems: (state, action) => {
      if (state.miniPettyCashItemsParams?.page == 1) {
        return {
          ...state,
          loading: true,
          miniPettyCashItems: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    miniPettyCashItemSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.miniPettyCashItemsParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.miniPettyCashItems, ...response.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.miniPettyCashItemsParams?.page_size
      );
      return {
        ...state,
        miniPettyCashItems: list,
        loading: false,
        miniPettyCashItemsParams: {
          ...state.miniPettyCashItemsParams,
          no_of_pages: noofpages,
        },
      };
    },
    setMiniPettyCashItemParams: (state, action) => {
      return {
        ...state,
        miniPettyCashItemsParams: action.payload,
      };
    },

    // Utility Billing

    getMiniUtilityBilling: (state, action) => {
      if (state.miniUtilityBillingParams?.page == 1) {
        return {
          ...state,
          loading: true,
          miniUtilityBilling: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    miniUtilityBillingSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.miniUtilityBillingParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.miniUtilityBilling, ...response.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.miniUtilityBillingParams?.page_size
      );
      return {
        ...state,
        miniUtilityBilling: list,
        loading: false,
        miniUtilityBillingParams: {
          ...state.miniUtilityBillingParams,
          no_of_pages: noofpages,
        },
      };
    },
    setMiniUtilityBillingParams: (state, action) => {
      return {
        ...state,
        miniUtilityBillingParams: action.payload,
      };
    },


    // Utility Billing service Items
    getMiniUtilityItemNum: (state, action) => {
      if (state.utilitySeriveNumParams?.page == 1) {
        return {
          ...state,
          loading: true,
          utilitySeriveNum: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    miniUtilityItemNumSuccessful: (state, action) => {
      var response = action.payload;
      var list = [];
      if (state.utilitySeriveNumParams?.page == 1) {
        list = response.results;
      } else {
        list = [...state.utilitySeriveNum, ...response.results];
      }
      var noofpages = Math.ceil(
        action.payload.count / state.utilitySeriveNumParams?.page_size
      );
      return {
        ...state,
        utilitySeriveNum: list,
        loading: false,
        utilitySeriveNumParams: {
          ...state.utilitySeriveNumParams,
          no_of_pages: noofpages,
        },
      };
    },
    setMiniUtilityItemNumParams: (state, action) => {
      return {
        ...state,
        utilitySeriveNumParams: action.payload,
      };
    },












    // Wheelsmart Code

    getMiniCounters: (state) => {
      return {
        ...state,
        miniCountersList: [],
        loading: true,
      };
    },
    miniCountersSuccessful: (state, action) => {
      return {
        ...state,
        miniCountersList: action.payload,
        loading: false,
      };
    },
    getMiniCountersClearData: (state) => {
      return {
        ...state,
        miniCountersList: [],
      };
    },
    getMiniZones: (state) => {
      return {
        ...state,
        miniZonesList: [],
        loading: true,
      };
    },
    miniZonesSuccessful: (state, action) => {
      return {
        ...state,
        miniZonesList: action.payload,
        loading: false,
      };
    },
    getMiniZonesClearData: (state) => {
      return {
        ...state,
        miniZonesList: [],
      };
    },
    getMiniClusters: (state) => {
      return {
        ...state,
        miniClustersList: [],
        loading: true,
      };
    },
    miniClustersSuccessful: (state, action) => {
      return {
        ...state,
        miniClustersList: action.payload,
        loading: false,
      };
    },
    getMiniClustersClearData: (state) => {
      return {
        ...state,
        miniClustersList: [],
      };
    },
    getMiniDivisions: (state) => {
      return {
        ...state,
        miniDivisionsList: [],
        loading: true,
      };
    },
    miniDivisionsSuccessful: (state, action) => {
      return {
        ...state,
        miniDivisionsList: action.payload,
        loading: false,
      };
    },
    getMiniDivisionsClearData: (state) => {
      return {
        ...state,
        miniDivisionsList: [],
      };
    },

    getMiniOccupation: (state) => {
      return {
        ...state,
        miniOccupationList: [],
        loading: true,
      };
    },
    miniOccupationSuccessful: (state, action) => {
      return {
        ...state,
        miniOccupationList: action.payload,
        loading: false,
      };
    },

    getMiniRejectReasons: (state) => {
      return {
        ...state,
        miniRejectReasonsList: [],
        loading: true,
      };
    },
    miniRejectReasonsSuccessful: (state, action) => {
      return {
        ...state,
        miniRejectReasonsList: action.payload,
        loading: false,
      };
    },

    getMiniManagers: (state) => {
      return {
        ...state,
        miniManagersList: [],
        loading: true,
      };
    },
    miniManagersSuccessful: (state, action) => {
      return {
        ...state,
        miniManagersList: action.payload,
        loading: false,
      };
    },

    apiError: (state, action) => {
      return { ...state, loading: false, error_msg: action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getUserPermissions,
  userPermissionsSuccessful,
  getMiniBranches,
  miniBranchListSuccessful,
  setBranchParams,
  getMiniDepartment,
  miniDepartmentSuccessful,
  setDepartmentParams,
  getMiniDesignations,
  miniDesignationSuccessful,
  setDesignationParams,
  getMiniWarehouse,
  miniWarehouseSuccessful,
  setWarehouseParams,
  getMiniMaterialCategory,
  miniMaterialCategorySuccessful,
  setMaterialCategoryParams,
  getMiniMaterialGroup,
  miniMaterialGroupSuccessful,
  setMaterialGroupParams,
  getMiniMaterialItem,
  miniMaterialItemSuccessful,
  setMaterialItemParams,
  getMiniStockPoint,
  miniStockPointSuccessful,
  setStockPointParams,
  getUserData,
  userDataSuccessful,
  getAllDeptByBranch,
  allDeptByBranchSuccessful,
  setAllDeptByBranchParams,
  getAllWarehouseByDeptBranch,
  allWarehouseByDeptBranchSuccessful,
  setAllWarehouseByDeptBranchParams,
  getUserDeptByBranch,
  userDeptByBranchSuccessful,
  setUserDeptByBranchParams,
  getMiniUserBranches,
  miniUserBranchSuccessful,
  setMiniUserBranchsParams,
  getUserWarehouse,
  userWarehouseSuccessful,
  setUserWarehouseParams,
  getMiniUsers,
  miniUserSuccessful,
  setMiniUserParams,
  getMiniReportingUsers,
  miniReportingUserSuccessful,
  setMiniReportingUserParams,
  getMiniMaterialType,
  miniMaterialTypeSuccessful,
  setMiniMaterialTypeParams,
  getMiniResourceCategory,
  miniResourceCategorySuccessful,
  setMiniResourceCategoryParams,
  // getMiniResourceEmployee,
  // miniResourceEmployeeSuccessful,
  // setMiniResourceEmployeeParams,
  getMiniResourceVendor,
  miniResourceVendorSuccessful,
  setMiniResourceVendorParams,
  getMiniDeptWarehouseList,
  miniDeptWarehouseListSuccessful,
  setMiniDeptWarehouseListParams,
  getMiniUserStockpointList,
  miniUserStockpointListSuccessful,
  setMiniUserStockpointListParams,
  getMiniPettyCashItems,
  miniPettyCashItemSuccessful,
  setMiniPettyCashItemParams,
  getMiniUtilityBilling,
  miniUtilityBillingSuccessful,
  setMiniUtilityBillingParams,
  getMiniUtilityItemNum,
  miniUtilityItemNumSuccessful,
  setMiniUtilityItemNumParams,

  // Wheelsmart Code
  getMiniCounters,
  miniCountersSuccessful,
  getMiniCountersClearData,
  getMiniZones,
  miniZonesSuccessful,
  getMiniZonesClearData,
  getMiniClusters,
  miniClustersSuccessful,
  getMiniClustersClearData,
  getMiniDivisions,
  miniDivisionsSuccessful,
  getMiniDivisionsClearData,
  getMiniOccupation,
  miniOccupationSuccessful,
  getMiniRejectReasons,
  miniRejectReasonsSuccessful,
  getMiniManagers,
  miniManagersSuccessful,
  apiError,
} = miniSlice.actions;

export default miniSlice.reducer;
